import {createSelector} from "@ngrx/store";
import {AuthActionTypes} from '../actions/authentication';
import {ActivationFormErrors, LoginFormErrors, UserData} from '../models';
import {CustomAction} from '../actions/CustomAction';

const ADMIN_PERMISSION = 'can_admin_school';
const EDIT_EVENTS_PERMISSION = 'can_edit_events';
const DELETE_COMMENTS_PERMISSION = 'can_delete_comments';
const EDIT_OWN_COMMENTS = 'can_edit_own_comments';
const CREATE_RELATIONSHIPS_FOR_SELF_IN_OWN_GROUPS = 'can_create_relationships_for_self';

export interface AuthState {
  anonymous: boolean;  // todo remove and use combined selector
  logginIn: boolean;
  tokenLoaded: boolean;
  loginRedirectUrl: string;
  userData: UserData;
  loginFormErrors: LoginFormErrors;
  activationFormErrors: ActivationFormErrors;
}

export const initialAuthState: AuthState = {
  anonymous: true,
  logginIn: false,
  tokenLoaded: false,
  loginRedirectUrl: '',
  userData: {
    username: '',
    permissions: [],
    id: -1,
    role: '',
    email: '',
    language: 'de',
    isEmailVerified: false,
    forcePasswordReset: true,
    schoolId: -1,
    schoolName: '',
    notificationId: 0,
    selectedGroups: [-1],
    showEventOnboarding: false,
    premium: false,
    subscriptionUntil: "",
    schoolAcceptedTerms: false,
    edulog: false,
    semesters: {
      firstStart: '',
      firstEnd: '',
      secondStart: '',
      secondEnd: '',
      thirdStart: '',
      thirdEnd: '',
      names: {
        previous: {
          semester: 0,
          years: [21, 22]
        },
        current: {
          semester: 0,
          years: [21, 22]
        },
        next: {
          semester: 0,
          years: [21, 22]
        }
      }
    },
    showRelationshipOnboarding: false
  },
  loginFormErrors: {
    username: '',
    password: '',
    general: ''
  },
  activationFormErrors: {
    activationCode: '',
    general: ''
  }
};

export function reducer(state = initialAuthState, action: CustomAction): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return Object.assign({}, state, {
        logginIn: true
      });

    case AuthActionTypes.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        logginIn: false,
        anonymous: false
      });

    case AuthActionTypes.LOGIN_FAILURE:
    case AuthActionTypes.AUTH_LOGOUT_SUCCESS:
      return Object.assign({}, initialAuthState, {
        loginRedirectUrl: state.loginRedirectUrl
      });


    case AuthActionTypes.AUTH_USER_TOKEN_RECEIVED:
      return Object.assign({}, state, {
        tokens: action.payload,
        tokenLoaded: true
      });

    case AuthActionTypes.TOKEN_LOADED:
      return Object.assign({}, state, {
        tokenLoaded: true
      });

    case AuthActionTypes.AUTH_TOKEN_RESET:
      return Object.assign({}, state, {
        tokenLoaded: true,
      });

    case AuthActionTypes.AUTH_TOKEN_LOAD_LOCAL:
      return Object.assign({}, state, {
        tokenLoaded: false
      });

    case AuthActionTypes.AUTH_USER_DATA_SUCCESS:
      const showEventOnboarding = state.userData.id === -1 ?
        action.payload.showEventOnboarding : state.userData.showEventOnboarding;
      const showRelationshipOnboarding = state.userData.id === -1 ?
        action.payload.showRelationshipOnboarding : state.userData.showRelationshipOnboarding;
      const userData = Object.assign({}, action.payload, {
        showEventOnboarding,
        showRelationshipOnboarding
      })
      return Object.assign({}, state, {
        userData,
        anonymous: false
      });

    case AuthActionTypes.AUTH_USER_DATA_FAIL:
      return Object.assign({}, state, {
        userData: initialAuthState.userData
      });

    case AuthActionTypes.LOGIN_UPDATE_FORM_ERROR:
      return Object.assign({}, state, {
        loginFormErrors: action.payload
      });

    case AuthActionTypes.LOGIN_CLEAR_FORM_ERROR:

      let newFormErrors = Object.assign({}, state.loginFormErrors);

      if (action.payload in newFormErrors) {
        newFormErrors[action.payload] = '';
      }

      return Object.assign({}, state, {
        loginFormErrors: newFormErrors
      });

    case AuthActionTypes.SET_LOGIN_REDIRECT_URL:
      return Object.assign({}, state, {
        loginRedirectUrl: action.payload
      });

    case AuthActionTypes.CLEAR_LOGIN_REDIRECT_URL:
      return Object.assign({}, state, {
        loginRedirectUrl: initialAuthState.loginRedirectUrl
      });

    case AuthActionTypes.RESET_TOKEN:
      return Object.assign({}, state, {
        anonymous: initialAuthState.anonymous,
        tokenLoaded: initialAuthState.tokenLoaded
      });

    case AuthActionTypes.UPDATE_SETTINGS:
      return Object.assign({}, state, {
        userData: Object.assign({}, state.userData, {
          showEventOnboarding: action.payload.showEventOnboarding,
          showRelationshipOnboarding: action.payload.showRelationshipOnboarding
        })
      });

    case AuthActionTypes.ACTIVATE_CODE_FORM_ERROR:
      return Object.assign({}, state, {
        activationFormErrors: action.payload
      });

    default:
      return state;

  }
}

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */
export const isLogginIn = (state: AuthState) => state.logginIn
export const isAnonymous = (state: AuthState) => state.anonymous
export const isTokenLoaded = (state: AuthState) => state.tokenLoaded
export const getUser = (state: AuthState) => state.userData
export const getLoginFormErrors = (state: AuthState) => state.loginFormErrors
export const getLoginRedirectUrl = (state: AuthState) => state.loginRedirectUrl
export const getActivatinoFormErrors = (state: AuthState) => state.activationFormErrors

function hasPermission(permissionCode, data) {
  const permission = data.permissions.filter(perm => perm === permissionCode);
  return permission.length > 0;
}

export const isAdmin = createSelector(
  getUser,
  (userData: UserData) => hasPermission(ADMIN_PERMISSION, userData)
)

export const canEditEvents = createSelector(
  getUser,
  (userData: UserData) => hasPermission(EDIT_EVENTS_PERMISSION, userData)
)

export const canDeleteComments = createSelector(
  getUser,
  (userData: UserData) => hasPermission(DELETE_COMMENTS_PERMISSION, userData)
)

export const canEditOwnComments = createSelector(
  getUser,
  (userData: UserData) => hasPermission(EDIT_OWN_COMMENTS, userData)
)

export const canCreateRelationshipsForSelfInOwnGroups = createSelector(
  getUser,
  (userData: UserData) => hasPermission(CREATE_RELATIONSHIPS_FOR_SELF_IN_OWN_GROUPS, userData)
)

export const hasPremium = createSelector(
  getUser,
  (userData: UserData) => userData.premium
)

export const getSubscriptionUntil = createSelector(
  getUser,
  (userData: UserData) => userData.subscriptionUntil
)

export const showEventOnboarding = createSelector(
  getUser,
  (userData: UserData) => userData.showEventOnboarding
)

export const showRelationshipOnboarding = createSelector(
  getUser,
  (userData: UserData) => userData.showRelationshipOnboarding
)
