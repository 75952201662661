
import { Injectable, Inject } from '@angular/core';
import {Observable} from 'rxjs';

import { HelloClassRequestsService } from '../hc-requests/hc-requests.services';
import { API } from '../app.tokens';
import {Relationship, RelationshipUser} from "./relationship.models";
import {HttpParams} from "@angular/common/http";

@Injectable()
export class RelationshipHttpService {

    constructor(private hcRequests: HelloClassRequestsService, @Inject(API) private api) {
    }

    getUsers(schoolId: number, userId: number): Observable<RelationshipUser[]> {
        return this.hcRequests.request({
            method: 'get',
            url: `${this.api}/schools/${schoolId}/users/${userId}/relationship-selection`,
        });
    }

    getAdminUsers(schoolId: number, groups: number[]): Observable<RelationshipUser[]> {

        const groupsString = groups.join(':')
        const params = new HttpParams().set('groups', groupsString)

        return this.hcRequests.request({
            method: 'get',
            url: `${this.api}/schools/${schoolId}/users/relationship-selection`,
            params
        });
    }

    getRelationships(schoolId: number, userId: number): Observable<Relationship[]> {
        return this.hcRequests.request({
            method: 'get',
            url: `${this.api}/schools/${schoolId}/users/${userId}/relationships`,
        });
    }

    updateRelationships(schoolId, userId: number, newRelationships: Relationship[],
                        updatedRelationships: Relationship[], deletedRelationships: number[]): Observable<any> {

        const data = {
            add: this.makeApiRelationship(newRelationships),
            delete: deletedRelationships,
            update: this.makeApiRelationship(updatedRelationships)
        };

        return this.hcRequests.request({
            method: 'post',
            url: `${this.api}/schools/${schoolId}/users/${userId}/relationships`,
            body: data
        });
    }

    private makeApiRelationship (relationships: Relationship[]) {
        return relationships.map(relationship => {
            const apiRelationship = {
                guardian: relationship.guardian.id,
                cared_for: relationship.cared_for.id
            };

            if (relationship.id !== -1) {
                apiRelationship['id'] = relationship.id
            }

            return apiRelationship;
        });
    }

}
