import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
  selector: 'hc-edulog-existing-user-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="container-fluid container-fluid--limit signup-page">
      <div class="lead-in">
        <div class="row">
          <div class="col-md-12">
            <h1 class="heading-2 qa-title" translate>Der von dir verwendete Benutzer ist bereits bei Helloclass
              registriert.</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <hc-edulog-widget [buttonLink]="edulogUrl" [buttonText]="'Mit Edulog anmelden'">
            <p translate>Willst du dich mit deinen Edulog Zugangsdaten anmelden?</p>
          </hc-edulog-widget>
        </div>
      </div>
    </div>
  `
})
export class EdulogExistingUserPage {
  public edulogUrl = `action=login`

  constructor() {
  }

}
