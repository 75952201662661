import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import {StorageService} from "../services";

@Injectable()
export class FromPremiumGuard  {

  constructor(private localStorageService: StorageService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.localStorageService.getFromPremium()) {
      this.localStorageService.deleteFromPremium();
      return this.router.parseUrl('/premium');
    } else {
      return true;
    }
  }
}
