
import {of as observableOf } from 'rxjs';

import {map, switchMap, catchError} from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { EffectsWithRouter } from "../effects/router-effect";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers/index";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { HelloClassApiService } from "../services/helloclass";
import { GetPaymentRequestFailureAction, GetPaymentRequestSuccessAction, PaymentActionTypes } from "./payment.actions";
import { CustomAction } from '../actions/CustomAction';


@Injectable()
export class PaymentEffects extends EffectsWithRouter {

    constructor(private store: Store<AppState>,
                private actions$: Actions,
                private translateService: TranslateService,
                private helloClassApi: HelloClassApiService,
                injector: Injector) {
        super(injector);
    }

     getPaymentRequest$ = createEffect(() => this.actions$.pipe(
        ofType<CustomAction>(PaymentActionTypes.GET_PAYMENT_REQUEST),
        map(action => action.payload),
            switchMap((code) => {
                return this.helloClassApi.createPaymentRequest(code).pipe(
                    map(paymentRequest => new GetPaymentRequestSuccessAction(paymentRequest)));
            }),
        catchError(error => observableOf(new GetPaymentRequestFailureAction())),));

}
