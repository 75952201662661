import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'hc-subscription-info',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="subscription-info">
      <h2 class="heading-2 subscription-info__title" translate>Abonnement</h2>
      <div class="subscription-info__current">
        <div *ngIf="premium; then premiumTemplate; else freeTemplate">
        </div>
        <ng-template #premiumTemplate>
          <h4 class="text-emph-blue">
            Helloclass Premium
          </h4>
          <!--div *ngIf="admin">
              <p [innerHtml]="premiumString | translate:params"></p>
              <a routerLink="/premium" class="btn btn--blue btn--innershadow" translate>Verlängern</a>
          </div-->

        </ng-template>
        <ng-template #freeTemplate>
          <h4 class="text-emph-blue">
            Helloclass Basic
          </h4>
          <!-- div *ngIf="admin">
              <p class="subscription-info__premium-ad">
                  <span class="icon-star subscription-info__premium-ad-star text-emph-blue"></span> <span [innerHtml]="adString | translate"></span>
              </p>
              <div>
                  <a routerLink="/premium" class="btn btn--blue btn--innershadow" translate>Upgrade
                      durchführen</a>
              </div>
          </div-->
        </ng-template>

      </div>
    </div>
  `
})
export class SubscriptionInfoComponent implements OnInit {
  @Input() premium: boolean;
  @Input() admin: boolean;
  @Input() until$: Observable<string>;

  premiumString = 'Dein Abo für deine Schule ist bis am {{date}} gültig.'; // translated string with placeholder in translation file
  adString = 'Mit <strong>Helloclass Premium</strong> stehen dir weitere nützliche Funktionen zur Verfügung.';
  params: any = {date: ''};

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.until$.subscribe(until => {
      this.params.date = `<span class="premium-date">${this.datePipe.transform(until, 'dd.MM.yyyy')}</span>`;
    });
  }


}
