
import { Injectable, Inject } from '@angular/core';

import { HelloClassRequestsService } from '../hc-requests/hc-requests.services';
import { API } from '../app.tokens';
import {Observable} from "rxjs";
import {InfopointMessage} from "./infopoint.models";
import {HelloClassApiService} from "../services";

@Injectable({
  providedIn: 'root',
})
export class InfopointHttpService {

    constructor(
        private helloclassApi: HelloClassApiService, // refactor
        private hcRequests: HelloClassRequestsService,
        @Inject(API) private api) {
    }

    fetchMessage(): Observable<InfopointMessage> {
        return this.hcRequests.request({
            method: 'get',
            url: `${this.api}/infopoint/`,
            language: this.helloclassApi.getLanguage()
        })
    }

}
