import { environment } from "../environments/environment";

export const JWT_REFRESH_DELTA_TIME = environment.JWT_REFRESH_DELTA;
export const JWT_CHECK_INTERVAL = environment.JWT_CHECK_INTERVAL;
export const BACKEND_URL = environment.BACKEND_URL;
export const API_URL = environment.API_URL;
export const BASE_URL = environment.BASE_URL;
export const IS_APP = environment.app;
export const API_VERSION = environment.API_VERSION;
export const APP_VERSION = environment.APP_VERSION;
export const ALLOW_TEST_USER = environment.ALLOW_TESTUSER;
export const I18N_PATH = environment.I18N_PATH;
export const IS_PRODUCTION = environment.production;
export const USE_MATOMO = environment.USE_MATOMO;
export const EDULOG_SSO = environment.EDULOG_SSO

export const SUPPORTED_LANGS = ['de', 'fr', 'it'];
export const SYSTEM_ERROR_KEY = 'Es ist ein Systemfehler aufgetreten. Bitte wende Dich sich an den Administrator.';
export const MIN_PASSWORD_LENGTH = 8;
export const ACCOUNT_DELETED_URL = '/goodbye'

