
import {tap, map, switchMap, filter, withLatestFrom} from 'rxjs/operators';
import * as daysjs from "dayjs";
import { Injectable, Injector } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { EffectsWithRouter } from '../effects/router-effect';
import { AppState, getAuthUser } from '../reducers';
import { UiActionTypes, UiHideNotifications } from '../actions/ui';
import {
    MarkNotifications
} from "../notifications/notifications.actions";
import { TranslateService } from '@ngx-translate/core';
import { HelloClassApiService } from '../services/helloclass';
import { SUPPORTED_LANGS } from '../app.constants';
import * as ui from '../actions/ui';
import {Title} from "@angular/platform-browser";
import {AbsenceService} from "../absence-service/absence.service";

@Injectable()
export class UiEffects extends EffectsWithRouter {

    constructor(private store: Store<AppState>,
                private actions$: Actions,
                private translateService: TranslateService,
                private helloClassApi: HelloClassApiService,
                private localeService: BsLocaleService,
                private title: Title,
                private absenceService: AbsenceService,
                injector: Injector) {
        super(injector);
    }

     setLanguage$ = createEffect(() => this.actions$.pipe(
        ofType<ui.UiSetLanguage>(UiActionTypes.UI_SET_LANGUAGE),
        map(action => {

            let langPart = action.payload.split('-')[0];

            // if language is supported change language
            if (SUPPORTED_LANGS.filter((lang) => langPart === lang).length > 0) {
                daysjs.locale(`${action.payload}`);
                // setTimeout(() => this.translateService.use(langPart), 0); // evil trick
                this.translateService.use(langPart);
                this.helloClassApi.setLanguage(langPart);
                this.localeService.use(langPart);

            }
            return { type: 'NOP', payload: null };
        })));

     notificationSelected$ = createEffect(() => this.actions$.pipe(
        ofType<ui.UiNotificationSelected>(UiActionTypes.UI_NOTIFICATION_SELECTED),
        map(action => action.payload),
        withLatestFrom(this.store.select(getAuthUser)),
        tap(() => this.store.dispatch(new UiHideNotifications())),
        map(data => {
            let url = []

            // is event
            if (data[0].target['label']) {
                url = [ '/klassenbuch/events', data[0].target.id ]
            } else if (data[0].target['from_hour']) { // is absence
                url = [ '/absences/absence', data[0].target.id ]
                if (!this.absenceService.isAbsenceReadonly((data[0] as any), data[1].permissions)) {
                    url = [...url, 'edit']
                }
            } else if (data[0].target['link_text']) { // is sai news
                window['InAppBrowser'].open(data[0].target['link'], '_blank').focus()
            }

            if (url.length > 0) {
                this.getRouter().navigate(url)
            }
            return new MarkNotifications(data[0].id)
        })
    ));

     setTitle = createEffect(() => this.actions$.pipe(
        ofType<ui.UiSetTitleAction>(UiActionTypes.UI_SET_TITLE),
        map(action => action.payload),
        filter(title => title !== ''),
        switchMap((title) =>
            this.translateService.get(title).pipe(
                map((translation: string) => {
                    this.title.setTitle(`Helloclass - ${translation}`)
                    return { type: 'NOP', payload: null };
                })
            )
        )
    ));

     handleEmptyTitle = createEffect(() => this.actions$.pipe(
        ofType<ui.UiSetTitleAction>(UiActionTypes.UI_SET_TITLE),
        map(action => action.payload),
        filter(title => title === ''),
        map(title => {
            this.title.setTitle('Helloclass')
            return { type: 'NOP', payload: null };
        })
    ))
}
