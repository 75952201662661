import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelloClassRequestsService {

  constructor(private httpClient: HttpClient) {
  }

  private makeHeaders(options) {
    let headers = options.language ? new HttpHeaders({'Accept-Language': options.language}) : new HttpHeaders();

    if (options.body) {
      if (options.mediaType) {
        // angular will set the correct multipart boundary for the contenttype below
        if (options.mediaType !== 'multipart/form-data') {
          headers = headers.set('Content-Type', options.mediaType);
        }
      } else {
        headers = headers.set('Content-Type', 'application/json');
      }

      if (options.httpOverwriteMethod) {
        headers = headers.set('X-Http-Method-Override', options.httpOverwriteMethod);
      }

      if (options.contentDisposition) {
        headers = headers.set('Content-Disposition', options.contentDisposition);
      }
    }

    if (options.accept) {
      headers = headers.set('Accept', options.accept)
    }
    return headers;
  }

  request(config: any, type = 'json'): Observable<any> {

    const method = config.method.toUpperCase();
    const options = {
      headers: this.makeHeaders(config),
      responseType: 'json' as 'json',
    };

    options.responseType = config.type ? config.type : options.responseType;

    if (config.params) {
      options['params'] = config.params;
    }

    let response$: Observable<any>;

    switch (method) {
      case 'GET':
        response$ = this.httpClient.get(config.url, options);
        break;
      case 'POST':
        response$ = this.httpClient.post(config.url, config.body, options);
        break;
      case 'DELETE':
        response$ = this.httpClient.delete(config.url, options);
        break;
      case 'PUT':
        response$ = this.httpClient.put(config.url, config.body, options);
        break;
      default:
        break;
    }
    ;

    return response$;
  }

  dateToString(date: Date): string {
    return `${date.getFullYear()}-${this.addLeadingZero(date.getMonth() + 1)}-${this.addLeadingZero(date.getDate())}`;
  }

  private addLeadingZero(number: number): string {
    return `0${number}`.slice(-2);
  }

}
