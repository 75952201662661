
import {combineLatest, Observable, Subscription} from 'rxjs';

import {filter, map, take} from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import { AppState, getPageNotifications, getNotificationError, getNotificationsLoading } from "../reducers";
import { GetNotificationsForPage } from "./notifications.actions";
import { Notification } from './notifications.models';
import { UiSetTitleAction, UiSetContextMenu, UiSetBackUrl } from "../actions/ui";
import { TranslateService } from "@ngx-translate/core";
import { UiNotificationSelected } from "../actions/ui";
import { MarkAllNotificationsAsRead, SetToLatestNotificationId } from "../notifications/notifications.actions";
import { OfflineService } from '../app/offline.service';


@Component({
    selector: 'hc-notifications-page',
    template: `
        <div class="container-fluid container-fluid--limit main__container notification-page">
            <div class="row vertical-margin" *ngIf="(hasNotifications$ | async)">
                <div class="col-xs-12">
                    <button type="button" class="btn btn-primary btn--innershadow btn--bigger" (click)="markAsRead()" translate>Alle Meldungen als gelesen markieren</button>
                </div>
            </div>
            <div class="row vertical-margin">
                <div class="col-xs-12">
                    <hc-notification-list
                        [notifications]="notifications$ | async"
                        [error]="error$ | async"
                        [isLoading]="notificationsLoading$ | async"
                        (notificationSelected)="notificationSelected($event)">
                    </hc-notification-list>
                </div>
            </div>
        </div>
    `
})
export class NotificationsPage implements OnInit, OnDestroy {

    public notifications$: Observable<any>;
    public error$: Observable<string>;
    public hasNotifications$: Observable<boolean>;
    public notificationsLoading$: Observable<boolean>;
    private notificationSubscription: Subscription;

    constructor(private store: Store<AppState>,
                private translateService: TranslateService,
                private offlineService: OfflineService
    ) {
        this.notifications$ = store.select(getPageNotifications);
        this.error$ = store.select(getNotificationError);
        this.notificationsLoading$ = store.select(getNotificationsLoading);
        this.hasNotifications$ = combineLatest([
            this.error$,
            this.notifications$
        ]).pipe(map(values => values[0] === null && values[1].length > 0));

        this.store.dispatch(new UiSetContextMenu(''));
        this.store.dispatch(new UiSetBackUrl(''));
        this.store.dispatch(new UiSetTitleAction('Meldungen'));

        this.notificationSubscription = this.notifications$.pipe(
            filter(notifications => notifications && notifications.length > 0),
            take(1))
            .subscribe(() => this.store.dispatch(new SetToLatestNotificationId())
        )
    }

    public notificationSelected(notification: Notification) {
        this.store.dispatch(new UiNotificationSelected(notification));
    }

    public markAsRead() {
        this.offlineService.executeActionOnlyWhenOnlineRequired(true, () => this.store.dispatch(new MarkAllNotificationsAsRead()));
    }

    ngOnInit() {
        this.store.dispatch(new GetNotificationsForPage());
    }

    ngOnDestroy() {
        this.notificationSubscription.unsubscribe();
    }
}
