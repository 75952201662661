import {combineLatest, from, of} from 'rxjs';

import {catchError, filter, map, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';

import {EffectsWithRouter} from '../effects/router-effect';
import * as reducers from '../reducers';
import {AppState, getAuthUser} from '../reducers';
import {FormUser, UserData} from '../models';
import {RoutingHistoryService} from '../routing-history/routing-history.service';
import {HelloClassApiService} from '../services/index'
import {AddAlertAction, AddToastAction} from '../alerts/alerts.actions';
import {ACCOUNT_DELETED_URL, SYSTEM_ERROR_KEY} from '../app.constants';
import {UiHideMenuAction, UiSetLanguage} from '../actions/ui';
import * as users from '../actions/users';
import {
    AddUserFailAction,
    AddUserSuccessAction,
    DeleteUsersAction,
    DeleteUsersFailAction,
    ImportUserInfoAction,
    LoadUserFailAction,
    LoadUserSuccessAction,
    UpdateProfileFailAction,
    UpdateProfileSuccessAction,
    UpdateUserFailAction,
    UpdateUserFormErrorsAction,
    UpdateUserSuccessAction,
    UserActionTypes,
    UsersLoadAction,
    UsersLoadFailAction,
    UsersLoadSuccessAction
} from '../actions/users';
import {CustomAction} from '../actions/CustomAction';
import {FileService} from '../services/file';
import {LogoutAction, UserDataSuccessAction} from '../actions/authentication';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class UsersEffects extends EffectsWithRouter {

    constructor(private actions$: Actions,
                private store: Store<AppState>,
                private helloclassApi: HelloClassApiService,
                injector: Injector,
                private routingHistoryService: RoutingHistoryService,
                private fileService: FileService) {
        super(injector);
    }

    loadUsers$ = createEffect(() => from(this.actions$.pipe(ofType(UserActionTypes.LOAD_USERS))
    ).pipe(
        withLatestFrom(this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1))
        ),
        switchMap((values: [any, UserData]) => this.helloclassApi.getUsers(values[1].schoolId).pipe(
            map(data => new UsersLoadSuccessAction(data)),
            catchError(error => of(new UsersLoadFailAction(error))),) // todo: do dispatch notification
        )));

    loadUser$ = createEffect(() => this.actions$.pipe(
        ofType<users.LoadUserAction>(UserActionTypes.LOAD_USER),
        map(action => action.payload),
        withLatestFrom(this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1))
        ), switchMap((values: [number, UserData]) => this.helloclassApi.getUser(values[1].schoolId, values[0]).pipe(
            map(data => new LoadUserSuccessAction(data)),
            catchError(error => of(new LoadUserFailAction(error))),)
        ),));

    loadUserFail$ = createEffect(() => this.actions$.pipe(
        ofType<users.LoadUserFailAction>(UserActionTypes.LOAD_USER_FAIL),
        map(action => action.payload),
        map((response: HttpErrorResponse) => {
            let text = '';
            if (404 === response.status) {
                // its possible to modify the id in the URL
                // in case thge user cannot be found 404, redirect to user-list
                this.getRouter().navigate(['/admin/users']);
            }
            return new AddToastAction({message: 'Es ist ein Fehler aufgetreten!', type: 'fail'});
        }),));

    addUser$ = createEffect(() => from(this.actions$.pipe(
        ofType<users.AddUserAction>(UserActionTypes.ADD_USER),
        map(action => action.payload)))
        .pipe(
            withLatestFrom(this.store.select(getAuthUser).pipe(
                filter(authUser => authUser.id !== -1),)),
            switchMap((values: [FormUser, UserData]) => this.helloclassApi.addUser(values[1].schoolId, values[0]).pipe(
                map(data => new AddUserSuccessAction(data)),
                catchError(error => of(new AddUserFailAction(error))),)
            )));

    addUserSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<users.AddUserSuccessAction>(UserActionTypes.ADD_USER_SUCCESS),
        map(action => action.payload),
        withLatestFrom(this.store.select(reducers.getBackUrl)),
        map((values) => {
            // this.location.back();
            this.getRouter().navigateByUrl(values[1]);
            return new AddToastAction({message: 'Die Person wurde erfolgreich erfasst', type: 'success'});
        }),));

    addUserFail$ = createEffect(() => this.actions$.pipe(
        ofType<any>(UserActionTypes.ADD_USER_FAIL, UserActionTypes.UPDATE_USER_FAIL),
        map(action => action.payload),
        map((response: HttpErrorResponse) => {
            let text = '';
            switch (response.status) {
                case 400:
                    if (response.error.email) {
                        text = response.error.email[0];
                        break;
                    } else {
                        text = JSON.stringify(response.error);
                    }
                    break;

                default:
                    text = SYSTEM_ERROR_KEY;
                    break;
            }

            return new AddToastAction({message: text, type: 'fail'});
        }),));

    updateUser$ = createEffect(() => combineLatest([
            this.actions$.pipe(
                ofType<users.UpdateUserAction>(UserActionTypes.UPDATE_USER),
                map(action => action.payload)),
            this.store.select(getAuthUser).pipe(
                filter(authUser => authUser.id !== -1),
                take(1))
        ]
    ).pipe(
        switchMap((values: [FormUser, UserData]) => this.helloclassApi.updateUser(values[1].schoolId, values[0].id, values[0]).pipe(
            map(data => new UpdateUserSuccessAction(data)),
            catchError(error => of(new UpdateUserFailAction(error))),)
        )));

    updateUserSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<users.UpdateUserSuccessAction>(UserActionTypes.UPDATE_USER_SUCCESS),
        map(action => action.payload),
        map(() => {
            const backUrl = this.routingHistoryService.getPreviousRoute();
            this.getRouter().navigate([this.routingHistoryService.getUrlPart(backUrl)], {queryParams: this.routingHistoryService.getParams(backUrl)});
            return new AddToastAction({message: 'Die Änderungen wurden gespeichert', type: 'success'});
        }),));

    deleteUserConfirmation$ = createEffect(() => this.actions$.pipe(
        ofType<users.DeleteUserConfirmationAction>(UserActionTypes.DELETE_USER_CONFIRMATION),
        tap(() => this.store.dispatch(new UiHideMenuAction())),
        map((action) => {
            let newPayload = Object.assign({}, action.payload, {
                singleUser: true,
                userIds: [action.payload['id']]
            });
            return new DeleteUsersAction(newPayload);
        }),));

    deleteUserCancel$ = createEffect(() => this.actions$.pipe(
        ofType(UserActionTypes.DELETE_USER_CANCEL),
        map(() => new UiHideMenuAction())));

    deleteUsersConfirmation$ = createEffect(() => this.actions$.pipe(
        ofType<users.DeleteUsersConfirmationAction>(UserActionTypes.DELETE_USERS_CONFIRMATION),
        map((action) => {
            let newPayload = {
                singleUser: false,
                userIds: [...action.payload]
            };
            return new DeleteUsersAction(newPayload);
        })));

    deleteUsers$ = createEffect(() => this.actions$.pipe(
        ofType<users.DeleteUsersAction>(UserActionTypes.DELETE_USERS),
        map((action: DeleteUsersAction) => action.payload),
        withLatestFrom(
            this.store.select(getAuthUser).pipe(
                filter(authUser => authUser.id !== -1),
                map(user => user.schoolId))
        )).pipe(
        switchMap((values: [{ userIds: number[], singleUser: boolean }, number]) => this.helloclassApi.deleteUsers(values[0].userIds, values[1])
            .pipe(
                map((response) => {
                    const message = values[0].userIds.length > 1 ? 'Die Benutzer wurden gelöscht' : 'Der Benutzer wurde gelöscht'
                    this.store.dispatch(new AddToastAction({type: 'success', message: message}))
                    return new UsersLoadAction();
                }),
                catchError(error => of(new DeleteUsersFailAction(error)))
            )
        )
    ));

    importUsersInit$ = createEffect(() => combineLatest([
        this.actions$.pipe(
            ofType<users.ImportUsersAction>(UserActionTypes.IMPORT_USERS),
            map(action => action.payload)),
        this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1),
            take(1))
    ]).pipe(
        switchMap((values: [any, UserData]) => this.helloclassApi.importUsers(values[1].schoolId, values[0]).pipe(
            map(data => new ImportUserInfoAction(data)),
            catchError(error => of(new AddUserFailAction(error))),) // todo: do dispatch notification
        )
    ));

    importUsers = createEffect(() => this.actions$.pipe(
        ofType(UserActionTypes.IMPORT_USERS_INFO),
        map(() => new UsersLoadAction())))

    importUsersNoError = createEffect(() => this.actions$.pipe(
        ofType<users.ImportUserInfoAction>(UserActionTypes.IMPORT_USERS_INFO),
        filter(action => action.payload['createdRoles'].length === 0 && action.payload['importErrors'].length === 0),
        map(data => new AddToastAction({message: 'Die Personen wurden importiert', type: 'success'})),))

    updateProfile$ = createEffect(() => this.actions$.pipe(
        ofType<users.UpdateProfileAction>(UserActionTypes.UPDATE_PROFILE),
        map(action => action.payload),
        switchMap((user) => this.helloclassApi.updateProfile(user).pipe(
            map(data => new UpdateProfileSuccessAction(data)),
            catchError(error => of(new UpdateProfileFailAction(error))),) // todo: do dispatch notification
        ),));

    updateProfileSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<users.UpdateProfileSuccessAction>(UserActionTypes.UPDATE_PROFILE_SUCCESS),
        map(action => action.payload),
        tap((response) => this.store.dispatch(new AddToastAction({
            message: 'Das Profil wurde gespeichert',
            type: 'success'
        }))),
        map((response) => {
            let userData = response['user'];
            this.store.dispatch(new UiSetLanguage(userData.language));

            const backUrl = this.routingHistoryService.getPreviousRoute();
            this.getRouter().navigate([backUrl]);
            return new UserDataSuccessAction(userData);
        }),));

    updateProfileFail$ = createEffect(() => this.actions$.pipe(
        ofType<any>(UserActionTypes.UPDATE_PROFILE_FAIL),
        map(action => action.payload),
        map((response) => {

            try {
                let data = response.json();
                let profileFormErrors = {};

                if (data['non_field_errors']) {
                    profileFormErrors['passwords'] = [data['non_field_errors']];
                }
                if (data['email']) {
                    profileFormErrors['email'] = data['email'];
                }
                if (data['username']) {
                    profileFormErrors['username'] = data['username'];
                }

                if (Object.keys(profileFormErrors).length > 0) {
                    return new UpdateUserFormErrorsAction(profileFormErrors);
                }
                ;

            } catch (e) {
            }

            return new AddAlertAction({message: SYSTEM_ERROR_KEY, type: 'fail'});
        }),));

    // refactor with absence export
    exportUsers$ = createEffect(() => combineLatest([
        this.actions$.pipe(ofType(UserActionTypes.USERS_EXPORT)),
        this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1),
            take(1),)
    ]).pipe(
        switchMap((values: [CustomAction, UserData]) => this.helloclassApi.exportUsers(values[1].schoolId).pipe(
            tap((data) => {
                this.fileService.openAsFile(data, 'text/csv', 'helloclass_export');
            }),
            map(() => ({type: 'none', payload: null})),)
        )
    ));

    deleteAccount$ = createEffect(() => this.actions$.pipe(
        ofType(UserActionTypes.DELETE_ACCOUNT),
        switchMap((user) => this.helloclassApi.deleteAccount().pipe(
                map(() => {
                    let currentHost = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
                    window.location.href = `${currentHost}${ACCOUNT_DELETED_URL}`;
                    return new LogoutAction(false)
                }))
            // .catch(error => Observable.of(new UpdateProfileFailAction(error))) // todo: do dispatch notification
        )))

    inviteFriend$ = createEffect(() => this.actions$.pipe(
        ofType<users.InviteFriendAction>(UserActionTypes.INVITE_FRIEND),
        map(action => action.payload),
        withLatestFrom(this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1)
        )),
        switchMap((data) => this.helloclassApi.inviteFriend(data[0], data[1].schoolId).pipe(
                map(() => new AddToastAction({message: 'Die Person wurde erfolgreich eingeladen', type: 'success'})),
                catchError((response: HttpErrorResponse) => {
                    let text = '';
                    switch (response.status) {
                        case 400:
                            if (response.error.email) {
                                text = response.error.email[0];
                                break;
                            } else if (response.error.non_field_errors) {
                                text = response.error.non_field_errors[0]
                                break;
                            } else {
                                text = JSON.stringify(response.error);
                            }
                            break;

                        default:
                            text = SYSTEM_ERROR_KEY;
                            break;
                    }
                    ;
                    return of(new AddToastAction({message: text, type: 'fail'}));
                })
            )
        )));
}
