import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable, Subscription} from "rxjs";
import {AppState, getFormErrors, getSignupLoading, getSignupUser} from "../reducers";
import {SignupUser} from "../models";
import {SignupAddFormErrorAction, signupPremium} from "../actions/signup";
import {UiSetLanguage} from "../actions/ui";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {filter, map, take} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'hc-signupform-new-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <hc-simple-header></hc-simple-header>
    <!-- todo: refactor with login page, make a component with slots -->
    <div class="container-fluid container-fluid--limit signup-page">
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <div class="lead-in">
            <div class="row">
              <div class="col-md-12">
                <h1
                  class="heading-2 qa-title vertical-margin-bottom"
                  data-cy="signupform-title">{{ "Nutze jetzt alle Premium-Funktionen kostenlos." | translate}}</h1>
                <p translate>
                  Helloclass ist ein Angebot von Swisscom. Ab dem neuen Schuljahr bieten wir alle Premium-Funktionen
                  kostenlos an. Gerne richten wir dir schon jetzt ein Konto ein.
                </p>
                <p translate>Registriere dich dazu einfach hier.</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hc-signup (signup)="signup($event)"
                         (errors)="formErrors($event)"
                         [user]="user$ | async"
                         [schoolName]="''"
                         [formErrors]="formErrors$ | async"
                         (formChanges)="formChanges($event)"
                         [schoolNameReadOnly]="false"></hc-signup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row footer-wrapper">
        <div class="col-md-10 col-md-offset-1">
          <hc-footer></hc-footer>
        </div>
      </div>
    </div>
  `
})
export class NewSignupFormPage implements OnDestroy, OnInit {
  loading$: Observable<boolean>;
  public user$: Observable<SignupUser>;
  public showMessage$: Observable<any>;
  public message$: Observable<any>;
  public showSuccess$: Observable<boolean>
  public formErrors$: Observable<Object>
  private oldLang = ''
  private titleSubscription: Subscription
  public schoolName$: Observable<string>

  constructor(private store: Store<AppState>,
              private translateService: TranslateService,
              private title: Title,
              private route: ActivatedRoute,
              private router: Router) {
    /**
     * Selectors can be applied with the `let` operator, which passes the source
     * observable to the provided function. This allows us an expressive,
     * composable technique for creating view projections.
     *
     * More on `let`: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35#let
     * More on selectors: https://gist.github.com/btroncone/a6e4347326749f938510#extracting-selectors-for-reuse
     */
    this.loading$ = store.select(getSignupLoading);
    this.user$ = store.select(getSignupUser);
    this.formErrors$ = store.select(getFormErrors);
  }

  signup(newUser: SignupUser) {
    /**
     * All state updates are handled through dispatched actions in 'smart'
     * components. This provides a clear, reproducible history of state
     * updates and user interaction through the life of our application.
     */
    this.store.dispatch(signupPremium(newUser));
  }

  formErrors(errors: Object) {
    this.store.dispatch(new SignupAddFormErrorAction(errors));
  }

  public formChanges(changes: SignupUser) {
    if (this.oldLang === '') {
      this.oldLang = changes.language;
      return;
    }

    if (this.oldLang !== changes.language) {
      this.oldLang = changes.language;
      this.store.dispatch(new UiSetLanguage(changes.language));
    }
  }

  ngOnInit() {
    this.titleSubscription = this.translateService.get('Registrieren')
      .subscribe(description => this.title.setTitle(`Helloclass - ${description}`));
    this.schoolName$ = this.route.queryParams.pipe(
      filter(params => params['schoolName']),
      map(params => params['schoolName']),
      take(1),
    )
  }

  ngOnDestroy() {
    this.titleSubscription.unsubscribe();
  }
}
