// https://stackoverflow.com/questions/48350506/how-to-validate-password-strength-with-angular-5-validator-pattern
import {FormControl} from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class StrongPasswordValidator {

  public static strong(control: FormControl): ValidationResult {

    // don't check for empty (must be covered by Validators.required)
    if (control.value.length === 0) {
      return null;
    }

    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    let hasLower = /[a-z]/.test(control.value);
    let hasSpecial = /[!@#$%^&*(),.?":{}|<>_+\-]/.test(control.value); // https://www.regextester.com/99810
    const valid = hasNumber && hasUpper && hasLower && hasSpecial;
    if (!valid) {
      // return what´s not valid
      return {strong: true};
    }
    return null;
  }
}
