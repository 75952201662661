import {ChangeDetectionStrategy, Component, ViewChild, OnInit} from '@angular/core';
import {AppState, getAuthUser} from "../reducers";
import {Store} from "@ngrx/store";
import {RelationshipComponent} from "./relationship.component";
import {Router, ActivatedRoute} from "@angular/router";
import {map, take, tap} from 'rxjs/operators';
import {Observable} from "rxjs";


@Component({
    selector: 'hc-relationship-onboarding-done',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="container-fluid container-fluid--limit relationship-onboarding">
            <div class="relationship-onboarding__main-block">
                <div class="relationship-onboarding__text-block">
                    <p class="relationship-onboarding__done-lead text-size-form"><span translate>Du hast dich erfolgreich mit</span>&nbsp;<span class="text-emph">{{selectedUserName$ | async}}</span>&nbsp;<span translate>verknüpft</span>.</p>
                    <img class="relationship-onboarding__title-image relationship-onboarding__title-image--bottom-margin" src="assets/icons/relationship.svg" alt="relationships" />
                    <p class="relationship-onboarding__add-person text-emph text-size-form" translate>
                        Willst du dich mit einer weiteren Person verknüpfen?
                    </p>
                </div>
                <button
                    (click)="addRelationship()"
                    data-cy="onboarding-add-relationship"
                    class="btn btn--blue btn--innershadow btn--max-width relationship-onboarding__add-button" translate>Ja, mit weiterer Person verknüpfen</button>
                <button
                    (click)="done()"
                    data-cy="onboarding-add-relationship"
                    class="btn btn--gray btn--innershadow btn--max-width" translate>Nein, Verknüpfung abschliessen</button>
            </div>
            <footer class="relationship-onboarding__footer">
                <button (click)="edit()"
                        class="unstyled-button text-blue" translate>Verknüpfungen bearbeiten</button>
            </footer>
         </div>
    `,
})
export class RelationshipOnboardingDoneComponent implements OnInit {

    @ViewChild('relationshipComponent', {static: false}) public relationshipComponent: RelationshipComponent;

    readonly loggedInUser$ = this.store.select(getAuthUser);
    selectedUserName$: Observable<string>;

    constructor(private store: Store<AppState>,
                private router: Router,
                private route: ActivatedRoute) {
    }

    public addRelationship() {
        this.router.navigate(['/relationships/onboarding']);
    }

    public done() {
        this.router.navigate(['/klassenbuch/']);
    }

    public edit() {
        this.router.navigate(['/profile/']);
    }

     ngOnInit() {
        this.selectedUserName$ = this.route.queryParams
            .pipe(
                tap(s => console.warn(s)),
                map(params => params['name'])
            )
      }
}
