
import {of as observableOf, from as observableFrom, combineLatest } from 'rxjs';
import {withLatestFrom, tap, mergeMap, catchError, map, switchMap, take, filter, pairwise, startWith} from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { EffectsWithRouter } from '../effects/router-effect';
import { SYSTEM_ERROR_KEY } from '../app.constants';
import { AppState, getAuthUser, getSelectedGroupId, getGroupsForSchool } from "../reducers";
import { Group, User, UserData, NewGroup } from "../models";
import { HelloClassApiService } from "../services/helloclass";
import { UserDataAction, AuthActionTypes } from "../actions/authentication";
import * as groups from "../actions/groups";
import {
    LoadGroupsAction, LoadGroupsSuccessAction, LoadGroupsFailAction, LoadGroupsMembersSuccessAction,
    LoadGroupsMembersFailAction, AddGroupSuccessAction, AddGroupFailAction, DeleteGroupAction,
    DeleteGroupFailAction, DeleteGroupSuccessAction, AddMembersToGroupFailAction, AddMembersToGroupSuccessAction,
    RemoveMembersFromGroupSuccessAction, RemoveMembersFromGroupFailAction, LoadGroupsMembersAction,
    HideRemoveUserAction, LoadGroupsForSchoolSuccessAction, LoadGroupsForSchoolFailAction,
    LoadGroupFailAction, LoadGroupSuccessAction, UpdateGroupSuccessAction, UpdateGroupFailAction,
    GenerateActivationLinksSuccessAction, GenerateActivationLinksFailAction
} from "../actions/groups";
import {
    SetSelectDisplayGroupAction, AddSelectDisplayGroupAction, RemoveSelectDisplayGroupAction
} from "../actions/klassenbuch";
import { UiHideMenuAction } from '../actions/ui';
import { AddAlertAction, AddToastAction } from "../alerts/alerts.actions";

@Injectable()
export class GroupsEffects extends EffectsWithRouter {

    constructor(private actions$: Actions,
                private store: Store<AppState>,
                private helloclassApi: HelloClassApiService,
                injector: Injector) {
        super(injector);
    }

     loadGroups$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.LOAD_GROUPS),
        switchMap(() => this.store.select(getAuthUser).pipe(filter((userData: UserData) => userData.id > -1),take(1),)),
        switchMap((userData: UserData) => this.helloclassApi.getGroups(userData.schoolId).pipe(
            map(data => {
                return new LoadGroupsSuccessAction(data);
                // return new TodaySelectedAction();
            }),
            catchError(error => observableOf(new LoadGroupsFailAction(error))),) // todo: do dispatch notification
        ),));

     loadGroup$ = createEffect(() => combineLatest([
        this.actions$.pipe(
            ofType<groups.LoadGroupAction>(groups.GroupsActionTypes.LOAD_GROUP),
            map(action => action.payload)),
            this.store.select(getAuthUser).pipe(
                filter(authUser => authUser.id !== -1),
                take(1),)
        ])
    .pipe(switchMap((values: [string, UserData]) => this.helloclassApi.getGroup(values[ 1 ].schoolId, +values[ 0 ]).pipe(
        map(data => new LoadGroupSuccessAction(data)),
        catchError(error => observableOf(new LoadGroupFailAction(error))),) // todo: do dispatch notification
    )));

     loadGroupsForSchool$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.LOAD_GROUPS_FOR_SCHOOL),
        switchMap(() => this.store.select(getAuthUser).pipe(filter((userData: UserData) => userData.id > -1), take(1), )),
        switchMap((userData: UserData) => this.helloclassApi.getGroupsForSchool(userData.schoolId).pipe(
            map(data => new LoadGroupsForSchoolSuccessAction(data)),
            catchError(error => observableOf(new LoadGroupsForSchoolFailAction(error))), ) // todo: do dispatch notification
        ),));

     initSelectedGroups$ = createEffect(() => combineLatest([
        this.actions$.pipe(
          ofType<any>(AuthActionTypes.AUTH_USER_DATA_SUCCESS),
              // start with fake non-signedin user
              startWith({
                  payload: {
                      id: -1
                  }
              }),
              pairwise(),
              map(actions => ({prevUserData: actions[0].payload, currentUserData: actions[1].payload})),
              filter(userData => userData.prevUserData.id === -1 && userData.currentUserData.id > -1),
              map(userData => userData.currentUserData.selectedGroups)),
              this.actions$.pipe(ofType(groups.GroupsActionTypes.LOAD_GROUPS_SUCCESS))
      ]).pipe(
        filter<any>(values => values[1].payload.length > 0),
        take(1),
        map((values) => {
            // if initial value is set (-1) select all groups
            let selectedGroupIds = [-1];
            if (values[0] !== undefined) {
                selectedGroupIds = values[0];
            }
            if (selectedGroupIds.length === 1 && selectedGroupIds[0] === -1) {
                selectedGroupIds = values[1].payload.map((group) => group.id);
            }
            return new SetSelectDisplayGroupAction(selectedGroupIds);
      })));


     loadGroupsFail$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.LOAD_GROUPS_FAIL),
        map((values) => new AddToastAction({ type: 'fail', message: 'Es ist ein Serverfehler aufgetreten.'}))));

     loadGroupMembers$ = createEffect(() => this.actions$.pipe(
        ofType<any>(groups.GroupsActionTypes.LOAD_GROUP_MEMBERS),
        map(action => action.payload),
        withLatestFrom(this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1))),
        switchMap((values: [number, UserData]) => this.helloclassApi.getGroupMembers(values[ 1 ].schoolId, '' + values[ 0 ]).pipe(
            map(data => new LoadGroupsMembersSuccessAction(data)),
            catchError(error => observableOf(new LoadGroupsMembersFailAction(error))),) // todo: do dispatch notification
        ),));

     loadGroupMembersFail$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.LOAD_GROUP_MEMBERS_FAIL),
        map((values) => new AddToastAction({ type: 'fail', message: 'Es ist ein Serverfehler aufgetreten.'}))));

     loadGroupIfListEmpty$ = createEffect(() => combineLatest([
      this.actions$.pipe(
        ofType<groups.LoadGroupsMembersAction>(groups.GroupsActionTypes.LOAD_GROUP_MEMBERS),
        map(action => action.payload)),
        this.store.select(getGroupsForSchool)
    ]).pipe(
      map((values) => {
          if (values[ 1 ].length > 0) {
              // don't load
              return { type: "NOP", payload: null };
          } else {
              return new LoadGroupsAction();
          }
      })
  ));

     addGroup$ = createEffect(() => combineLatest([
        this.actions$.pipe(
        ofType<groups.AddGroupAction>(groups.GroupsActionTypes.ADD_GROUP),
        map(action => action.payload)),
        this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1),
            take(1))
        ]).pipe(switchMap((values: [NewGroup, UserData]) => this.helloclassApi.addGroup(values[ 1 ].schoolId, values[ 0 ]).pipe(
                map((data: Group) => {
                    if (this.getRouter().url.indexOf('/addgroupwizard') > -1) {
                        this.getRouter().navigate([ '/adduserwizard' ]);
                        return new AddGroupSuccessAction(data);
                    } else {
                        this.getRouter().navigate([ '/admin/groups' ]); //todo: make own effect?
                        return new AddGroupSuccessAction(data);
                    }
                }),
                catchError(error => observableOf(new AddGroupFailAction(error))),) // todo: do dispatch notification
    )));


     addGroupFail$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.ADD_GROUP_FAIL),
        map(action => new AddAlertAction({message: SYSTEM_ERROR_KEY, type: 'fail' }))));

     deleteUsersConfirmation$ = createEffect(() => this.actions$.pipe(
        ofType<groups.DeleteGroupConfirmationAction>(groups.GroupsActionTypes.DELETE_GROUP_CONFIRMATION),
        tap(action => this.store.dispatch(new UiHideMenuAction())),
        map(action => new DeleteGroupAction(action.payload)),));

     deleteUsersCancel$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.DELETE_GROUP_CANCEL),
        map(action => new UiHideMenuAction())));

     deleteGroup$ = createEffect(() => combineLatest([
        this.actions$.pipe(
            ofType<groups.DeleteGroupAction>(groups.GroupsActionTypes.DELETE_GROUP),
            map(action => action.payload)),
            this.store.select(getAuthUser).pipe(
                filter(authUser => authUser.id !== -1),
                take(1),
                map(user => user.schoolId),)
        ]).pipe(
                switchMap((values: [number, number]) => this.helloclassApi.deleteGroup(values[ 1 ], values[ 0 ]).pipe(
                    withLatestFrom(observableOf({id: values[ 0 ]})),
                    map((response: [any, any]) => new DeleteGroupSuccessAction(response[1])),
                    catchError(error => {
                        this.getRouter().navigate([ '/admin/groups' ]);
                        return observableOf(new DeleteGroupFailAction(error));
                    }),
                )
        )));

     DeleteGroupSuccessAction$ = createEffect(() => this.actions$.pipe(
        ofType<groups.DeleteGroupSuccessAction>(groups.GroupsActionTypes.DELETE_GROUP_SUCCESS),
        map((action: any) => {
            this.getRouter().navigate([ '/admin/groups' ]);
            this.store.dispatch(new RemoveSelectDisplayGroupAction(action.payload));
            this.store.dispatch(new AddToastAction({ type: 'success', message: 'Die Gruppe wurde gelöscht'}));
            return new LoadGroupsAction();
        })));

     addGroupSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<groups.AddGroupSuccessAction>(groups.GroupsActionTypes.ADD_GROUP_SUCCESS),
        mergeMap((action) => {
            if (this.getRouter().url.indexOf('/addgroup') > -1) {
                return observableFrom([new AddSelectDisplayGroupAction(action.payload.id), new UserDataAction()]);
            } else {
                this.getRouter().navigate([ '/admin/groups', action.payload.id, 'members' ]);
                return observableFrom([new AddSelectDisplayGroupAction(action.payload.id)]);
            }
        })));

     addMembersToGroup$ = createEffect(() => this.actions$.pipe(
        ofType<groups.AddMembersToGroupAction>(groups.GroupsActionTypes.ADD_MEMBERS_TO_GROUP),
        map(action => action.payload),
        withLatestFrom(
            this.store.select(getSelectedGroupId),
            this.store.select(getAuthUser).pipe(
                filter(authUser => authUser.id !== -1),
            map(user => user.schoolId),)),
        switchMap((values) => this.helloclassApi.addGroupMembers(values[2], values[1], <User[]>values[0]).pipe(
            map((response) => {
                return new AddMembersToGroupSuccessAction();
            }),
            catchError(error => {
                return observableOf(new AddMembersToGroupFailAction(error));
            }),)
        ),));

     addMembersFail$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.ADD_MEMBERS_TO_GROUP_FAIL),
        map(() => new AddAlertAction({message: SYSTEM_ERROR_KEY, type: 'fail' }))));

     AddMembersToGroupSuccessAction$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.ADD_MEMBERS_TO_GROUP_SUCCESS),
        withLatestFrom(this.store.select(getSelectedGroupId)),
        map((values) => {
            this.getRouter().navigate([ '/admin/groups', values[ 1 ], 'members' ]);
            return new LoadGroupsAction();
        }),));

     removeMembersFromGroup$ = createEffect(() => this.actions$.pipe(
        ofType<any>(groups.GroupsActionTypes.REMOVE_MEMBERS_FROM_GROUP),
        map(action => action.payload),
        withLatestFrom(this.store.select(getSelectedGroupId),
            this.store.select(getAuthUser).pipe(
                filter(authUser => authUser.id !== -1),
                map(user => user.schoolId),)),
        switchMap((values) => this.helloclassApi.removeGroupMembers(values[ 2 ], values[ 1 ], values[ 0 ]).pipe(
            map((response) => {
                return new RemoveMembersFromGroupSuccessAction();
            }),
            catchError(error => {
                return observableOf(new RemoveMembersFromGroupFailAction(error));
            }),)
        ),));

     removeMembersFromGroupSuccessAction$ = createEffect(() => this.actions$.pipe(
        ofType<groups.RemoveMembersFromGroupSuccessAction>(groups.GroupsActionTypes.REMOVE_MEMBERS_FROM_GROUP_SUCCESS),
        withLatestFrom(this.store.select(getSelectedGroupId)),
        tap(() => {
            this.store.dispatch(new HideRemoveUserAction());
            this.store.dispatch(new AddToastAction({ type: 'success', message: 'Die Personen wurden entfernt'}))
        }),
        map((values) => {
            return new LoadGroupsMembersAction(values[ 1 ]);
        }),));

    // todo: refactor with similar auth (multiple...) methods

     generateActivationLinks$ = createEffect(() => this.actions$.pipe(
        ofType<groups.GenerateActivationLinksAction>(groups.GroupsActionTypes.GENERATE_ACTIVATION_LINKS),
        map(action => action.payload),
        switchMap(payload => this.helloclassApi.generateMultiplePasswords(payload).pipe(
            map(() => new GenerateActivationLinksSuccessAction() ),
            catchError((error) => {
                return observableOf(new GenerateActivationLinksFailAction(error));
            }),)
        ),));

     generateActivationLinksSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.GENERATE_ACTIVATION_LINKS_SUCCESS),
        withLatestFrom(this.store.select(getSelectedGroupId)),
        map( (values) => new LoadGroupsMembersAction( values[ 1 ]) ),))

     generateActivationLinksFail$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.GENERATE_ACTIVATION_LINKS_FAIL),
        map(() => {
            return new AddAlertAction({message: 'Es ist ein Fehler aufgetreten!', type: 'fail' });
        })));

     updateGroup$ = createEffect(() => combineLatest([
          this.actions$.pipe(
              ofType<groups.UpdateGroupAction>(groups.GroupsActionTypes.UPDATE_GROUP),
              map(action => action.payload)
          ),
          this.store.select(getAuthUser).pipe(
              filter(authUser => authUser.id !== -1),
              take(1),)
      ]).pipe(
          switchMap((values: [NewGroup, UserData]) => this.helloclassApi.updateGroup(values[ 1 ].schoolId, values[ 0 ].id, values[ 0 ]).pipe(
          map((data: Group) => {
              this.getRouter().navigate([ '/admin/groups' ]);
              return new UpdateGroupSuccessAction(data);
          }),
        catchError(error => observableOf(new UpdateGroupFailAction(error))),)
    )));


     updateGroupSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.UPDATE_GROUP_SUCCESS),
        map((action) => {
            this.getRouter().navigate([ '/admin/groups']);
            this.store.dispatch(new AddToastAction({ type: 'success', message: 'Die Änderungen wurden gespeichert'}))
            return new LoadGroupsAction();
        })));

     updateGroupFail$ = createEffect(() => this.actions$.pipe(
        ofType(groups.GroupsActionTypes.UPDATE_GROUP_FAIL),
        map((action) => {
            this.store.dispatch(new AddToastAction({ type: 'fail', message: 'Es ist ein Serverfehler aufgetreten.'}))
            return new LoadGroupsAction();
        })));
}
