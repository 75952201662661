import {Component} from "@angular/core";


@Component({
  selector: 'hc-landing-app-page',
  template: `
    <div class="page">
      <div class="page__header">
        <hc-simple-header></hc-simple-header>
      </div>
      <div class="page__content page-content page-content--full-height">

        <hc-alert></hc-alert>

        <div class="lead-in page-content__lead">
          <h1 class="lead lead-in__heading" translate>Melde dich jetzt an</h1>
        </div>

        <div class="login page-content__content">
          <hc-auth-app-login></hc-auth-app-login>
        </div>

        <div class="vertical-margin registration-c2a page-content__bottom">
          <p><span translate>Um die App zu verwenden benötigst du ein Helloclass Premium Konto.</span>
        </div>
      </div>
    </div>
  `
})
export class LandingAppPage {

  constructor() {
  }

}
