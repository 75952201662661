import {Component, Input} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {Store} from "@ngrx/store"
import {Subscription} from "rxjs"

import * as reducer from "../reducers"
import {getLoginFormErrors} from "../reducers"
import {LoginFormErrors} from '../models/auth'
import {LoginAction, LoginAddFormErrorAction} from "../actions/authentication"
import {EDULOG_SSO} from "../app.constants"

/**
 * Tip: Export type aliases for your component's inputs and outputs. Until we
 * get better tooling for templates, this helps enforce you are using a
 * component's API with type safety.
 */


const QUERY = 'action=login'

@Component({
  selector: 'hc-auth-login',
  template: `
    <div class="login">
      <div *ngIf="formErrors?.general">
        <p class="form__input_error text-size-small qa-login-general-error">
          <i aria-hidden="true" class="sdx-icon icon-026-exclamation-mark-circle"></i> <span
          [innerHtml]="formErrors['general']"></span></p>
      </div>
      <form [formGroup]="form" (submit)="formSubmit()" class="login__form">
        <div class="form-group">
          <hc-form-field
            [errors]="formErrors"
            [fieldName]="'username'"
            [labelText]="'Benutzername/Email'">
            <input class="qa-login-username"
                   type="text" name="username"
                   formControlName="username"
                   arial-label="username"
                   autofocus/>
          </hc-form-field>
        </div>
        <div class="form-group">
          <hc-form-field [errors]="formErrors" [fieldName]="'password'"
                         [labelText]="'Passwort'">
            <input class="qa-login-password"
                   type="password"
                   name="password"
                   formControlName="password"
                   arial-label="password"
                   autocomplete="off"/>
          </hc-form-field>
        </div>
        <button
          [ngClass]="{'btn--blue-dark': !standalone, 'btn--blue': standalone}"
          class="btn btn--bigger qa-login-button btn--full-width login-form__button btn--innershadow"
          type="submit">
          {{'Anmelden' | translate }}
        </button>
        <div class="login__reset-link">
          <p [ngClass]="{'text-center': standalone}">
            <a routerLink="/forgotpassword" class="a--no-highlight">{{"Passwort vergessen?" | translate}}</a>
          </p>
        </div>
        <p class="login__separator" translate>oder</p>
        <div *ngIf="!standalone; else edulogContainer">
          <a class="edulog-login" href="{{EDULOG_REDIRECT}}">
            <span translate>Anmelden mit</span> <img class="edulog-login__icon inline" src="../assets/icons/edulog.svg"
                                                     alt="edulog"/>
          </a>
        </div>

        <ng-template #edulogContainer>
          <hc-edulog-widget [buttonLink]="EDULOG_QUERY" [buttonText]="'Mit Edulog anmelden'">
            <p translate>Willst du dich mit deinen Edulog Zugangsdaten anmelden?</p>
          </hc-edulog-widget>
        </ng-template>
      </form>
    </div>
  `
})
export class AuthLoginComponent {
  public formErrors: LoginFormErrors = {
    password: '',
    username: '',
    general: ''
  }

  @Input() standalone = true

  public form: FormGroup
  public EDULOG_REDIRECT = `${EDULOG_SSO}${QUERY}&redirect=true`
  public EDULOG_QUERY = QUERY
  private formErrorSubscription: Subscription

  constructor(formBuilder: FormBuilder,
              private store: Store<reducer.AppState>) {
    this.form = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.formErrorSubscription = store.select(getLoginFormErrors).subscribe(errors => this.formErrors = errors);
  }

  public formSubmit(): void {
    if (this.form.valid) {
      this.store.dispatch(new LoginAction({username: this.form.value.username, password: this.form.value.password}));
    } else {
      let errors = {};

      for (let control in this.form.controls) {
        if (this.form.controls[control].errors) {
          // todo allow multiple errors && only handles required so far....
          let key = '';
          switch (control) {
            case 'username':
              key = 'Benutzername oder Email fehlt';
              break;
            case 'password':
              key = 'Passwort fehlt';
              break;
          }
          errors[control] = [key];
        }
      }
      ;
      this.store.dispatch(new LoginAddFormErrorAction(errors));
    }
  }

  ngOnDestroy() {
    this.formErrorSubscription.unsubscribe();
  }
}
