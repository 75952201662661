/**
 * Created by christiancueni on 06/07/16.
 */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {API_URL} from '../app.constants';

import {FileUploader} from 'ng2-file-upload';
import {CsrfService} from '../hc-requests/csrf.service';

@Component({
  selector: 'hc-fileupload',
  template: `
    <div class="file-upload">
      <div *ngIf="!isUploading" class="file-upload__uploader uploader">
        <div class="uploader__field upload-field">
          <input class="upload-field__input" type="file" ng2FileSelect [uploader]="uploader" multiple/>
          <span class="upload-field__icon icon-upload"></span>
          <p class="upload-field__label">
            {{ 'Datei hochladen' | translate }}
          </p>
        </div>
        <div class="uploader__max-size">
          <p>{{ 'Maximale Dateigrösse: 10 MB' | translate }}</p>
        </div>
      </div>
      <div *ngIf="isUploading" class="queue">
        <ul class="queue-list unstyled-list">
          <li class="queue-list__item" *ngFor="let item of uploader.queue"
              [ngClass]="{'queue-list__item--hidden': !item.isUploading}">
            <div class="queue-item">
              <span><i
                class="glyphicon glyphicon-refresh glyphicon-refresh--rotate"></i>{{ 'Datei wird hochgeladen' | translate }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  `
})
export class FileUploadComponent {

  @Input() authToken: string;
  @Output() uploadSuccess = new EventEmitter();
  @Output() uploadError = new EventEmitter();

  public uploader: FileUploader = null;
  public isUploading = false;

  constructor(private csrfService: CsrfService) {
  }

  ngOnInit() {
    let token = "Bearer " + this.authToken;
    let headers = [{name: 'Accept', value: '*/*'}, {name: 'X-CSRFToken', value: this.csrfService.getToken()}];
    this.uploader = new FileUploader({url: API_URL + "/file/", authToken: token, headers: headers});

    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
      item.upload();
      this.isUploading = true;
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.isUploading = false;
      if (status === 202) {
        this.uploadSuccess.emit(JSON.parse(response));
      } else {
        this.uploadError.emit(response);
      }
    };
  }

}
