import {Component, ChangeDetectionStrategy, OnInit} from "@angular/core";
import {EDULOG_SSO} from "../app.constants";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'hc-edulog-confirm-role',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="container-fluid container-fluid--limit signup-page">
            <div class="lead-in">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="heading-2 qa-title" translate>Du bist gerade im Begriff ein neues Helloclass-Konto für eine Schule, oder Klasse zu registrieren.</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10">
                    <p class="text-size-form vertical-margin" translate>Willst du mit dieser Registrierung wirklich fortfahren?</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-md-offset-1">
                    <p class="text-size-form gutter-mt text-center">
                        <a href="{{edulogUrl}}" translate>Ja, ich will die Registierung abschliessen</a>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-md-offset-1">
                    <p class="text-size-form gutter-mt text-center">
                        <a href="/" translate>Nein, ich will abbrechen</a>
                    </p>
                </div>
            </div>
        </div>
    `
})
export class EdulogConfirmRolePage implements OnInit {
    public edulogUrl = `${EDULOG_SSO}override=true&redirect=true&session=`

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            this.edulogUrl += params?.session ? params?.session : ''
          }
        );
      }
}
