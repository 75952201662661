export interface RelationshipUser {
    id: number;
    first_name: string;
    last_name: string;
}

// maybe make 2 types relationship and viewrelationship
export interface Relationship {
    id: number;
    dirty?: boolean;
    index?: number;
    guardian: RelationshipUser;
    cared_for: RelationshipUser;
}

export enum FORTYPE {
  Guardian = 'guardian',
  CaredFor = 'cared_for',
}

export interface RelationshipState {
    relationships: Relationship[]; // source of truth
    displayRelationships: Relationship[]; // SDX has some issues with updates, so we don't update the realtinships in this state
    users: RelationshipUser[];
    selectedUsers: number[];
    relationshipsToDelete: number[];
    selectedType: FORTYPE;
}

export interface GetRelationshipParams {
    schoolId: number;
    userId: number;
}

export interface GetRelationshipAdminParams {
    schoolId: number;
    groups: number[];
}

export interface RelationshipUpdate {
    schoolId: number;
    userId: number;
    newRelationships: Relationship[];
    updatedRelationships: Relationship[];
    deletedRelationships: number[]
}
