import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {JWT_CHECK_INTERVAL, JWT_REFRESH_DELTA_TIME} from '../app.constants';
import {Tokens} from "../models/auth";
import {Store} from "@ngrx/store";
import * as fromAuth from "../reducers";
import {AuthRefreshTokenAction} from "../actions/authentication";

const TOKEN_ID = 'token_id';
const REFRESH_ID = 'refresh_id';


@Injectable({
  providedIn: 'root',
})
export class TokenService {

  jwtHelper = new JwtHelperService()
  // todo: move tokens to state, save to storage in effect
  accessToken = ''
  refreshToken = ''
  timeoutId: any = -1

  constructor(
    private store: Store<fromAuth.AppState>
  ) {
  }

  saveTokens(tokens: Tokens): void {
    try {
      localStorage.setItem(TOKEN_ID, tokens.access)
      localStorage.setItem(REFRESH_ID, tokens.refresh)
    } catch (e) {
      window.location.href = `https://helloclass.zendesk.com/hc/de/articles/360032258152-Welche-technischen-Anforderungen-m%C3%BCssen-erf%C3%BCllt-sein-um-Helloclass-zu-verwenden-`;
    } finally {
      this.accessToken = tokens.access
      this.refreshToken = tokens.refresh
    }
  }

  updateAccessToken(accessToken: string) {
    localStorage.setItem(TOKEN_ID, accessToken)
    this.accessToken = accessToken
  }

  startRefreshCheck() {
    if (this.timeoutId === -1 && this.accessToken) {
      this.refreshCheck()
    }
  }

  private refreshCheck() {
    if (this.isTokenExpired(this.refreshToken)) {
      this.clearRefresh()
      return
    }

    this.timeoutId = setTimeout(() => {
      this.refreshIfAboutToExpire()
      this.refreshCheck()
    }, JWT_CHECK_INTERVAL * 1000)
  }

  private refreshIfAboutToExpire() {
    if (this.isTokenAboutToExpire()) {
      this.store.dispatch(new AuthRefreshTokenAction(this.refreshToken))
    }
  }

  private clearRefresh() {
    clearTimeout(this.timeoutId)
    this.timeoutId = -1
  }

  private tokenIsValid(token: string): boolean {
    return token && !this.isTokenExpired(token)
  }

  accessTokenIsValid(): boolean {

    return this.tokenIsValid(this.accessToken)
  }

  refreshTokenIsValid(): boolean {
    return this.tokenIsValid(this.refreshToken)
  }

  getTokens(): Tokens {
    try {
      this.accessToken = localStorage.getItem(TOKEN_ID)
      this.refreshToken = localStorage.getItem(REFRESH_ID)
    } catch (e) {
    }

    return {
      access: this.accessToken,
      refresh: this.refreshToken
    }
  }

  deleteTokens(): void {
    try {
      localStorage.removeItem(TOKEN_ID)
      localStorage.removeItem(REFRESH_ID)
      this.clearRefresh()
    } catch (e) {
    } finally {
      this.accessToken = ''
      this.refreshToken = ''
    }
  }

  isExpired(): boolean {
    return this.isTokenExpired(this.getTokens().access);
  }

  isTokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token)
  }

  getExpirationTimestamp(token) {
    return Math.floor(this.getExpirationDate(token).getTime());
  }

  //fixme: use this.token
  decodeToken(token: string): boolean {
    return this.jwtHelper.decodeToken(token);
  }

  isTokenAboutToExpire(): boolean {
    return this.isAboutToExpire(this.getTokens().access);
  }

  isAboutToExpire(token: string): boolean {
    if (!token) {
      return false;
    }
    const expirationTime = this.getExpirationTimestamp(token);
    const minRefreshTime = expirationTime - JWT_REFRESH_DELTA_TIME * 1000;
    return this.tokenWithinTimeRange(minRefreshTime, expirationTime);
  }

  // todo: use this string
  tokenWithinTimeRange(minTime: number, maxTime: number): boolean {
    let now = Date.now();
    return minTime < now && now < maxTime;
  }

  getTokenExpirationDate(): Date {
    return this.getExpirationDate(this.getTokens().access);
  }

  getExpirationDate(token: string): Date {
    return this.jwtHelper.getTokenExpirationDate(token);
  }

}
