import {ChangeDetectionStrategy, Component, EventEmitter, Output} from "@angular/core";
import {Store} from "@ngrx/store";
import * as reducers from "../reducers";
import {DocumentOrderFilter, DocumentOrderFilterType} from "./document-order-filter";
import {Observable} from "rxjs";
import {getDocumentOrderFilter} from "../reducers/index";
import {map} from "rxjs/operators";

@Component({
  selector: 'hc-document-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="document-filter btn-group overlay-dropdown my-[24px]" dropdown>
      <button
        id="filter-button"
        type="button"
        data-cy="document-order-button"
        class="document-filter__button filter-button"
        dropdownToggle
        aria-haspopup="true"><span class="text-emph text-[15px]" translate>Sortieren</span>: <span
        class="filter-button__order text-emph text-[15px]">{{(documentOrderFilter$ | async) | translate}}</span> <span
        class="icon-arrow-down inline-block ml-[8px]"></span></button>
      <div class="sm-full-selector dropdown-menu overlay-dropdown__menu !inset-0" *dropdownMenu
           hcOverlayHide>
        <ul class="sm-full-selector-list overlay-list unstyled-list" role="menu" aria-labelledby="filter-button">
          <li role="menuitem" *ngFor="let sortBy of orderFilters"
              class="sm-full-selector-list__item overlay-list__item">
            <button
              class="dropdown-item unstyled-button btn--dropdown"
              attr.data-cy="document-order-{{sortBy.key}}"
              (click)="order(sortBy)">
              {{ sortBy.title | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>

  `
})
export class DocumentFilterComponent {
  public documentOrderFilter$: Observable<string>;
  public orderFilters: DocumentOrderFilterType[];
  @Output() filterUpdate = new EventEmitter<DocumentOrderFilterType>();

  constructor(private store: Store<reducers.AppState>) {
    this.documentOrderFilter$ = this.store.select(getDocumentOrderFilter).pipe(
      map((filter: DocumentOrderFilter) => {
        for (let filterType of DocumentOrderFilter.FILTERS) {
          if (filterType.key === filter) {
            return filterType.title
          }
        }
      })
    )
    this.orderFilters = DocumentOrderFilter.FILTERS
  }

  order(sortBy) {
    this.filterUpdate.emit(sortBy)
  }

}
