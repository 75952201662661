/**
 * Created by christiancueni on 06/07/16.
 */
import { Component, Output, EventEmitter, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { Label } from '../admin/labels/labels.models';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown'

@Component({
    selector: 'hc-label-selection',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div class="label-selection">
        <div class="btn-group label-dropdown dropdown" dropdown #dropdown="bs-dropdown" (isOpenChange)="onOpenChange()">
                <button type="button"
                    class="unstyled-button qa-label-selection"
                    dropdownToggle>
                        <span class="label-dropdown__button"
                              [ngClass]="'unstyled-button--' + selectedLabel?.color">{{ selectedLabel?.title }}</span><span [ngClass]="{'dropdown__icon--inverted': invertedIcon}"
                        class="icon-arrow-down label-dropdown__icon dropdown__icon"></span>
                </button>
            <div class="sm-full-selector dropdown-menu overlay-dropdown__menu" *dropdownMenu hcOverlayHide (hideOverlay)="hideOverlay()">
                <ul role="menu" aria-labelledby="single-button" class="overlay-list unstyled-list sm-full-selector-list">
                    <li role="menuitem" *ngFor="let label of labels" class="overlay-list__item sm-full-selector-list__item" (click)="selectLabel(label)" role="button">
                        <div [ngClass]="'unstyled-button--' + label.color" class="dropdown-item unstyled-button label-pill" type="button">
                            <span class="label-pill__text" >{{label.title}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    `
})
export class LabelSelectionComponent {

    @Input() labels: Label[] = [];
    @Input() selectedLabel: Label;
    @Output() labelSelected = new EventEmitter<Label>();
    @Output() errors = new EventEmitter<Object>();
    @ViewChild(BsDropdownDirective, {static: false}) public dropdown: BsDropdownDirective;

    public invertedIcon = false;

    constructor() { }

    public selectLabel(label: Label) {
        this.labelSelected.emit(label);
        this.invertedIcon = false;
    }

    public onOpenChange() {
        this.invertedIcon = !this.invertedIcon;
    }

    public hideOverlay() {
        this.dropdown.hide();
    }
}
