
import { Observable, combineLatest } from 'rxjs';

import {tap, map} from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Component } from '@angular/core';

import { UiForceContextDropdown, UiLiberateContextDropdown } from '../actions/ui';
import { AddSelectDisplayGroupAction } from '../actions/klassenbuch';
import { RemoveSelectDisplayGroupAction } from '../actions/klassenbuch';
import * as reducer from '../reducers';

@Component({
    selector: 'hc-klassenbuch-context',
    // changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div class="dropdown open">
        <ul class="dropdown-menu context-dropdown sm-full-selector-list context-dropdown--has-transparency"
            [ngClass]="{'context-dropdown--force': forceDropdown$ | async, 'is-hidden': !(menuOpen$ | async), 'is-transparent': 'initialHide'}"
            aria-labelledby="dropdownMenu1" #dropdown>
            <li *ngFor="let group of groups$ | async" class="context-dropdown__item sm-full-selector-list__item" (click)="selectedGroupChange($event, group)" data-cy="classSelectionItem">
                <span>
                    <i class="context-checkbox header-group-checkbox" [ngClass]="{'icon-check-circle': group.selected, 'icon-circle': !group.selected, 'font-text-gray': group['selected'] && this.activeElements === 1 }" aria-hidden="true"></i>
                    {{group.group.display_name}}
                </span>
            </li>
        </ul>
    </div>`
})
export class GroupSelectionContextComponent {

    public groups$: Observable<any>;
    public forceDropdown$: Observable<boolean>;
    public menuOpen$: Observable<boolean>;
    private activeElements: number;
    public initialHide = true;

    constructor(private store: Store<reducer.AppState>) {

        this.forceDropdown$ = store.select(reducer.getForceContextDropdown);
        this.menuOpen$ = store.select(reducer.getContextMenuOpen);

        this.groups$ = combineLatest([
            store.select(reducer.getGroups),
            store.select(reducer.getSelectedDisplayGroups)
        ]).pipe(
            map((values) => {

                let allGroups = values[0];
                let selectedGroups = values[1];
                this.activeElements = 0;

                const groups = allGroups.map(group => {
                    const selected = selectedGroups.some(selectedGroup => group.id === selectedGroup.id);

                    if (selected) {
                        this.activeElements++;
                    };

                    return {
                        group,
                        selected
                    }
                });

                return groups;
            }),
            tap(groups => {
                // use dropdown for groups if too long (far from ideal solution)
                let charCount = 0;
                groups.map(group => charCount += group.group.display_name.length);
                if (charCount > 70) {
                    this.store.dispatch(new UiForceContextDropdown());
                } else {
                    this.store.dispatch(new UiLiberateContextDropdown());
                }
                this.initialHide = false;
            })
        );

    }

    public selectedGroupChange(event: Event, group: Object) {
        event.preventDefault();
        event.stopPropagation(); // don't close when open & clicked inside
        let isOnlyOneGroupSelected: boolean = group['selected'] && this.activeElements === 1;
        if (!isOnlyOneGroupSelected) {
            if (group['selected']) {
                this.store.dispatch(new RemoveSelectDisplayGroupAction(group['group']['id']));
            } else {
                this.store.dispatch(new AddSelectDisplayGroupAction(group['group']['id']));
            }
            group['selected'] = !group['selected'];
        }
    }

}
