import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import * as reducer from "../../reducers";
import { ForgotPasswordResetWizardPage } from "./forgotpasswordreset";


@Component({
    selector: 'hc-activate-user',
    template: `
    <div class="container-fluid">
      <hc-public-header></hc-public-header>
        <div class="row vertical-margin">
            <div class="col-md-5 col-md-offset-3">
                <hc-alert></hc-alert>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5 col-md-offset-3">
                <h2 class="h3">{{'Willkommen! Du wurdest zu Helloclass eingeladen.' | translate}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5 col-md-offset-3">
                <h3 class="text-size-normal">{{'Bist du unter 16 Jahre?' | translate}}</h3>
                <p class="text-gray" [innerHtml]="legalUnder | translate"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5 col-md-offset-3">
                <h3 class="text-size-normal">{{'Bist du über 16 Jahre?' | translate}}</h3>
                <p class="text-gray" [innerHtml]="legalOver | translate"></p>
            </div>
        </div>
        <hc-password-reset (submitForm)="reset($event)"></hc-password-reset>
    </div>
    `
})
export class ActivateUserPage extends ForgotPasswordResetWizardPage {

    public legalUnder = 'Du brauchst die Zustimmung deiner Eltern, um Helloclass zu nutzen. Wenn Du unten dein Passwort definierst und auf «Speichern» klickst, gehen wir davon aus, dass deine Eltern mit den <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Nutzungsbedingungen.pdf\">Nutzungsbedingungen</a>, den <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Privacy_Policy.pdf\">Datenschutzbestimmungen</a> und den <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Cookies.pdf\">Cookies-Bestimmungen</a> einverstanden sind.';
    public legalOver = 'Wenn du unten dein Passwort definierst und auf «Speichern» klickst, akzeptierst du die <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Nutzungsbedingungen.pdf\">Nutzungsbedingungen</a>, die <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Privacy_Policy.pdf\">Datenschutzbestimmungen</a> und die <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Cookies.pdf\">Cookies-Bestimmungen</a>.';

    constructor(store: Store<reducer.AppState>,
        router: Router,
        activatedRoute: ActivatedRoute) {

        super(store, router, activatedRoute);
    }

}
