import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {Relationship, RelationshipUser} from "./relationship.models";
import {ComponentStore} from "@ngrx/component-store";
import {map} from "rxjs/operators";
import {RelationshipStore} from "./relationship.store";

@Component({
    selector: 'hc-relationship-readonly',
    template: `
    <div class="relationship-readonly">
        <ul class="unstyled-list">
            <li *ngFor="let relationship of caredForRelationships$ | async">
                <p class="readonly-box"
                    data-cy="related-name">{{ displayName(relationship.guardian) }}</p>
            </li>
        </ul>
    </div>
    `,
    providers: [ComponentStore, RelationshipStore]
})
export class RelationshipReadonlyComponent implements OnInit {

    @Input() userId: number;
    @Input() schoolId: number;

    readonly caredForRelationships$ = this.relationshipStore.relationships$.pipe(
        map((relationships: Relationship[]) => {
            return relationships.filter(relationship => relationship.cared_for.id === this.userId)
        })
    );

    constructor(private readonly relationshipStore: RelationshipStore) {
    }

    public displayName (user: RelationshipUser) {
        return `${user.first_name} ${user.last_name}`
    }

    ngOnInit () {
        this.relationshipStore.fetchRelationships({
            schoolId: this.schoolId,
            userId: this.userId
        });
    }


}
