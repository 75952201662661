import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'hc-hint',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="hint" [ngClass]="{'hint--bubble': bubbleStyle}">
            <button class="hint__close-btn unstyled-button icon-cross" (click)="closeHint.emit()"></button>
            <div class="hint__header hint-header">
                <h4 class="hint-header__title" translate>{{ title }}</h4>
            </div>
            <div class="hint__body hint-body">
                {{ body }}
            </div>
        </div>
    `,
})
export class HintComponent {

    @Output() closeHint = new EventEmitter();
    @Input() title: string;
    @Input() body: string;
    @Input() bubbleStyle: boolean;
}
