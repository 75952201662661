import {map} from 'rxjs/operators';

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {Store} from '@ngrx/store';
import {AppState} from "../reducers";
import {AlertActionTypes} from '../alerts/alerts.actions';
import {ToastrService} from 'ngx-toastr';
import {CustomAction} from "../actions/CustomAction";
import {AlertMessage} from "./alerts.models";

@Injectable()
export class AlertsEffects {

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private toastr: ToastrService
  ) {
  }

  addToast$ = createEffect(() => this.actions$.pipe(
    ofType(AlertActionTypes.ADD_TOAST),
    map((a: CustomAction) => {
      const message: AlertMessage = a.payload
      const options = {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      }

      if (message.type === 'success') {
        this.toastr.success('', message.message, options);
      } else {
        this.toastr.error('', message.message, options)
      }
      return {type: 'NOP', payload: null}
    })))

}
