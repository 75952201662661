
import { Subscription, from, combineLatest } from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromNativeApp from '../reducers'
import { IS_APP } from '../app.constants';
import {AppSetOnline, AppSetOffline} from './app.actions';
import { getAppOnline } from '../reducers';

declare global {
    interface Navigator {
        notification:any
    }
}

@Injectable()
export class OfflineService {

    private isOnline = true;
    private isOnlineSubscription:Subscription;

    constructor(private store:Store<fromNativeApp.AppState>,
        private translateService: TranslateService) {
        this.isOnlineSubscription = this.store.select(getAppOnline)
            .subscribe(isOnline => this.isOnline = isOnline);
    }

    initOffline() {
        if (IS_APP) {
            document.addEventListener("offline", this.setOffline.bind(this), false);
            document.addEventListener("online", this.setOnline.bind(this), false);
        }
    }

    onDestroy() {
        this.isOnlineSubscription.unsubscribe();
    }

    private setOffline() {
        this.store.dispatch(new AppSetOffline());
    }

    private setOnline() {
        this.store.dispatch(new AppSetOnline());
    }

    public displayOfflineWarning(message, title, buttonName) {
        navigator.notification.alert(
            message,
            null, // callback
            title,
            buttonName
        )
    }

    public executeActionOnlyWhenOnlineRequired(requiresNetwork:boolean, fn:Function) {
        if (this.allowActionOnlyWhenOnlineRequired(requiresNetwork)) {
            fn();
        } else {
            combineLatest([
                from(this.translateService.get('Diese Funktion ist nur online verfügbar')),
                this.translateService.get('Du bist offline!'),
                this.translateService.get('Weiter')
            ]).pipe(
                take(1)
            ).subscribe(translations => this.displayOfflineWarning(translations[0], translations[1], translations[2]));
        }
    }

    private allowActionOnlyWhenOnlineRequired(requiresNetwork:boolean) {
        return !requiresNetwork || this.isOnline;
    }

}
