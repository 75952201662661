import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TemporaryTokenGuard} from "./tempToken.guard";
import {SimpleHeaderLayout} from "../pages/simple-header.layout";
import {EdulogTermsPage} from "./edulog.terms.page";
import {EdulogExistingUserPage} from "./edulog.existing-user.page";
import {EdulogNoUserPage} from "./edulog.no-user.page";
import {EdulogConfirmRolePage} from "./edulog.confirm-role.page";


const routes: Routes = [
    {
        path: 'cb',
        canActivate: [ TemporaryTokenGuard ],
        component: SimpleHeaderLayout
    },
    {
        path: 'terms',
        component: EdulogTermsPage
    },
    {
        path: 'existing-user',
        component: EdulogExistingUserPage
    },
    {
        path: 'no-user',
        component: EdulogNoUserPage
    },
    {
        path: 'confirm-create',
        component: EdulogConfirmRolePage
    }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EdulogRoutingModule {}
