import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import {SignupTermsComponent} from "../signup-terms/signup-terms.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule(
  {
    imports: [
        TranslateModule,
        CommonModule
    ],
    declarations: [
        SignupTermsComponent
    ],
    exports: [
        SignupTermsComponent
    ],
    providers: [
    ]
  })
export class SignupTermsModule {}
