import {of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import {AddAlertAction} from "../alerts/alerts.actions";
import {AppState} from "../reducers";
import {LoginFailureAction, LoginSuccessAction} from "../actions/authentication";
import {EdulogHttpService} from "./edulog.http.service";

/**
 * Guards are hooks into the route resolution process, providing an opportunity
 * to inform the router's navigation process whether the route should continue
 * to activate this route. Guards must return an observable of true or false.
 */
@Injectable()
export class TemporaryTokenGuard  {
  constructor(
    private store: Store<AppState>,
    private apiService: EdulogHttpService,
    private router: Router
  ) {
  }

  getTokensFromApi(token: string, id: string, context: string) {
    return this.apiService.getTokens(token, id).pipe(
      map((response) => {
        if (context === "signup") {
          return new LoginSuccessAction(response)
        } else {
          return new LoginSuccessAction(response)
        }
      }),
      map((action) => {
        this.store.dispatch(action);
        this.router.navigate(['/klassenbuch']);
        return false
      }),
      catchError((reason) => {
        if (context === "signup") {
          this.store.dispatch(new LoginFailureAction(reason));
        } else {
          this.store.dispatch(new LoginFailureAction(reason));
        }
        this.router.navigate(['/login']);
        return throwError(reason.statusText);
      }));
  }

  verifyParams(queryParams: Object) {
    if (!queryParams['state']) {
      this.router.navigate(['/login']);
      return of(false);
    }

    if (queryParams['state'] === 'success') {

      let context = "signup";

      // token is required for success
      if (!queryParams['token'] || !queryParams['id']) {
        this.router.navigate(['/login']);
        return of(false);
      }

      if (queryParams['context'] && queryParams['context'] === "login") {
        context = "login";
      }

      return this.getTokensFromApi(queryParams['token'], queryParams['id'], context);
    }

    this.store.dispatch(new AddAlertAction({message: queryParams['state'], type: 'fail'}));
    this.router.navigate(['/login']);
    return of(false);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.verifyParams(route.queryParams)
  }
}
