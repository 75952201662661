
import {of as observableOf } from 'rxjs';

import {tap, withLatestFrom, switchMap, filter, take, map, catchError} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { EffectsWithRouter } from '../effects/router-effect';
import { AppState, getAuthUser } from '../reducers';
import { HelloClassApiService } from '../services/helloclass';
import { AddToastAction } from '../alerts/alerts.actions';
import * as roles from './roles.actions';

import { RolesActionTypes, LoadRolesSuccessAction, LoadRolesFailAction,
    UpdateRolesActionFailAction, UpdateRolesActionSuccessAction } from '../roles/roles.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class RolesEffect extends EffectsWithRouter {

    constructor(private actions$: Actions,
                private store: Store<AppState>,
                private helloclassApi: HelloClassApiService,
                injector: Injector) {

        super(injector);
    }

     loadRoles$ = createEffect(() => this.actions$.pipe(
        ofType(RolesActionTypes.LOAD_ROLES),
        withLatestFrom(
            this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1),)
        ),
        switchMap((values) => this.helloclassApi.getRoles(values[1].schoolId).pipe(
            map(data => new LoadRolesSuccessAction(data)),
            catchError(error => observableOf(new LoadRolesFailAction(error))),) // todo: do dispatch notification
        ),));

     updateRoles$ = createEffect(() => this.actions$.pipe(
        ofType<any>(RolesActionTypes.UPDATE_ROLES),
        withLatestFrom(
            this.store.select(getAuthUser).pipe(
            filter(authUser => authUser.id !== -1),)
        ),
        switchMap((values) => this.helloclassApi.updateRoles(values[1].schoolId, values[0].payload).pipe(
            map(data => new UpdateRolesActionSuccessAction(data)),
            catchError((response: HttpErrorResponse) => {
                let data  = response.error;
                switch (response.status) {
                    case 409:
                        break;
                    case 500:
                        data['errors'] = [
                            { name: 'general',
                            errors: 'Es ist ein Serverfehler aufgetreten.'
                        }];
                        break;
                    default:
                        data['errors'] = [{ name: 'general', errors: 'Es ist ein Fehler aufgetreten. Bitte versuche es nochmals.'}];
                        break;
                }
                return observableOf(new UpdateRolesActionFailAction(data));
            }),) // todo: do dispatch notification
        ),));

     updateRolesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<roles.UpdateRolesActionSuccessAction>(RolesActionTypes.UPDATE_ROLES_SUCCESS),
        tap(() => {
            this.getRouter().navigate(['/klassenbuch']);
            this.store.dispatch( new AddToastAction({message: 'Die Daten wurden gespeichert!', type: 'success' }) );
        }),
        map(action => new LoadRolesSuccessAction(action.payload['data'])),));
}
