import {map} from 'rxjs/operators';
import * as dayjs from 'dayjs';
import type {AttachmentRequestParameters, AttachmentResponse} from "../attachment/attachment.model";

import {Inject, Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {
  BackendEvent,
  Comment,
  Credentials,
  FormEvent,
  FormUser,
  Group,
  NewGroup,
  ProfileUser,
  School,
  SchoolRole,
  SignupUser,
  User,
  ViewEvent
} from '../models';
import {Label} from '../admin/labels/labels.models';
import {Notification} from '../notifications/notifications.models';
import {SUPPORTED_LANGS} from '../app.constants';
import {HelloClassRequestsService} from '../hc-requests/hc-requests.services';
import {API} from '../app.tokens';
import {AdminMessage} from "../admin-messages/admin-message";
import {HttpParams} from "@angular/common/http";

@Injectable()
export class HelloClassApiService {
  private language = 'de';

  constructor(private hcRequests: HelloClassRequestsService, @Inject(API) private api) {
  }

  setLanguage(newLanguage: string) {
    this.language = (SUPPORTED_LANGS.filter((lang) => newLanguage === lang).length > 0) ? newLanguage : this.language
  }

  getLanguage() {
    return this.language
  }

  login(credentials: Credentials): Observable<Object> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/login/`,
      body: credentials,
      language: this.language
    });
  }

  appLogin(credentials: Credentials): Observable<Object> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/app/token/`,
      body: credentials,
      language: this.language
    });
  }

  logout(): Observable<Object> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/logout/`,
      language: this.language
    });
  }

  signupUser(newUser: SignupUser): Observable<Object> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/user`,
      body: Object.assign({}, newUser),
      language: this.language
    })
  }

  getUserData(): Observable<Object> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/users/me`,
      language: this.language
    });
  }

  deleteAccount(): Observable<any> {
    return this.hcRequests.request({
      method: 'delete',
      url: `${this.api}/users/me`,
      language: this.language
    });
  }

  getGroups(schoolId: number): Observable<Group[]> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/users/me/schools/${schoolId}/groups`,
      language: this.language
    });
  }

  getGroupsForSchool(schoolId: number): Observable<Group[]> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/schools/${schoolId}/groups`,
      language: this.language
    });
  }

  deleteGroup(schoolId: number, groupId: number): Observable<Group> {
    return this.hcRequests.request({
      method: 'delete',
      url: `${this.api}/schools/${schoolId}/groups/${groupId}`,
      language: this.language
    });
  }

  addGroup(schoolId: number, newGroup: NewGroup): Observable<Group> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/schools/${schoolId}/groups`,
      body: {
        year: newGroup.year,
        display_name: newGroup.displayName,
        import_from_id: newGroup.importFromGroup
      },
      language: this.language
    });
  }

  updateGroup(schoolId: number, groupId: number, newGroup: NewGroup): Observable<Group> {
    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/schools/${schoolId}/groups/${groupId}`,
      body: {
        year: newGroup.year,
        display_name: newGroup.displayName
      },
      language: this.language
    });
  }

  getGroupMembers(schoolId: number, id: string): Observable<User[]> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/schools/${schoolId}/groups/${id}/members`,
      language: this.language
    });
  }

  addGroupMembers(schoolId: number, id: number, newMembers: User[]): Observable<any> {
    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/schools/${schoolId}/groups/${id}/members`,
      body: {
        addMembers: newMembers
      },
      language: this.language
    });
  }

  removeGroupMembers(schoolId: number, id: number, members: User[]): Observable<any> {
    return this.hcRequests.request({
      method: 'put',
      url: `${this.api}/schools/${schoolId}/groups/${id}/members`,
      body: {
        removeMembers: members
      },
      language: this.language
    });
  }

  getUsers(schoolId: number): Observable<User[]> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/schools/${schoolId}/users`,
      language: this.language
    });
  }

  getUser(schoolId: number, userId: number): Observable<User> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/schools/${schoolId}/users/${userId}`,
      language: this.language
    });
  }

  getGroup(schoolId: number, groupId: number): Observable<Group> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/schools/${schoolId}/groups/${groupId}`,
      language: this.language
    });
  }

  addUser(schoolId: number, user: FormUser): Observable<User> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/schools/${schoolId}/users`,
      body: user,
      language: this.language
    });
  }

  updateUser(schoolId: number, userId: number, user: FormUser): Observable<User> {
    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/schools/${schoolId}/users/${userId}`,
      body: user,
      language: this.language
    });
  }

  deleteUsers(users: number[], schoolId: any) {
    return this.hcRequests.request({
      method: 'POST',
      url: `${this.api}/schools/${schoolId}/users`,
      body: users,
      language: this.language,
      httpOverwriteMethod: 'DELETE'
    });
  }

  updateProfile(user: ProfileUser) {
    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/users/me`,
      body: user,
      language: this.language
    });
  }

  refreshToken(refresh: string): Observable<{ access: string }> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/app/token/refresh/`,
      body: {refresh},
      language: this.language
    });
  }

  generateVerificationEmail(): Observable<Object> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/email/verify/generate`,
      language: this.language
    });
  }

  verifyEmail(code: string): Observable<Object> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/email/verify/activate/${code}`,
      language: this.language
    });
  }


  resetPassword(payload: Object): Observable<Object> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/password`,
      body: payload,
      language: this.language
    });
  }

  addComment(comment: Comment): Observable<any> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/events/${comment.event}/comments`,
      body: comment,
      language: this.language
    });
  }

  deleteComment(comment: Comment): Observable<Comment> {
    return this.hcRequests.request({
      method: 'delete',
      url: `${this.api}/comments/${comment.id}`,
      language: this.language
    });
  }

  loadEventComments(eventId: Object): Observable<Comment[]> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/events/${eventId}/comments`,
      language: this.language
    });
  }

  forgotPasswordGenerate(payload: Object[]): Observable<Object> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/password/forgot/generate`,
      body: payload,
      language: this.language
    });
  }

  generateMultiplePasswords(payload: Object[]): Observable<Object> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/password/generate-multiple`,
      body: payload,
      language: this.language
    });
  }

  forgotPasswordReset(key: string, payload: Object): Observable<Object> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/password/forgot/reset/key/${key}`,
      body: payload,
      language: this.language
    });
  }

  activateCode(code: string) {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/password/forgot/reset/key/${code}`,
      language: this.language
    });
  }

  getRoles(schoolId: Number): Observable<SchoolRole[]> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/schools/${schoolId}/roles/`,
      language: this.language
    });
  }

  getEvent(eventId: string): Observable<BackendEvent> {
    let url = `${this.api}/events/${eventId}`;

    return this.hcRequests.request({
      method: 'get',
      url: url,
      language: this.language
    });
  }

  getEvents(groups: Object[], schoolId: number, start: string, end: string): Observable<BackendEvent[]> {
    let groupIds = [];
    let url = `${this.api}/school/${schoolId}/events?start=${start}&end=${end}`;

    if (groups.length > 0) {
      groupIds = groups.map(group => group['id']);
      url = url + `&groups=${groupIds.join(':')}`;
    }
    return this.hcRequests.request({
      method: 'get',
      url: url,
      language: this.language
    });
  }


  addEvent(event: FormEvent): Observable<any> {
    // TODO: pawel: refactor to use classes
    let apiEvent = {
      title: event.title,
      description: event.description,
      date: dayjs(event.date).format("YYYY-MM-DD"),
      row_start: parseInt(event.start, 10),
      row_end: parseInt(event.start, 10) + parseInt(event.duration, 10) - 1,
      state: event.state,
      is_private: event.isPrivate,
      uploads: event.uploads,
      label: event.label,
      end_date: dayjs(event.endDate).format("YYYY-MM-DD"),
      recurrences: event.recurrences
    }

    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/groups/${event.group}/events`,
      body: apiEvent,
      language: this.language
    });
  }

  updateEvent(event: FormEvent): Observable<BackendEvent> { // actualy the parameter is of type FormEvent OR ViewEvent
    // TODO: pawel: refactor to use classes

    let apiEvent = {
      id: event.id,
      title: event.title,
      description: event.description,
      date: dayjs(event.date).format('YYYY-MM-DD'),
      row_start: parseInt(event.start),
      row_end: parseInt(event.start) + parseInt(event.duration) - 1,
      state: event.state,
      group: event.group,
      is_private: event.isPrivate,
      uploads: event.uploads,
      label: event.label,
      end_date: dayjs(event.endDate).format("YYYY-MM-DD"),
      recurrences: event.recurrences,
      recurrences_start: dayjs(event.recurrencesStart).format('YYYY-MM-DD') ? event.recurrencesStart :
        '',
    };

    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/events/${event.id}`,
      body: apiEvent,
      language: this.language
    });
  }

  deleteEvent(data: { event: ViewEvent, singleDelete: boolean }): Observable<BackendEvent> {
    const url = data.singleDelete ? `${this.api}/events/${data.event.id}` :
      `${this.api}/recurring-events/${data.event.id}`

    return this.hcRequests.request({
      method: 'delete',
      url,
      language: this.language
    });
  }

  importUsers(schoolId: number, fileData: any): Observable<any> {

    let formData = new FormData();
    formData.append('file', fileData, fileData.name);
    let options = {
      method: 'POST',
      url: `${this.api}/schools/${schoolId}/excelimport`,
      body: formData,
      language: this.language,
      mediaType: 'multipart/form-data',
      contentDisposition: `attachment; filename="${fileData.name}"`
    };
    return this.hcRequests.request(options);
  }

  getLabels(schoolId: number): Observable<Label[]> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/schools/${schoolId}/labels/`,
      language: this.language
    });
  }

  getNotifications(number: number): Observable<Notification[]> {
    // todo: Do proper with effects & actions
    if (number === 5) {
      return this.hcRequests.request({
        method: 'get',
        url: `${this.api}/notification_handling/latest/`,
        language: this.language
      }).pipe(
        map(response => response.results));
    }
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/notification_handling/nested/?limit=${number}`,
      language: this.language
    }).pipe(
      map(response => response.results));
  }

  markNotification(id: number): Observable<number> {

    let notificationUpdate = {
      id: id,
      unread: false
    };

    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/notification_handling/unread/${id}/`,
      body: notificationUpdate,
      language: this.language
    });
  }

  markAllNotifications(): Observable<number> {

    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/notification_handling/markall/`,
      language: this.language
    });
  }

  updateLabels(schoolId: number, labels: Label[]): Observable<Label[]> {
    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/schools/${schoolId}/labels/bulk/`,
      body: labels,
      language: this.language
    });
  }

  updateRoles(schoolId: number, roles: SchoolRole[]): Observable<SchoolRole[]> {
    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/schools/${schoolId}/roles/bulk/`,
      body: roles,
      language: this.language
    });
  }

  updateSettings(notificationId: number, selectedGroups: number[], showEventOnboarding,
                 showRelationshipOnboarding: boolean): Observable<SchoolRole[]> {
    return this.hcRequests.request({
      method: 'PUT',
      url: `${this.api}/users/me/settings`,
      body: {
        notification_id: notificationId,
        selected_groups: selectedGroups,
        show_event_onboarding: showEventOnboarding,
        show_relationship_onboarding: showRelationshipOnboarding
      },
      language: this.language
    });
  }

  acceptUpdate2019(): Observable<Object> {
    return this.hcRequests.request({
      method: 'GET',
      url: `${this.api}/data/update2019`,
      language: this.language
    });
  }

  getMyDocuments(requestParameters: AttachmentRequestParameters): Observable<AttachmentResponse> {
    let url = `${this.api}/my-documents/v2/`;

    let queryParameters = {
      page: requestParameters.page
    }

    if (requestParameters.query) {
      queryParameters['search'] = requestParameters.query;
    }

    if (requestParameters.orderBy) {
      queryParameters['ordering'] = requestParameters.orderBy;
    }

    const params = new HttpParams({fromObject: queryParameters});

    return this.hcRequests.request({
      method: 'get',
      url: url,
      language: this.language,
      params
    });
  }

  deleteDocuments(documents: number[]): Observable<any> {
    return this.hcRequests.request({
      method: 'POST',
      url: `${this.api}/documents/`,
      body: {ids: documents},
      language: this.language,
      httpOverwriteMethod: 'DELETE'
    });
  }

  setPushId(registrationId: string, name: string, uuid: string): Observable<number> {

    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/device/apns`,
      body: {
        name: name,
        device_id: uuid,
        registration_id: registrationId
      },
      language: this.language
    });
  }

  loadAdminMessages(): Observable<AdminMessage[]> {
    return this.hcRequests.request({
      method: 'get',
      url: `${this.api}/admin-messages/`,
      language: this.language
    });
  }

  markAdminMessageRead(id: number): Observable<any> {
    return this.hcRequests.request({
      method: 'post',
      url: `${this.api}/admin-messages/read/${id}/`,
      language: this.language
    });
  }

  createPaymentRequest(code) {
    let url = `${this.api}/payments/request/`;

    return this.hcRequests.request({
      method: 'post',
      url: url,
      language: this.language,
      body: {
        code
      }
    });
  }

  exportUsers(schoolId: number) {
    let url = `${this.api}/schools/${schoolId}/export`;

    return this.hcRequests.request({
      method: 'get',
      url: url,
      language: this.language,
      type: 'text'
    });
  }

  testUser(email: string) {
    let url = `${this.api}/email/test?user=${email}`;

    return this.hcRequests.request({
      method: 'get',
      url: url,
      language: this.language
    });
  }

  inviteFriend(email: string, schoolId: number) {
    let url = `${this.api}/invite-friend/${schoolId}`;

    return this.hcRequests.request({
      method: 'post',
      url: url,
      language: this.language,
      body: {
        email
      }
    });
  }

  updateSchool(school: School) {
    let url = `${this.api}/schools/${school.id}/`;

    return this.hcRequests.request({
      method: 'put',
      url: url,
      language: this.language,
      body: {
        name: school.name,
        first_semester_start: this.hcRequests.dateToString(school.firstSemesterStart),
        first_semester_end: this.hcRequests.dateToString(school.firstSemesterEnd),
        second_semester_start: this.hcRequests.dateToString(school.secondSemesterStart),
        second_semester_end: this.hcRequests.dateToString(school.secondSemesterEnd),
        third_semester_start: this.hcRequests.dateToString(school.thirdSemesterStart),
        third_semester_end: this.hcRequests.dateToString(school.thirdSemesterEnd)
      }
    });
  }

  blacklistToken(refresh: string, redirect: boolean) {
    let url = `${this.api}/app/token/blacklist/`;

    return forkJoin({
      request: this.hcRequests.request({
        method: 'post',
        url: url,
        language: this.language,
        body: {
          refresh
        }
      }),
      redirect: of(redirect)
    })
  };

}
