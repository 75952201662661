import {withLatestFrom} from 'rxjs/operators';
import {NgModule} from "@angular/core";
import {Location} from "@angular/common";
import {NavigationEnd, Router, RouterModule, Routes} from "@angular/router";
import {
  AutoLoginGuard,
  ForcePasswordResetGuard,
  FromPremiumGuard,
  LanguageSwitchGuard,
  LogOutUserGuard,
  RelationshipOnboardingGuard,
  UserEmailNotVerifiedGuard,
  UserEmailVerifiedGuard,
  UserHasTokenGuard,
  UserIsLoggedInGuard,
  UserUnauthorized
} from "./guards";
import {LoginPage} from "./pages/login";
import {SignupFormPage} from "./pages/signupform";
import {HomePage} from "./pages/home";
import {KlassenbuchComponent} from "./components/klassenbuch";

import {VerifyPage} from "./pages/wizard/verify";
import {PasswordResetPage} from "./pages/wizard/passwordreset";
import {ForgotPasswordWizardPage} from "./pages/wizard/forgotpassword";
import {ForgotPasswordResetWizardPage} from "./pages/wizard/forgotpasswordreset";
import {EventFormComponent} from "./components/event-form";
import {EventDetailComponent} from "./components/event-detail";
import {ProfilePage} from "./pages/profile";
import {EventResetResolve} from "./event/event.resolve";
import {NotificationsPage} from './notifications/notifications.page';
import {LabelsLoadResolve} from "./labels/labels.resolve";
import {ImprintPage} from './pages/impressum';
import {MyDocumentsComponent} from "./my-documents/my-documents.component";
import {HasPremiumGuard} from "./guards/has-premium-guard";
import {AnalyticsService} from './services';
import {LandingAppPage} from './app/landing.page';
import {Store} from "@ngrx/store";
import {AppState, getLanguage} from "./reducers";
import {LegalPage} from "./pages/legal";
import {ActivateUserPage} from "./pages/wizard/activateUser";
import {AccountDeletedPage} from "./pages/account-deleted";
import {TestUserPage} from './pages/testuser';
import {AcceptTermsPage} from './pages/accept-terms';
import {AcceptTermsAdminPage} from './pages/accept-terms-admin';
import {ActivationCodePage} from './pages/activation-code';
import {SyncGuardHelper} from './guards/sync-guard-helper';
import {SimpleHeaderLayout} from "./pages/simple-header.layout";
import {SignupPage} from "./pages/signup";
import {NewSignupFormPage} from "./pages/new_signup";
import {IS_APP} from "./app.constants";

export const ROUTES: Routes = [
  {
    path: '',
    component: LoginPage,
    canActivate: [UserUnauthorized, LanguageSwitchGuard]
  },
  {
    path: 'imprint',
    component: ImprintPage,
    canActivate: [LanguageSwitchGuard]
  },
  {
    path: 'signout',
    component: LoginPage,
    canActivate: [LogOutUserGuard]
  },
  {
    path: 'signup',
    component: SignupPage,
    canActivate: [UserUnauthorized, LanguageSwitchGuard]
  },
  {
    path: 'signup-premium',
    component: NewSignupFormPage,
    canActivate: [UserUnauthorized, LanguageSwitchGuard]
  },
  {
    path: 'signup-form',
    component: SignupFormPage,
    canActivate: [UserUnauthorized, LanguageSwitchGuard]
  },
  {
    path: 'login',
    component: LoginPage,
    canActivate: [UserUnauthorized, LanguageSwitchGuard]
  },
  {
    path: 'verify',
    component: VerifyPage,
    canActivate: [UserIsLoggedInGuard, UserEmailNotVerifiedGuard]
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordWizardPage
  },
  {
    path: 'forgotpassword/reset/:key',
    component: ForgotPasswordResetWizardPage
  },
  {
    path: 'activate',
    component: ActivationCodePage
  },
  {
    path: 'activate/:key',
    component: ActivateUserPage
  },
  {
    path: 'passwordreset',
    canActivate: [UserIsLoggedInGuard],
    component: PasswordResetPage
  },
  {
    path: 'autologin',
    canActivate: [AutoLoginGuard],
    component: HomePage
  },
  {
    path: 'goodbye',
    component: AccountDeletedPage
  },
  {
    path: 'testuser',
    component: TestUserPage
  },
  {
    path: 'accept-terms',
    canActivate: [UserIsLoggedInGuard, UserEmailVerifiedGuard],
    component: AcceptTermsPage
  },
  {
    path: 'accept-terms-admin',
    canActivate: [UserIsLoggedInGuard, UserEmailVerifiedGuard],
    component: AcceptTermsAdminPage
  },
  {
    path: 'klassenbuch',
    canActivate: [SyncGuardHelper],
    data: {
      syncGuards: [
        UserHasTokenGuard,
        UserIsLoggedInGuard,
        UserEmailVerifiedGuard,
        ForcePasswordResetGuard,
        FromPremiumGuard,
        RelationshipOnboardingGuard
      ]
    },
    component: HomePage,
    children: [
      {
        path: '',
        component: KlassenbuchComponent,
        canActivate: [UserIsLoggedInGuard],
        resolve: {
          event: EventResetResolve,
          labels: LabelsLoadResolve
        }
      },
      {
        path: 'events/add',
        component: EventFormComponent,
        // canDeactivate: [ EventChangesGuard ],
        canActivate: [UserIsLoggedInGuard],
        resolve: {
          labels: LabelsLoadResolve
        }
      },
      {
        path: 'events/:id',
        component: EventDetailComponent,
        canActivate: [UserIsLoggedInGuard]
      },
      {
        path: 'events/:id/edit',
        component: EventFormComponent,
        // canDeactivate: [ EventChangesGuard ],
        canActivate: [UserIsLoggedInGuard],
        resolve: {
          labels: LabelsLoadResolve
        }
      },
      {
        path: 'profile',
        component: ProfilePage,
        // canDeactivate: [ EventChangesGuard ],
        canActivate: [UserIsLoggedInGuard]
      },
      {
        path: 'notifications',
        component: NotificationsPage,
        canActivate: [UserIsLoggedInGuard],
        resolve: {
          event: EventResetResolve
        }
      },
      {
        path: 'documents',
        component: MyDocumentsComponent,
        canActivate: [UserIsLoggedInGuard, HasPremiumGuard]
      },
      {
        path: 'legal',
        component: LegalPage,
        canActivate: [UserIsLoggedInGuard]
      }
    ]
  },
  {
    path: 'admin',
    canActivate: [UserIsLoggedInGuard, UserEmailVerifiedGuard, ForcePasswordResetGuard, FromPremiumGuard],
    component: HomePage,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'absences',
    canActivate: [UserIsLoggedInGuard, UserEmailVerifiedGuard, ForcePasswordResetGuard, FromPremiumGuard],
    component: HomePage,
    loadChildren: () => import('./absences/absences.module').then(m => m.AbsencesModule),
  },
  {
    path: 'relationships',
    canActivate: [UserIsLoggedInGuard, UserEmailVerifiedGuard, ForcePasswordResetGuard, FromPremiumGuard],
    component: SimpleHeaderLayout,
    loadChildren: () => import('./relationship/relationship.module').then(m => m.RelationshipModule),
  },
  {
    path: 'sso',
    component: SimpleHeaderLayout,
    loadChildren: () => import('./edulog/edulog.module').then(m => m.EdulogModule),
  },
  {
    path: 'index_app.html',
    component: LandingAppPage,
    canActivate: [UserUnauthorized]
  },
  {
    path: '**',
    component: LoginPage,
    canActivate: [UserUnauthorized, LanguageSwitchGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      useHash: IS_APP,
      anchorScrolling: 'enabled',
      enableTracing: false,
    })
  ],
  exports: [
    RouterModule
  ]
})
export class HelloclassRoutingModule {

  private currentRoute: string;

  constructor(router: Router,
              location: Location,
              analyticsService: AnalyticsService,
              store: Store<AppState>) {

    router.events.pipe(
      withLatestFrom(store.select(getLanguage)))
      .subscribe(data => {

        const event = data[0];
        const language = data[1];

        // Send GA tracking on NavigationEnd event. You may wish to add other
        // logic here too or change which event to work with
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
          // When the route is '/', location.path actually returns ''.
          let newRoute = location.path() || '/';

          // If the route has changed, send the new route to analytics.
          if (this.currentRoute !== newRoute) {
            analyticsService.send(language, 'pageview', newRoute);
            this.currentRoute = newRoute;
          }
        }
      });
  }
}
