import {combineLatest, Observable, of as observableOf} from 'rxjs';

import {filter, map, take, takeWhile} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ViewEvent} from "../event/event";
import {Comment} from "../models/comment";
import {
    DeleteCommentAction,
    DeleteEventCancelAction,
    DeleteEventConfirmationAction,
    DeleteEventUserAction,
    GetEventAction,
    PublishEventAction
} from "../actions/klassenbuch";
import {UiSetBackUrl, UiSetContextMenu, UiSetTitleAction} from "../actions/ui";
import {ConfirmationModalComponent} from '../confirmation-modal/confirmation-modal';
import * as reducers from "../reducers";
import {OfflineService} from "../app/offline.service";
import {TranslateService} from "@ngx-translate/core";
import {IS_APP} from '../app.constants';
import {ActionsheetService} from "../services/actionsheet.service";

const PARENT_URL = "/klassenbuch";

declare global {
  interface Window {
    plugins: any
  }
}

@Component({
  selector: 'hc-event-detail',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
      <div class="container main__container">
          <div *ngIf="event.id != -1" class="row">
              <hc-event class="event--detail" [event]="event"
                        [isHidden]="false"
                        [isDetail]="true"
                        [dayIndex]="0"
                        [displayIndex]="0"
                        [canEditEvents]="canEditEvents$ | async"
                        [isOnline]="isOnline$ | async"
                        (publish)="publish($event)"
                        (edit)="edit($event)"
                        (delete)="delete($event)">
              </hc-event>
          </div>
          <hc-spinner class="center" [show]="event.id === -1"></hc-spinner>

          <!-- Display comments -->
          <div class="comments" *ngIf="event.id != -1">
              <h2>{{(comments$ | async).length}} {{'Kommentare' | translate}}</h2>
              <div class="row" *ngIf="canEditOwnEvents$ | async">
                  <div class="col-xs-12">
                      <hc-comment-form [isOnline]="isOnline$ | async"
                                       [uploadEnabled]="hasPremium$ | async"
                                       [event]="event">
                      </hc-comment-form>
                  </div>
              </div>
              <!-- div class="commentupload-teaser row vertical-margin" *ngIf="displayUploadTeaser$ | async">
                  <div class="col-xs-12">
                      <p>{{'Möchtest du, dass bei Kommentaren eine Datei hinzugefügt werden kann? Diese Funktion ist ein Bestandteil von mit «Helloclass Premium».' | translate}}</p>
                      <p><a routerLink="/premium">{{'Upgrade durchführen' | translate}}</a></p>
                  </div>
              </div -->
              <div class="comment-wrapper row">
                  <div class="col-xs-12">
                      <div *ngFor="let comment of comments$ | async" class="vertical-margin comment">
                          <div class="comment__meta">
                              <p class="text-gray comment-meta__info inline-block">{{comment.authorFullName}}
                                  &#8211; {{comment.created |dateFormatFilter:'dddd, DD. MMMM YYYY &#8211; HH:mm'}}</p>
                              <button class="unstyled-button icon-bin pull-right text-gray comment-meta__action icon-size inline-block"
                                      *ngIf="canDeleteComment(comment.author) | async"
                                      (click)="deleteComment(comment)"></button>
                          </div>
                          <div class="comment__content">
                              <p class="no-margin" [innerHtml]="addNewLines(comment.content) | linky"></p>
                          </div>
                          <div *ngIf="comment.uploads.length > 0" class="comment__upload comment-upload">
                              <p class="comment-upload__filename">
                                  <a class="preview" href="{{ comment.uploads[0].public_url }}" download>
                                      <span class="icon-document"></span> {{comment.uploads[0].file_name}}
                                  </a>
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <hc-confirmation-modal
              *ngIf="!event.isRecurring()"
              [title]="'Aufgabe löschen' | translate"
              [displayText]="'Willst du diese Aufabe wirklich löschen?' | translate"
              [confirmationText]="'Löschen' | translate"
              (confirm)="confirmDelete(true)"
              (cancel)="cancelDelete()">
      </hc-confirmation-modal>
      <hc-confirmation-modal
              *ngIf="event.isRecurring()"
              [title]="'Wiederholdende Aufgabe löschen' | translate"
              [displayText]="'Willst du diese Aufabe wirklich löschen?' | translate"
              [confirmationText]="'Nur diese Aufgabe' | translate"
              (confirm)="confirmDelete(true)"
              (cancel)="cancelDelete()">
          <button
                  class="btn btn--gray btn--innershadow btn--full-width"
                  aria-label="Confirm"
                  (click)="confirmDelete(false)"
                  data-cy="modal-all-recurrences"
                  translate>Alle Aufgaben
          </button>
      </hc-confirmation-modal>
  `
})
export class EventDetailComponent {

  @ViewChild(ConfirmationModalComponent, {static: false}) public modal: ConfirmationModalComponent;

  public event: ViewEvent = ViewEvent.initialObject();
  private event$: Observable<ViewEvent>;
  public form: FormGroup;
  public comments$: Observable<Comment[]>;
  private commentsLoading$: Observable<boolean>;
  private canEditEvents$: Observable<boolean>;
  public loadingDetail$: Observable<boolean>;
  // public displayUploadTeaser$: Observable<boolean>;
  public isOnline$: Observable<boolean>;
  public canEditOwnEvents$: Observable<boolean>;
  public hasPremium$: Observable<boolean>;
  private alive = true;

  private backUrl = '';
  private selectedDate$: Observable<Object>;

  constructor(formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private store: Store<reducers.AppState>,
              private cd: ChangeDetectorRef,
              private router: Router,
              private offlineService: OfflineService,
              private translateService: TranslateService,
              private actionSheetService: ActionsheetService) {
    this.store.dispatch(new UiSetBackUrl(this.backUrl));
    this.selectedDate$ = store.select(reducers.getSelectedDate);
    this.selectedDate$.pipe(
      take(1))
      .subscribe((d: string) => {
        if (d) {
          this.backUrl = `${PARENT_URL}?date=${d}`;
          this.store.dispatch(new UiSetBackUrl(this.backUrl));
        } else {
          this.backUrl = `${PARENT_URL}`;
        }
      });
    this.store.dispatch(new UiSetContextMenu(''));

    // todo: this component has quite some state information. Should it be that intelligent?
    this.comments$ = store.select(reducers.getComments);
    this.commentsLoading$ = store.select(reducers.getGroupsLoading);
    this.event$ = store.select(reducers.getDetailEvent);
    this.loadingDetail$ = store.select(reducers.getDetailLoading);
    this.canEditEvents$ = store.select(reducers.canEditEvents);
    this.isOnline$ = store.select(reducers.getAppOnline);
    this.canEditOwnEvents$ = store.select(reducers.canEditOwnComments);
    this.hasPremium$ = this.store.select(reducers.hasPremium);

    store.select(reducers.getDetailEvent).pipe(
      takeWhile(() => this.alive))
      .subscribe((event) => {
        this.cd.markForCheck();
        this.event = event;
        this.store.dispatch(new UiSetTitleAction(this.getTitle()));
      });

    this.form = formBuilder.group({
      content: ['', Validators.required],
    });

    // this.displayUploadTeaser$ = combineLatest([
    //     this.hasPremium$,
    //     this.store.select(reducers.isAdmin)
    // ]).pipe(
    //     map(data => !data[0] && data[1])
    // );
  }

  private getTitle() {
    return this.event.title ? this.event.title : this.event.description;
  }

  ngOnInit() {
    //move to guard?
    this.activatedRoute.params.pipe(
      takeWhile(() => this.alive))
      .subscribe(params => this.store.dispatch(new GetEventAction(params['id'])));

    // show text in comment files
    combineLatest([
      this.isOnline$,
      this.translateService.get('Diese Funktion ist nur online verfügbar')
    ]).pipe(
      takeWhile(() => this.alive)
    ).subscribe(data => {
      const commentText = data[0] ? '' : data[1];
      this.form.patchValue({'content': commentText});
    });
  }

  ngAfterViewInit() {
    // handle modal updates
    this.store.select(reducers.getShowDeleteEventModal).pipe(
      takeWhile(() => this.alive))
      .subscribe(showModal => {
        if (showModal) {
          if (IS_APP) {
            this.showActionSheet();
          } else {
            this.modal.show();
          }
        } else {
          this.modal.hide();
        }
      });
  }

  private showActionSheet() {
    if (this.event.recurrences) {
      this.actionSheetService.createActionsheetHandler('Sich wiederholedne Aufgabe löschen', 'Abbrechen',
        'deleteAllEventsSheetButton', ['deleteOnlyThisEventSheetButton'],
        () => this.cancelDelete(),
        () => this.store.dispatch(new DeleteEventConfirmationAction({event: this.event, singleDelete: false})),
        [() => this.store.dispatch(new DeleteEventConfirmationAction({event: this.event, singleDelete: true}))])
    } else {
      this.actionSheetService.createActionsheetHandler('Aufgabe löschen', 'Abbrechen',
        'Aufgabe löschen', [], () => this.cancelDelete(),
        () => this.store.dispatch(new DeleteEventConfirmationAction({event: this.event, singleDelete: true})),
        [])
    }
  }

  public publish(event: Object) {
    this.offlineService.executeActionOnlyWhenOnlineRequired(true,
      () => this.store.dispatch(new PublishEventAction(event)));
  }

  public delete(event: ViewEvent) {
    this.offlineService.executeActionOnlyWhenOnlineRequired(true,
      () => this.store.dispatch(new DeleteEventUserAction(event)));
  }

  public edit(event: ViewEvent) {
    this.offlineService.executeActionOnlyWhenOnlineRequired(true,
      () => this.router.navigate(['/klassenbuch/events', event.id, 'edit']))
  }

  private canDeleteComment(author): Observable<boolean> {
    let loggedIn$ = this.store.select(reducers.getAuthUser).pipe(
      filter(u => u != null),
      map(u => u.id));

    return combineLatest([
      observableOf(author),
      loggedIn$,
      this.store.select(reducers.canEditOwnComments),
      this.store.select(reducers.canDeleteComments)
    ]).pipe(
      map(x => (x[0] === x[1] && x[2]) || x[3])
    );
  }

  public deleteComment(comment): void {
    this.offlineService.executeActionOnlyWhenOnlineRequired(true, () => this.store.dispatch(new DeleteCommentAction(comment)));
  }

  public confirmDelete(singleDelete: boolean) {
    this.store.dispatch(new DeleteEventConfirmationAction({event: this.event, singleDelete}));
  }

  public cancelDelete() {
    this.store.dispatch(new DeleteEventCancelAction())
  }

  public addNewLines(text: string) {
    return text.replace(/\n/g, '<br />');
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
