import {
    Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, ApplicationRef,
    Output, EventEmitter
} from "@angular/core";
import {FileAttachment, IFileAttachment} from "./attachment.model";
import {TokenService} from "../token/token";

@Component({
    selector: 'hc-event-attachment',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="event-attachment">
            <hc-attachment-list
                [uploads]="uploads"
                [backgroundColor]="labelColor"
                [editable]="editable"
                [textOnly]="textOnly"
                (removeUpload)="deleteUpload($event)"
                ></hc-attachment-list>
            <div class="col-md-12" *ngIf="editable && (!singleUpload || uploads.length === 0)">
                <hc-fileupload [authToken]="tokenService.getTokens().access"
                               (uploadSuccess)="onUploadSuccess($event)"
                               (uploadError)="onUploadError($event)"
                ></hc-fileupload>
            </div>
        </div>
    `
})
export class AttachmentComponent implements OnInit {

    @Input() editable = false;
    @Input() uploads: Object[];
    @Input() labelColor: string;
    @Input() singleUpload = false;
    @Input() textOnly = false;
    @Output() addUpload = new EventEmitter();
    @Output() removeUpload = new EventEmitter();
    @Output() errorOnUpload = new EventEmitter();

    constructor(protected tokenService: TokenService) {
    }

    ngOnInit() {
    }

    ngOnChanges(obj: any) {
    }

    deleteUpload(attachmentId: number): void {
        this.removeUpload.emit(attachmentId);
    }

    onUploadSuccess(file: IFileAttachment) {
        this.addUpload.emit(FileAttachment.fromPayload(file));
    }

    onUploadError(error: string) {
        this.errorOnUpload.emit();
    }
}
