import {Injectable} from '@angular/core';
import {combineLatest} from "rxjs";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import * as dayjs from "dayjs";

// https://github.com/jakubroztocil/rrule/blob/master/src/types.ts
enum Frequency {
    MONTHLY = 1,
    WEEKLY = 2,
    DAILY = 3,
}

const MONTHLY = 'MONTHLY'
const WEEKLY = 'WEEKLY'
const DAILY = 'DAILY'


@Injectable({
    providedIn: 'root',
})
export class HelloclassRRuleService {

    constructor(private translateService: TranslateService) {
    }

    public createRRule(frequency: number, date: Date): string | null {
        const verifiedFrequency = this.getFrequency(frequency)
        const day = this.getWeekday(date)

        if (verifiedFrequency === 'DAILY') {
            return `RRULE:FREQ=${verifiedFrequency}`
        }

        if (day === '' || verifiedFrequency === '') {
            return null
        }
        return `RRULE:FREQ=${verifiedFrequency};BYDAY=${day}`
    }

    public frequencyFromRRule(rrule: string): number {
        const regex = /.*RRULE:FREQ=(DAILY|WEEKLY|MONTHLY).*/

        if (!rrule) {
            return -1
        }

        const matches = rrule.match(regex)
        const frequency = matches[1]
        if ([MONTHLY, WEEKLY, DAILY].indexOf(frequency) < 0) {
            return -1
        }

        switch (frequency) {
            case MONTHLY:
                return Frequency.MONTHLY
            case WEEKLY:
                return Frequency.WEEKLY
            case DAILY:
                return Frequency.DAILY
            default:
                return -1
        }
    }

    public textForFrequencyId(recurrences, recurrenceId): string {
        for (const recurrence of recurrences) {
            if (recurrence['id'] === recurrenceId) {
                return recurrence['text']
            }
        }
        return ''
    }

    private getWeekday(date: Date) {
        switch (date.getDay()) {
            case 1:
                return 'MO'
            case 2:
                return 'TU'
            case 3:
                return 'WE'
            case 4:
                return 'TH'
            case 5:
                return 'FR'
            default:
                return ''
        }
    }

    private getFrequency(frequency: number): string {
        switch (frequency) {
            case Frequency.MONTHLY:
                return MONTHLY
            case Frequency.WEEKLY:
                return WEEKLY
            case Frequency.DAILY:
                return DAILY
            default:
                return ''
        }
    }

    public getFrequencyAsText(rrule: string, date: string) {
        const frequency = this.frequencyFromRRule(rrule)


        return combineLatest([
            this.translateService.get('eventDailyDetail'),
            this.translateService.get('eventWeeklyDetail',
                {dayOfWeek: dayjs(date).format('dddd')}),
            this.translateService.get('eventMonthlyDetail',
                {dayOfMonth: dayjs(date).format('D')}),
        ]).pipe(
            map((translations) => {
                switch (frequency) {
                    case Frequency.DAILY:
                        return translations[0]
                    case Frequency.WEEKLY:
                        return translations[1]
                    case Frequency.MONTHLY:
                        return translations[2]
                    default:
                        return ''
                }
            })
        )
    }
}
