import { NgModule } from '@angular/core';

import { ConfirmationModalComponent } from './confirmation-modal';
import { ModalModule } from "ngx-bootstrap/modal";
import { TranslateModule } from '@ngx-translate/core';
import { HelloclassModalModule } from '../modal/modal.module';

@NgModule(
  {
    imports: [
        TranslateModule,
        ModalModule,
        HelloclassModalModule
    ],
    declarations: [ ConfirmationModalComponent],
    exports: [ ConfirmationModalComponent]
  })
export class ConfirmationModaleModule {}
