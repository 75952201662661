import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import { Store } from "@ngrx/store";
import { ViewEvent } from "../event/event";
import { Comment } from "../models/comment";
import { AddCommentAction } from "../actions/klassenbuch";
import * as reducers from "../reducers";
import { FileAttachment } from "../attachment/attachment.model";
import { AddToastAction } from '../alerts/alerts.actions';


@Component({
    selector: 'hc-comment-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <form [formGroup]="form" (submit)="formSubmit()" class="comments-form">
            <div class="input-field input-field--multiline" data-init="auto">
                <textarea   formControlName="content"
                            id="content"
                            data-min-rows="5"
                            data-max-rows="20"
                            placeholder="{{ 'Kommentar schreiben...' | translate}}"
                            [attr.disabled]="!isOnline? '' : null"></textarea>
                <label class="comments-form__label isHidden" for="content">{{'Kommentare' | translate}}</label>
                <hc-event-attachment
                    [editable]="true"
                    [uploads]="uploads"
                    [labelColor]="event.labelColor"
                    [textOnly]="true"
                    [singleUpload]="true"
                    (addUpload)="addUpload($event)"
                    (removeUpload)="removeUpload($event)"
                    (errorOnUpload)="errorOnUpload()"
                    class="attachment-bottom-padding row"
                    *ngIf="uploadEnabled"
                    >
            </hc-event-attachment>
                <button class="btn btn--innershadow comments-form__button"
                        [ngClass]="{'btn--gray': !form.valid || !isOnline, 'btn--blue': form.valid && isOnline}"
                        [disabled]="!form.valid || !isOnline"
                        type="submit">{{'Kommentar abschicken' | translate}}</button>
            </div>
        </form>
`
})
export class CommentFormComponent {

    @Input() event: ViewEvent;
    @Input() isOnline: boolean;
    @Input() uploadEnabled: boolean;
    public form: FormGroup;
    public uploads: FileAttachment[] = []

    constructor(formBuilder: FormBuilder, private store: Store<reducers.AppState>) {
        this.form = formBuilder.group({
            content: ['', Validators.required],
        });
    }

    public formSubmit(): void {
        let content = this.form.controls[ 'content' ].value;
        if (content && content.length > 0) {
            let comment: Comment = {
                content: content,
                event: this.event.id,
                uploads: this.uploads
            };
            this.store.dispatch(new AddCommentAction(comment));
            this.form.patchValue({ 'content': '' });
        }
    }

    public addUpload(upload: FileAttachment) {
        this.uploads = [...this.uploads, upload];
    }

    public removeUpload(uploadId: number) {
        this.uploads = this.uploads.filter(upload => upload['id'] !== uploadId);
    }

    public errorOnUpload() {
        // const action = new AddToastAction({message: 'Datei konnte nicht hochgeladen werden.', type: 'fail'});
        // this.store.dispatch(action);
    }

}
