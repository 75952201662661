import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromAuth from "../reducers";


@Injectable({
  providedIn: 'root',
})
export class Cookies {

  constructor(
    private store: Store<fromAuth.AppState>
  ) {
  }

  async isLoggedIn() {
    return this.getCookieValue("loginStatus") === "true";
  };

  getCookieValue(cookieName: string): string {
    // https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript
    const cookieValue = document.cookie.match(
      "(^|[^;]+)\\s*" + cookieName + "\\s*=\\s*([^;]+)"
    );
    if (!cookieValue) {
      return "";
    }
    return cookieValue.pop() || "";
  };

  // https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
  deleteAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

}
