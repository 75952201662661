import { Component, ChangeDetectorRef } from '@angular/core';
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { AlertMessage } from './alerts.models';

import { getNotificationAlert, AppState } from "../reducers";

@Component({
    selector: 'hc-alert',
    template: `
        <div class="row vertical-margin" *ngIf="message && message.message">
            <div class="hc-alert qa-alert col-md-12">
                <p class="hc-alert__message text-size-normal qa-alert-text" [ngClass]="'hc-alert__message--' + message.type">
                    {{ message?.message | translate }}
                </p>
            </div>
        </div>
    `,
})
export class AlertComponent {
    public message: AlertMessage;
    private messageSubscription: Subscription;

    constructor(private store: Store<AppState>,
                private cd: ChangeDetectorRef) {
        this.messageSubscription = store.select(getNotificationAlert)
            .subscribe((message) => {
              // parent might not pick up change
              this.cd.markForCheck();
              this.message = message;
            });
    }

    ngOnDestroy() {
        this.messageSubscription.unsubscribe();
    }
}
