import {filter, map, take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import * as fromAuth from "../reducers";
import {getIsTokenLoaded} from "../reducers";
import {TokenService} from "../token/token";
import {AuthTokenLoadLocal, LogoutAction, SetLoginRedirectUrl} from "../actions/authentication";
import {IS_APP} from "../app.constants";

const PREMIUM_URL = '/premium'

@Injectable()
export class UserHasTokenGuard {
  constructor(
    private store: Store<fromAuth.AppState>,
    private tokenService: TokenService,
    private router: Router
  ) {
  }

  verifyToken(url: string) {
    this.store.dispatch(new AuthTokenLoadLocal());
    return this.store.select(getIsTokenLoaded).pipe(
      filter(isLoaded => isLoaded),
      take(1),
      map(() => {
        if (!this.tokenService.accessTokenIsValid()) {
          this.store.dispatch(new SetLoginRedirectUrl(url));
          if (url === PREMIUM_URL) {
            this.store.dispatch(new LogoutAction(false))
            return this.router.parseUrl('/login?from=premium')
          } else {
            this.store.dispatch(new LogoutAction(true))
          }
          return false
        } else {
          return true
        }
      }),)
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("UserHasToken guard activated.")
    if (IS_APP) {
      return this.verifyToken(state.url)
    }
    return true
  }
}
