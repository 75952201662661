import {filter, skip, take} from 'rxjs/operators';
import {Injectable, NgZone} from '@angular/core';
import {Store} from "@ngrx/store";
import {Router} from '@angular/router';
import {HelloClassApiService} from '../services';
import {AppState, getNotifications} from "../reducers";
import {
  ClearInterval,
  GetNotifications,
  SetToLatestNotificationId,
  StartInterval
} from "../notifications/notifications.actions";
import {IS_APP} from '../app.constants';
import {AppSetPushId} from './app.actions';


@Injectable()
export class PushService {

  private push;
  private registrationId = "";

  constructor(private helloclassApi: HelloClassApiService,
              private router: Router,
              private store: Store<AppState>,
              private ngZone: NgZone) {
  }

  initPush() {
    if (IS_APP) {
      document.addEventListener('deviceready', this.onReady.bind(this), false)
      window.open = cordova.InAppBrowser.open;
    }
  }

  private onReady() {

    this.push = PushNotification.init({
      ios: {
        alert: 'true',
        badge: 'true',
        sound: 'false',
        clearBadge: 'true'
      },
      windows: {}
    })
    this.push.on('registration', (registrationData) => {
      PushNotification.hasPermission(data => {
        if (data.isEnabled) {
          // why the timeout:
          // in notifications.effect.ts the polling starts right after the user logs in.
          // To not clutter the effect stopInterval i.e. stop polling is called here with
          // a timeout as this method might be called before the effect (and this starts the polling again).
          // This is just a lazy way to make a safe bet, that the interval is stopped after it has been started.
          // It's not really critical, but a good thing to do as the push notification mechanism can be used for the
          // notification update. Over and out.
          this.ngZone.run(() => {
            this.store.dispatch(new GetNotifications(5))
          });
          setTimeout(() => this.store.dispatch(new ClearInterval()), 5000)
          const name = `${device.model}`
          this.registrationId = registrationData.registrationId

          this.ngZone.run(() => {
            this.store.dispatch(new AppSetPushId({
              registrationId: registrationData.registrationId,
              name: name,
              uuid: device.uuid
            }))
          })
        } else {
          this.store.dispatch(new StartInterval())
        }
      })
    });

    this.push.on('error', () => this.store.dispatch(new StartInterval()))

    this.push.on('notification', (data) => {

      this.ngZone.run(() => {
        this.store.dispatch(new GetNotifications(5))
      });

      // route only to event if the app wasn't running
      if (this.appIsInForeground(data)) {
        return
      }

      if (this.isEvent(data)) {
        this.router.navigate(['/klassenbuch/events', data.additionalData.event])
      }

      if (this.isInfoMessage(data)) {
        window.open(data.additionalData.infopoint, '_system').focus()
      }

      if (this.isAbsenceMessage(data)) {
        this.router.navigate(['/absences/absence', data.additionalData.absence])
      }

      if (this.isRelationship(data)) {
        this.router.navigate(['/klassenbuch/notifications'])
      }
      this.setLatestPushNotification()
    });

  }

  private setLatestPushNotification() {
    this.store.select(getNotifications).pipe(
      filter(notifications => notifications && notifications.length > 0),
      skip(1),
      take(1),)
      .subscribe(() => this.store.dispatch(new SetToLatestNotificationId()))
  }

  private appIsInForeground(data) {
    return data.additionalData && data.additionalData.foreground
  }

  private isEvent(data) {
    return data.additionalData.event
  }

  private isInfoMessage(data) {
    return data.additionalData.infopoint
  }

  private isAbsenceMessage(data) {
    return data.additionalData.absence
  }

  private isRelationship(data) {
    return data.additionalData.relationship
  }

  public unregister() {
    if (IS_APP && this.push) {
      this.push.unregister(() => console.log('unregister success'), () => console.log('unregister fail'))
    }
  }
}
