
import {map, switchMap, catchError, tap} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { EffectsWithRouter } from '../effects/router-effect';
import { AppState } from '../reducers';
import { SchoolActionTypes } from '../actions/school';
import { HelloClassApiService } from '../services/helloclass';
import {UpdateSchool, UpdateSchoolFail, UpdateSchoolSuccess} from '../actions/school';

import {of} from "rxjs";
import {AddAlertAction, AddToastAction} from "../alerts/alerts.actions";
import {RoutingHistoryService} from "../routing-history/routing-history.service";

@Injectable()
export class SchoolEffects extends EffectsWithRouter {

    constructor(private store: Store<AppState>,
                private actions$: Actions,
                private helloclassApi: HelloClassApiService,
                private routingHistoryService: RoutingHistoryService,
                injector: Injector) {
        super(injector);
    }

     updateSchool$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateSchool>(SchoolActionTypes.UPDATE_SCHOOL),
        map(action => action.payload),
        switchMap(school => this.helloclassApi.updateSchool(school).pipe(
            map(data => new UpdateSchoolSuccess(data)),
            catchError((error) => {
                return of(new UpdateSchoolFail(error));
            }), )
        )
    ))

     updateSchoolFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateSchoolFail>(SchoolActionTypes.UPDATE_SCHOOL_FAIL),
        map(() => {
            return new AddAlertAction({message: 'Es ist ein Fehler aufgetreten!', type: 'fail' });
        }), ));

     updateSchoolSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateSchoolSuccess>(SchoolActionTypes.UPDATE_SCHOOL_SUCCESS),
        map(action => action.payload),
        tap(() => this.store.dispatch(new AddToastAction({ message: 'Die Änderungen wurden gespeichert', type: 'success' }))),
        map(() => {
            const backUrl = this.routingHistoryService.getPreviousRoute();
            this.getRouter().navigate([ backUrl]);
            return {type: 'NOP', payload: null};
    }),));

}
