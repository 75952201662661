import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayHideDirective } from './overlay-hide';

@NgModule(
  {
    imports: [CommonModule],
    declarations: [ OverlayHideDirective],
    exports: [ OverlayHideDirective]
  })
export class HelloclassOverlayModule {}
