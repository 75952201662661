// while upgrading to ng16 this library caused problems. As it hasn'b been updated in a while, it's now in the codebase

import {NgModule} from '@angular/core';

import {LinkyPipe} from './linky.pipe';

@NgModule({
  declarations: [LinkyPipe],
  exports: [LinkyPipe],
})
export class LinkyModule {
}
