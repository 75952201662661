import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import {InfopointMessage} from "./infopoint.models";

@Component({
    selector: 'hc-infopoint',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="infopoint">
            <h4 class="infopoint__title">{{infopointMessage.title}}:</h4>
            <p class="infopoint__text">{{infopointMessage.text}}</p>
            <p class="infopoint__link"><a [attr.href]="infopointMessage.link">{{infopointMessage.link_text}}</a></p>
        </div>
    `,
})
export class InfopointComponent {
    @Input() infopointMessage: InfopointMessage;
}
