import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {AppState, getAuthUser} from "../reducers";
import {Store} from "@ngrx/store";
import {DisableShowRelationshipOnboardingSettings} from "../actions/authentication";
import {Router} from "@angular/router";
import {RelationshipOnboardingManageComponent} from "./relationship.onboarding-selection.component";


@Component({
    selector: 'hc-relationship-onboarding',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="container-fluid container-fluid--limit relationship-onboarding">
            <div class="relationship-onboarding__main-block">
                <div class="relationship-onboarding__text-block">
                    <img class="relationship-onboarding__title-image" src="assets/icons/relationship.svg" alt="relationships" />
                    <h2 class="relationship-onboarding__title"
                        data-cy="onboarding-relationship-title" translate>Verknüpfe dich jetzt</h2>
                    <p class="relationship-onboarding__description-intro no-margin">
                        <span translate>Du wurdest als</span>&nbsp;<span class="text-emph" translate>{{(loggedInUser$ | async).role}}</span>&nbsp;<span translate>erfasst</span>.
                    </p>
                    <p class="relationship-onboarding__description">
                        <span translate>Wenn du dich mit einer Person (z.B. deinem Kind) verknüpfst, kannst du für diese Person Absenzen erfassen.</span>
                    </p>
                </div>
                <hc-relationship-onboarding-selection
                    #relationshipManageComponent
                    *ngIf="loggedInUser$ | async"
                    [userId]="(loggedInUser$ | async).id"
                    [schoolId]="(loggedInUser$ | async).schoolId"
                ></hc-relationship-onboarding-selection>
                <button
                    (click)="addRelationship()"
                    data-cy="onboarding-add-relationship"
                    class="btn btn--blue btn--innershadow btn--max-width" translate>Neue Verknüpfung herstellen</button>
            </div>
            <footer class="relationship-onboarding__footer">
                <button (click)="done()"
                        class="unstyled-button text-blue" translate>Verknüpfung überspringen</button>
            </footer>
         </div>
    `,
})
export class RelationshipOnboardingComponent {

    @ViewChild('relationshipManageComponent', {static: false}) public relationshipManageComponent: RelationshipOnboardingManageComponent;

    readonly loggedInUser$ = this.store.select(getAuthUser);

    constructor(private store: Store<AppState>,
                private router: Router) {
    }

    public addRelationship() {
        const selectedUserName = this.relationshipManageComponent.selectedUserName()
        if (selectedUserName !== '') {
            this.relationshipManageComponent.saveRelationships();
            this.store.dispatch(new DisableShowRelationshipOnboardingSettings());
            this.router.navigate(['/relationships/onboarding-success/'], {
                queryParams: { name: selectedUserName }
            });
        }
    }

    public done() {
        this.store.dispatch(new DisableShowRelationshipOnboardingSettings());
        this.router.navigate(['/klassenbuch/']);
    }
}
