import { NgModule } from '@angular/core';

import { ModalComponent } from './modal';
import { ModalModule } from "ngx-bootstrap/modal";
import { TranslateModule } from '@ngx-translate/core';
import {CommonModule} from "@angular/common";

@NgModule(
  {
    imports: [
        TranslateModule,
        ModalModule,
        CommonModule
    ],
    declarations: [ ModalComponent ],
    exports: [ ModalComponent ]
  })
export class HelloclassModalModule {}
