import {interval, Observable, of} from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";
import {catchError, debounceTime, filter, map, switchMap, throttle, withLatestFrom} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';

import {
  AppState,
  getAppOnline,
  getLatestNotificationId,
  getLoginRedirectUrl,
  getSelectedDisplayGroupsIds,
  getUser,
  showEventOnboarding,
  showRelationshipOnboarding
} from '../reducers';
import {HelloClassApiService} from '../services';
import {AddAlertAction, AddToastAction} from '../alerts/alerts.actions';
import {User} from '../models/user';
import {EffectsWithRouter} from '../effects/router-effect';
import * as auth from '../actions/authentication';
import {
  ActivationLinkGenerateFailureAction,
  ActivationLinkGenerateSuccessAction,
  AuthRefreshTokenAction,
  AuthRefreshTokenFailAction,
  AuthRefreshTokenSuccessAction,
  AuthTokenReceivedAction,
  AuthTokenReset,
  ForgotPasswordGenerateFailureAction,
  ForgotPasswordGenerateSuccessAction,
  ForgotPasswordResetFailureAction,
  ForgotPasswordResetSuccessAction,
  GenerateMultipleActivationLinksFailAction,
  GenerateMultipleActivationLinksSuccessAction,
  GenerateVerifyEmailFailureAction,
  GenerateVerifyEmailSuccessAction,
  LoadUserDataAction,
  LoginAddFormErrorAction,
  LoginFailureAction,
  LoginSuccessAction,
  LogoutAction,
  LogoutSuccessAction,
  PasswordResetFailureAction,
  PasswordResetSuccessAction,
  TokenLoaded,
  UpdateSettings,
  UserDataFailAction,
  UserDataSuccessAction,
  VerifyEmailFailureAction,
  VerifyEmailSuccessAction
} from '../actions/authentication';
import {LoadUserAction, UsersLoadAction} from '../actions/users';
import {ClearInterval, StartInterval} from '../notifications/notifications.actions';
import {TokenService} from '../token/token';
import {Cookies} from '../token/cookies';
import {TranslateService} from '@ngx-translate/core';
import {IS_APP, SYSTEM_ERROR_KEY} from '../app.constants';
import {OfflineStorageService} from '../app/offlinestorage.service';
import {PushService} from '../app/push.service';
import {UiSetLanguage} from '../actions/ui';
import {HttpErrorResponse} from '@angular/common/http';
import {UserSettings} from '../models/auth';

@Injectable()
export class AuthEffects extends EffectsWithRouter {

  constructor(
    private actions$: Actions,
    private helloclassApi: HelloClassApiService,
    private tokenService: TokenService,
    private cookieService: Cookies,
    injector: Injector,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private offlineStorageService: OfflineStorageService,
    private pushService: PushService) {
    super(injector);
  }

  login$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<auth.LoginAction>(auth.AuthActionTypes.LOGIN),
    map(action => action.payload),
    switchMap(credentials => {
        if (IS_APP) {
          return this.helloclassApi.appLogin(credentials).pipe(
            map(data => new LoginSuccessAction(data)),
            catchError((error) => of(new LoginFailureAction(error))),)

        } else {
          return this.helloclassApi.login(credentials).pipe(
            map(data => new LoginSuccessAction(data)),
            catchError((error) => of(new LoginFailureAction(error))),)

        }
      }
    )
  ));

  loginFail$ = createEffect(() => this.actions$.pipe(
    ofType<any>(auth.AuthActionTypes.LOGIN_FAILURE),
    map(action => action.payload),
    map((response: HttpErrorResponse) => {
      let text = ''
      switch (response.status) {
        case 401:
          text = 'Das Passwort oder der Benutzername ist falsch.';
          break;

        default:
          text = 'Es ist ein Fehler aufgetreten!';
          break;
      }
      return new LoginAddFormErrorAction({general: text});
    }),));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
      ofType<any>(auth.AuthActionTypes.LOGIN_SUCCESS),
      map(action => action.payload),
      withLatestFrom(this.store.select(getLoginRedirectUrl)),
      map((v) => {
        let tokens = v[0]
        let redirectUrl = v[1] === '' ? '/klassenbuch' : v[1]

        if (IS_APP) {
          this.tokenService.startRefreshCheck()
          this.tokenService.saveTokens(tokens)
        }
        this.getRouter().navigate([redirectUrl])
        this.store.dispatch(new auth.SetLoginRedirectUrl(''))
        if (IS_APP) {
          return new AuthTokenReceivedAction({access: null, refresh: null})
        }
        return new LoadUserDataAction()
      }))
    ,);

  refreshSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.AuthRefreshTokenSuccessAction>(auth.AuthActionTypes.AUTH_REFRESH_TOKEN_SUCCESS),
    map(action => action.payload),
    map(data => {
      this.tokenService.updateAccessToken(data.access);
      return new TokenLoaded();
    }),));

  loadUserData$ = createEffect(() => this.actions$.pipe( // refactor
    ofType<any>(auth.AuthActionTypes.AUTH_LOAD_USER_DATA, auth.AuthActionTypes.AUTH_USER_TOKEN_RECEIVED, auth.AuthActionTypes.AUTH_USER_DATA),
    throttle(() => interval(500)),
    map(action => action.payload),
    withLatestFrom(this.store.select(getAppOnline)),
    filter(payloadAndNetwork => payloadAndNetwork[1]),
    map(payloadAndNetowrk => payloadAndNetowrk[0]),
    switchMap(() => this.helloclassApi.getUserData().pipe(
      map((userData) => {
        this.store.dispatch(new UiSetLanguage(userData['user'].language));

        if (IS_APP) {
          // init notification interval
          this.store.dispatch(new StartInterval());
        }
        return new UserDataSuccessAction(userData['user']);
      }),
      catchError((error) => {
        console.warn('error', error);
        // no connection
        if (error.status === 0) {
          this.store.dispatch(new AddToastAction({message: SYSTEM_ERROR_KEY, type: 'fail'}))
          return of({type: 'NOP', payload: null});
        } else {
          return of(new UserDataFailAction(error));
        }
      }),)
    ),));

  loadUserDataFail$ = createEffect(() => this.actions$.pipe(
    ofType(auth.AuthActionTypes.AUTH_USER_DATA_FAIL),
    map(() => new LogoutAction(true))));

  logoutWeb$ = createEffect(() => this.actions$.pipe(
    ofType<auth.LogoutAction>(auth.AuthActionTypes.AUTH_LOGOUT),
    filter(() => !IS_APP),
    switchMap(code => this.helloclassApi.logout().pipe(
      map(() => {
        this.cookieService.deleteAllCookies();
        return new LogoutSuccessAction(true);
      })
    ))));

  logoutApp$ = createEffect(() => this.actions$.pipe(
    ofType<auth.LogoutAction>(auth.AuthActionTypes.AUTH_LOGOUT),
    switchMap((action) => this.helloclassApi.blacklistToken(this.tokenService.refreshToken, action.payload).pipe(
      map((result) => {
        filter(() => IS_APP),
          this.pushService.unregister();
        this.offlineStorageService.stopInterval();
        this.store.dispatch(new ClearInterval());
        this.tokenService.deleteTokens();
        return new LogoutSuccessAction(result.redirect);
      }),
      catchError((error) => {
        this.pushService.unregister();
        this.offlineStorageService.stopInterval();
        this.store.dispatch(new ClearInterval());
        this.tokenService.deleteTokens();
        return of(new LogoutSuccessAction(true));
      }),)
    )
  ));

  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.LogoutSuccessAction>(auth.AuthActionTypes.AUTH_LOGOUT_SUCCESS),
    map((action) => {
      if (IS_APP) {
        this.getRouter().navigate(['/index_app.html'])
      } else if (action.payload) {
        let currentHost = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        window.location.href = `${currentHost}${'/login'}`;
        // this.getRouter().navigate(['/']);
      }
      return {type: 'NOP', payload: null};
    })));


  tokenRefresh$ = createEffect(() => this.actions$.pipe(
    ofType<auth.AuthRefreshTokenAction>(auth.AuthActionTypes.AUTH_REFRESH_TOKEN),
    debounceTime(2000),
    map(action => action.payload),
    switchMap(token => this.helloclassApi.refreshToken(token).pipe(
      map(accessToken => new AuthRefreshTokenSuccessAction(accessToken)),
      catchError((error) => {
        return of(new AuthRefreshTokenFailAction(error));
      }),)
    )
  ))

  verifyEmail$ = createEffect(() => this.actions$.pipe(
    ofType<auth.VerifyEmailAction>(auth.AuthActionTypes.VERIFY_EMAIL),
    map(action => action.payload),
    switchMap(code => this.helloclassApi.verifyEmail(code).pipe(
      map(data => new VerifyEmailSuccessAction(data)),
      catchError((error) => {
        return of(new VerifyEmailFailureAction(error));
      }),)
    )
  ))

  verifyEmailFail$ = createEffect(() => this.actions$.pipe(
    ofType<auth.VerifyEmailFailureAction>(auth.AuthActionTypes.VERIFY_EMAIL_FAILURE),
    map(action => action.payload),
    map((response: HttpErrorResponse) => {
      let key = '';
      switch (response.status) {
        case 400:
          key = 'Der Code konnte nicht verifiziert werden';
          break;
        default:
          key = 'Es ist ein Fehler aufgetreten!';
          break;
      }
      return new AddAlertAction({message: key, type: 'fail'});
    }),));

  verifyEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.VerifyEmailSuccessAction>(auth.AuthActionTypes.VERIFY_EMAIL_SUCCESS),
    switchMap(() => this.helloclassApi.getUserData().pipe(
      map(userData => {
        this.getRouter().navigate(['/klassenbuch']);
        return new UserDataSuccessAction(userData['user']);
      }),
      catchError((error) => {
        Sentry.captureMessage(`auth:verifyEmailSuccess$ ${error}`);
        return of(new UserDataFailAction(error));
      }),)
    )));

  generateVerificationEmail$ = createEffect(() => this.actions$.pipe(
    ofType(auth.AuthActionTypes.GENERATE_VERIFY_EMAIL),
    switchMap(() => this.helloclassApi.generateVerificationEmail().pipe(
      map(data => new GenerateVerifyEmailSuccessAction(data)),
      catchError((error) => {
        return of(new GenerateVerifyEmailFailureAction(error));
      }),)
    )));

  generateVerificationEmailFail$ = createEffect(() => this.actions$.pipe(
    ofType<auth.GenerateVerifyEmailFailureAction>(auth.AuthActionTypes.GENERATE_VERIFY_EMAIL_FAILURE),
    map(action => action.payload),
    map((response: HttpErrorResponse) => {
      let key = '';
      switch (response.status) {
        case 400:
          key = 'Der Code konnte nicht verifiziert werden';
          break;
        default:
          key = 'Es ist ein Fehler aufgetreten!';
          break;
      }
      return new AddAlertAction({message: key, type: 'success'});
    }),));

  generateVerificationEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.GenerateVerifyEmailSuccessAction>(auth.AuthActionTypes.GENERATE_VERIFY_EMAIL_SUCCESS),
    map(action => action.payload),
    map(() => {
      const key = 'Code wurde erfolgreich neu generiert und an Ihre E-Mail versendet.';
      return new AddAlertAction({message: key, type: 'success'});
    }),));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType<auth.PasswordResetAction>(auth.AuthActionTypes.PASSWORD_RESET),
    map(action => action.payload),
    switchMap(payload => this.helloclassApi.resetPassword(payload).pipe(
      map(data => new PasswordResetSuccessAction(data)),
      catchError((error) => {
        return of(new PasswordResetFailureAction(error));
      }),)
    ),));

  resetPasswordFail$ = createEffect(() => this.actions$.pipe(
    ofType<auth.PasswordResetFailureAction>(auth.AuthActionTypes.PASSWORD_RESET_FAILURE),
    map(action => action.payload),
    map((response: HttpErrorResponse) => {
      let key = '';
      switch (response.status) {
        case 400:
          key = 'Passwort konnte nicht zurückgesetzt werden';
          break;
        default:
          key = 'Es ist ein Fehler aufgetreten!';
          break;
      }
      return new AddAlertAction({message: key, type: 'fail'});
    }),));

  resetPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.PasswordResetSuccessAction>(auth.AuthActionTypes.PASSWORD_RESET_SUCCESS),
    switchMap(() => this.helloclassApi.getUserData().pipe(
      map(userData => {
        this.getRouter().navigate(['/klassenbuch']);
        return new UserDataSuccessAction(userData['user']);
      }),
      catchError((error) => {
        this.store.dispatch(new AddAlertAction({message: error, type: 'fail'}));
        return of(new UserDataFailAction(error));
      }),)
    )));

  activationLinkGenerate$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ActivationLinkGenerateAction>(auth.AuthActionTypes.ACTIVATION_LINK_GENERATE),
    map(action => action.payload),
    switchMap(payload => this.helloclassApi.forgotPasswordGenerate([payload]).pipe(
      map(data => new ActivationLinkGenerateSuccessAction(data)),
      catchError((error) => {
        return of(new ActivationLinkGenerateFailureAction(error));
      }),)
    ),));

  activationLinkGenerateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ActivationLinkGenerateSuccessAction>(auth.AuthActionTypes.ACTIVATION_LINK_GENERATE_SUCCESS),
    withLatestFrom(this.store.select(getUser)),
    map((v: [ActivationLinkGenerateSuccessAction, User]) => {
      return new LoadUserAction(v[1].id);
    }),));

  multipleActivationLinkGenerate$ = createEffect(() => this.actions$.pipe(
    ofType<auth.GenerateMultipleActivationLinksAction>(auth.AuthActionTypes.MULTIPLE_ACTIVATION_LINK_GENERATE),
    map(action => action.payload),
    switchMap(payload => this.helloclassApi.generateMultiplePasswords(payload).pipe(
      map(() => new GenerateMultipleActivationLinksSuccessAction()),
      catchError((error) => {
        return of(new GenerateMultipleActivationLinksFailAction(error));
      }),)
    ),));

  multipleActivationLinkGenerateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(auth.AuthActionTypes.MULTIPLE_ACTIVATION_LINK_GENERATE_SUCCESS),
    map(() => new UsersLoadAction())))

  multipleActivationLinkGenerateFail$ = createEffect(() => this.actions$.pipe(
    ofType(auth.AuthActionTypes.MULTIPLE_ACTIVATION_LINK_GENERATE_FAILURE),
    map(() => new AddAlertAction({message: 'Es ist ein Fehler aufgetreten!', type: 'fail'}))));

  forgotPasswordGenerate$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ForgotPasswordGenerateAction>(auth.AuthActionTypes.FORGOT_PASSWORD_GENERATE),
    map(action => {
      this.tokenService.deleteTokens();
      return action.payload;
    }),
    switchMap(payload => this.helloclassApi.forgotPasswordGenerate([payload]).pipe(
      map(data => new ForgotPasswordGenerateSuccessAction(data)),
      catchError((error) => {
        return of(new ForgotPasswordGenerateFailureAction(error));
      }),)
    ),));

  generationGenerateFail$ = createEffect(() => this.actions$.pipe(
    ofType<any>(auth.AuthActionTypes.FORGOT_PASSWORD_GENERATE_FAILURE,
      auth.AuthActionTypes.FORGOT_PASSWORD_RESET_FAILURE,
      auth.AuthActionTypes.ACTIVATION_LINK_GENERATE_FAILURE),

    map(action => action.payload),
    map((error: HttpErrorResponse) => {
      let text = '';
      switch (error.error['status']) {
        case 'password_reset_fail_invalid_request':
          text = 'Das Passwort kann nicht zurückgesetzt werden. Bitte überprüfe die E-mail Adresse.';
          break;
        case 'password_reset_fail_key_invalid':
          text = 'Der Link ist ungültig.';
          break;
        case 'password_reset_fail_key_expired':
          text = 'Dieser Link ist nicht mehr gültig.';
          break;
        case 'password_generate_fail_unknown_email':
          text = 'Die angegebene E-Mail Adresse konnte nicht gefunden werden.';
          break;
        default:
          Sentry.captureMessage(`auth:verifyEmailSuccess$ ${error.error['status']}`);
          text = 'Es ist ein Fehler aufgetreten!';
          break;
      }
      return new AddAlertAction({message: text, type: 'fail'});
    })
  ));

  forgotPasswordGenerateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ForgotPasswordGenerateSuccessAction>(auth.AuthActionTypes.FORGOT_PASSWORD_GENERATE_SUCCESS),
    map(action => action.payload),
    map(() => {
      this.getRouter().navigate(['/login']);
      return new AddToastAction({message: 'Die E-Mail wurde verschickt', type: 'success'});
    }),));

  forgotPasswordReset$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ForgotPasswordResetAction>(auth.AuthActionTypes.FORGOT_PASSWORD_RESET),
    map(action => action.payload),
    switchMap(payload => this.helloclassApi.forgotPasswordReset(payload.key, payload).pipe(
      map(data => new ForgotPasswordResetSuccessAction(data)),
      catchError((error) => {
        return of(new ForgotPasswordResetFailureAction(error));
      }),)
    ),));

  forgotPasswordResetSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ForgotPasswordResetSuccessAction>(auth.AuthActionTypes.FORGOT_PASSWORD_RESET_SUCCESS),
    map(action => action.payload),
    map(() => {
      this.getRouter().navigate(['/login']);
      return new AddToastAction({message: 'Das Passwort wurde erfolgreich gesetzt', type: 'success'});
    }),));

  authTokenLoadLocal$ = createEffect(() => this.actions$.pipe(
    ofType(auth.AuthActionTypes.AUTH_TOKEN_LOAD_LOCAL),
    map(() => {
      let tokens = this.tokenService.getTokens();
      if (this.tokenService.accessTokenIsValid()) {
        return new AuthTokenReceivedAction(tokens);
      } else if (this.tokenService.refreshTokenIsValid()) {
        return new AuthRefreshTokenAction(tokens.refresh)
      }
      return new AuthTokenReset();
    })));

  updateGroupSettings$ = createEffect(() => this.actions$.pipe(
    ofType(auth.AuthActionTypes.UPDATE_GROUP_SETTINGS),
    withLatestFrom(
      this.store.select(getLatestNotificationId),
      this.store.select(getSelectedDisplayGroupsIds),
      this.store.select(showEventOnboarding),
      this.store.select(showRelationshipOnboarding)
    ),
    map((values) => new UpdateSettings({
      latestNotificationId: values[1],
      selectedGroups: values[2],
      showEventOnboarding: values[3],
      showRelationshipOnboarding: values[4]
    })),));

  updateLatestNotificationSettings$ = createEffect(() => this.actions$.pipe(
    ofType<any>(auth.AuthActionTypes.UPDATE_LATEST_NOTIFICATION_ID_SETTINGS),
    map(action => action.payload),
    withLatestFrom(
      this.store.select(getSelectedDisplayGroupsIds),
      this.store.select(showEventOnboarding),
      this.store.select(showRelationshipOnboarding)
    ),
    map((values) => new UpdateSettings({
      latestNotificationId: values[0],
      selectedGroups: values[1],
      showEventOnboarding: values[2],
      showRelationshipOnboarding: values[3]
    })),
  ));

  disableShowEventOnboardingSettings$ = createEffect(() => this.actions$.pipe(
    ofType<any>(auth.AuthActionTypes.DISABLE_SHOW_EVENT_ONBOARDING),
    withLatestFrom(
      this.store.select(getLatestNotificationId),
      this.store.select(getSelectedDisplayGroupsIds),
      this.store.select(showEventOnboarding),
      this.store.select(showRelationshipOnboarding)
    ),
    filter(values => values[3]),
    map((values) => new UpdateSettings({
      latestNotificationId: values[1],
      selectedGroups: values[2],
      showEventOnboarding: false,
      showRelationshipOnboarding: values[4]
    })),
  ));

  disableShowRelationshipOnboardingSettings$ = createEffect(() => this.actions$.pipe(
    ofType<any>(auth.AuthActionTypes.DISABLE_SHOW_RELATIONSHIP_ONBOARDING),
    withLatestFrom(
      this.store.select(getLatestNotificationId),
      this.store.select(getSelectedDisplayGroupsIds),
      this.store.select(showEventOnboarding),
      this.store.select(showRelationshipOnboarding)
    ),
    filter(values => values[4]),
    map((values) => new UpdateSettings({
      latestNotificationId: values[1],
      selectedGroups: values[2],
      showEventOnboarding: values[3],
      showRelationshipOnboarding: false
    })),
  ));

  updateSettings$ = createEffect(() => this.actions$.pipe(
    ofType<any>(auth.AuthActionTypes.UPDATE_SETTINGS),
    debounceTime(2000),
    map(action => action.payload),
    switchMap((settings: UserSettings) => this.helloclassApi.updateSettings(settings.latestNotificationId,
      settings.selectedGroups, settings.showEventOnboarding, settings.showRelationshipOnboarding).pipe(
      map(() => ({type: "NULL", payload: null})),
      catchError(() => of({type: "NULL", payload: null})),)
    )
  ))

  // Offline handling

  preventLoadUserDataWhenOffline$ = createEffect(() => this.actions$.pipe(
    ofType<any>(auth.AuthActionTypes.AUTH_LOAD_USER_DATA, auth.AuthActionTypes.AUTH_USER_TOKEN_RECEIVED, auth.AuthActionTypes.AUTH_USER_DATA),
    map(action => action.payload),
    withLatestFrom(this.store.select(getAppOnline)),
    filter(payloadAndNetwork => !payloadAndNetwork[1]),
    map(() => ({type: 'NOP', payload: null}))
  ))

  activateCode$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ActivateCode>(auth.AuthActionTypes.ACTIVATE_CODE),
    map(action => action.payload),
    switchMap(code => this.helloclassApi.activateCode(code).pipe(
      map(() => new auth.ActivateCodeSuccess(code)),
      catchError((error) => {
        return of(new auth.ActivateCodeFail(error));
      }),)
    )
  ))

  activateCodeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ActivateCodeSuccess>(auth.AuthActionTypes.ACTIVATE_SUCCESS),
    map(action => {
      this.getRouter().navigate([`activate/${action.payload}`])
      return {type: 'NOP', payload: null}
    })
  ));

  activateCodeFail$ = createEffect(() => this.actions$.pipe(
    ofType<auth.ActivateCodeFail>(auth.AuthActionTypes.ACTIVATE_FAIL),
    map(action => action.payload),
    map((response: HttpErrorResponse) => {
      let message = '';
      switch (response.status) {
        case 400:
          message = 'Der Code ist ungültig oder abgelaufen';
          break;
        default:
          message = 'Es ist ein Fehler aufgetreten!';
          break;
      }
      return new auth.ActivateCodeFormErrorAction({general: message});
    }),
  ));
}

