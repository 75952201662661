import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {Cookies} from "../token/cookies";
import {AuthTokenLoadLocal, LoadUserDataAction, LogoutAction, SetLoginRedirectUrl} from "../actions/authentication";
import {from, of as observableOf} from "rxjs";
import {filter, map, switchMap, take} from "rxjs/operators";
import * as fromAuth from "../reducers";
import {getIsTokenLoaded} from "../reducers";
import {TokenService} from "../token/token";
import {IS_APP} from "../app.constants";

@Injectable()
export class UserIsLoggedInGuard {
  constructor(
    private store: Store<fromAuth.AppState>,
    private cookieService: Cookies,
    private router: Router,
    private tokenService: TokenService
  ) {
  }

  isLoggedIn() {
    return from(this.cookieService.isLoggedIn()).pipe(
      switchMap(isloggedIn => {
        if (isloggedIn) {
          this.store.dispatch(new LoadUserDataAction());
          return observableOf(true);
        } else {
          return observableOf(false);
        }
      }),);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("UserIsLoggedIn guard activated.")
    if (IS_APP) {
      return this.verifyToken(state.url)
    }
    return this.isLoggedIn()
  }

  verifyToken(url: string) {
    this.store.dispatch(new AuthTokenLoadLocal());
    return this.store.select(getIsTokenLoaded).pipe(
      filter(isLoaded => isLoaded),
      take(1),
      map(() => {
        if (!this.tokenService.accessTokenIsValid()) {
          this.store.dispatch(new SetLoginRedirectUrl(url));
          this.store.dispatch(new LogoutAction(true))
          return false
        } else {
          return true
        }
      }),)
  }
}
