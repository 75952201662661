import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'hc-spinner',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div [ngClass]="{'isHidden' : !show}" class="klassenbuch-spinner loader-spinner loader-spinner--large"></div>
    `,
})
export class SpinnerComponent {

    @Input() show = false;

    constructor() {
    }
}
