import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from "@angular/core";
import {FileAttachment} from "../attachment/attachment.model";

@Component({
  selector: 'hc-document',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="md:h-[72px] py-[12px] items-center relative">
      <div
        data-cy="document"
        class="grid grid-cols-[48px_auto_1fr] grid-rows-1 md:grid-cols-[48px_auto_2fr_1fr] md:grid-rows-1 items-center">
        <div class="max-w-[48px]">
          <img src="{{document.thumbnail_url}}" *ngIf="document.isImage(); else documentThumbnail">
          <ng-template #documentThumbnail>
            <span class="icon-document document-thumbnail document-thumbnail--small"></span>
          </ng-template>
        </div>
        <div class="ml-[8px] flex flex-col justify-between">
          <p
            data-cy="document-name"
            class="m-0 text-emph">{{document.filename}}</p>
          <p class="m-0"><span translate>Aufgabe</span>: <a
            class="text-black underline"
            data-cy="document-link"
            [routerLink]="['/klassenbuch/events/', document.event.id]">{{document.event.title}}</a></p>
          <p
            class="show-only-\@s m-0 text-gray-800 text-[13px]">{{document.created | dateFormatFilter: 'dddd, DD. MMM YYYY, HH:mm'}}</p>
        </div>
        <div class="text-right hidden-\@xs">
          {{document.created | dateFormatFilter: 'dddd, DD. MMM YYYY, HH:mm'}}
        </div>
        <div class="flex justify-end">
          <button (click)="showHover = !showHover"
                  data-cy="document-menu"
                  class="text-right icon-3-dots h-[30px] w-[30px] text-[25px]">
          </button>
        </div>
        <div #menu
             [ngClass]="{hidden: !showHover}"
             class="absolute right-0 p-[24px] bg-white border-gray-600 border z-10">
          <ol>
            <li class="mb-[24px]"><span class="icon-download inline-block mr-[8px]"></span><span
              translate><a [href]="document.url" class="no-underline theme-black"
                           download>Herunterladen</a></span>
            </li>
            <li *ngIf="document.isOwner">
              <span class="icon-bin inline-block mr-[8px] cursor-pointer"></span><span
              (click)="deleteDocument(document.id)"
              data-cy="document-delete"
              translate>Löschen</span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  `
})
export class DocumentComponent {
  @Input() document: FileAttachment
  @Output() delete = new EventEmitter<number>()
  @ViewChild('menu') menu: ElementRef


  protected showHover = false

  constructor(private eRef: ElementRef) {
  }

  @HostListener('window:keydown.escape', ['$event'])
  handleKeyDown(_event: KeyboardEvent) {
    this.showHover = false
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showHover = false
    }
  }

  isEvent(document) {
    return document.comment == null;
  }

  wrapperClasses(document) {

    let baseClass = 'document__wrapper';

    if (!this.isEvent(document)) {
      return baseClass;
    }

    return `${baseClass} ${baseClass}--${document.event.label_color}`
  }

  deleteDocument(docId: number) {
    this.delete.emit(this.document.id)
  }
}
