import {Injectable} from '@angular/core';
import {combineLatest} from "rxjs";
import {take} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

declare global {
  interface Window {
    plugins: any
  }
}

@Injectable({
  providedIn: 'root',
})
export class ActionsheetService {

  constructor(private translateService: TranslateService) {
  }

  public createActionsheetHandler(title: string, cancelTranslationKey: string, destructiveTranslationKey: string | null,
                                  buttonTranslationKeys: string[], cancelHandler, destructiveHandler, buttonHandlers) {

    let allKeys, allHandlers
    const hasDestructiveButton = this.argumentIsSet(destructiveTranslationKey)

    if (hasDestructiveButton) {
      allKeys = [title, cancelTranslationKey, destructiveTranslationKey, ...buttonTranslationKeys]
      allHandlers = [...buttonHandlers, destructiveHandler, cancelHandler]
    } else {
      allKeys = [title, cancelTranslationKey, ...buttonTranslationKeys]
      allHandlers = [...buttonHandlers, cancelHandler]
    }

    const translations$ = allKeys.map(key => this.translateService.get(key))
    combineLatest(translations$).pipe(
      take(1)
    ).subscribe((translations: string[]) => {
      const options = {
        title: translations[0],
        addCancelButtonWithLabel: translations[1],
        destructiveButtonLast: true
      }

      if (hasDestructiveButton) {
        options['addDestructiveButtonWithLabel'] = (translations as string[])[2]
      }

      if (buttonTranslationKeys.length > 0) {
        const startIndex = hasDestructiveButton ? 3 : 2
        options['buttonLabels'] = translations.slice(startIndex)
      }

      window.plugins.actionsheet.show(options, (buttonIndex) => {
        allHandlers[buttonIndex - 1]()
        window.plugins.actionsheet.hide()
      });
    })
  }

  private argumentIsSet(arg: string | null) {
    return arg && arg !== ''
  }

}
