import {NgModule} from '@angular/core';
import {EdulogHttpService} from "./edulog.http.service";
import {TemporaryTokenGuard} from "./tempToken.guard";
import {EdulogRoutingModule} from "./edulog.routing";
import {TranslateModule} from "@ngx-translate/core";
import {EdulogWidget} from "./edulog-widget";
import {CommonModule} from "@angular/common";
import {EdulogTermsPage} from "./edulog.terms.page";
import {SignupTermsModule} from "../signup-terms/signup-terms.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EdulogExistingUserPage} from "./edulog.existing-user.page";
import {RouterModule} from "@angular/router";

@NgModule(
  {
    imports: [
      EdulogRoutingModule,
      TranslateModule,
      CommonModule,
      SignupTermsModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule
    ],
    declarations: [
      EdulogWidget,
      EdulogTermsPage,
      EdulogExistingUserPage
    ],
    exports: [
      EdulogWidget
    ],
    providers: [
      EdulogHttpService,
      TemporaryTokenGuard,
      EdulogHttpService
    ]
  })
export class EdulogModule {
}
