import {Relationship} from "./relationship.models";

import { createAction, props } from '@ngrx/store';

export const relationshipUpdate = createAction(
  '[Relationship] Update', props<{
    schoolId: number;
    userId: number;
    newRelationships: Relationship[];
    updatedRelationships: Relationship[];
    deletedRelationships: number[]
  }>()
);
