import {filter, map, switchMap, take, takeWhile} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, Renderer2} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Event, NavigationEnd, Router} from "@angular/router";
import {IS_APP} from "../app.constants";

import * as reducer from "../reducers";
import {
  UiHideMenuAction,
  UiHideNavigationAction,
  UiHideNotifications,
  UiNotificationSelected,
  UiSetAppPart,
  UiShowMenuAction,
  UiShowNavigationAction,
  UiToggleNotifications,
} from "../actions/ui";
import {UnsetNewNotifications} from "../notifications/notifications.actions";
import {PushService} from '../app/push.service';
import {OfflineStorageService} from '../app/offlinestorage.service';
import {LoadGroupsAction} from "../actions/groups";
import {Notification} from '../notifications/notifications.models';
import {TranslateService} from "@ngx-translate/core";


const GROUPS_PREFIX = "/admin/groups";
const PERSON_PREFIX = "/admin/users";
const PROFILE_PREFIX = "/klassenbuch/profile";
const LABELS_PREFIX = "/admin/labels";
const ROLES_PREFIX = "/admin/roles";
const NOTIFICATIONS_PREFIX = "/klassenbuch/notifications";
const MY_DOCS_PREFIX = "/klassenbuch/documents";
const ABSENCES_PREFIX = "/absences";

const APP_PARTS = [
  {
    url: GROUPS_PREFIX,
    part: 'groups'
  },
  {
    url: PERSON_PREFIX,
    part: 'person'
  },
  {
    url: PROFILE_PREFIX,
    part: 'profile'
  },
  {
    url: LABELS_PREFIX,
    part: 'labels'
  },
  {
    url: ROLES_PREFIX,
    part: 'roles'
  },
  {
    url: NOTIFICATIONS_PREFIX,
    part: 'notifications'
  },
  {
    url: MY_DOCS_PREFIX,
    part: 'documents'
  },
  {
    url: ABSENCES_PREFIX,
    part: 'absences'
  },
]

@Component({
  selector: 'hc-home',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <hc-admin-messages></hc-admin-messages>
    <hc-navigation [showNavigation]="navigationShown$ | async"
                   [username]="username$ | async"
                   [schoolName]="schoolName$ | async"
                   [showAdmin]="isAdmin$ | async"
                   [groupNames]="groupNames$ | async">
    </hc-navigation>
    <div class="main" id="main" [ngClass]="{'is-full-width': !(navigationShown$ | async)}">
      <div class="header"
           [ngClass]="[(navigationShown$ | async) ? 'is-open' : '', this.appPartName === 'klassenbuch' ? 'header--klassenbuch': '' ]">
        <div class="header__wrapper">
          <div class="header__btn header__btn--left">
            <div class="main-menu__toggle" data-cy="mainmenu-toggle">
              <button
                [ngClass]="{'icon-3-lines': !(navigationShown$ | async), 'icon-cross': (navigationShown$ | async) }"
                class="qa-header-button header__button unstyled-button" (click)="toggleNav()"></button>
            </div>
          </div>
          <div class="header__main">
            <div class="header__title header__title--{{ appPartName }}">
              <button
                aria-label="Back"
                class="header__back inline-block icon-arrow-left unstyled-button"
                (click)="goBack()"
                [ngClass]="{'header__back--is-hidden': !(backShown$ | async)}"
                data-cy="backButton"></button>
              <h1 class="header__page-title inline-block h4" data-cy="pageTitle">{{ pageTitle$ | async }}</h1>
            </div>
            <div class="header__options header-options">
              <button
                class="inline-block icon-3-dots unstyled-button header-options__button header__context-button qa-context-menu-button"
                [ngClass]="{'header__context-button--hide': !(contextMenuShown$ | async), 'header__context-button--show': forceDropdown$ | async}"
                (click)="toggleMenu($event)">
              </button>
              <div class="modal-menu hidden-@s header__context-menu-l pull-right sm-full-selector" hcOverlayHide
                   (hideOverlay)="hideOverlay()"
                   [ngClass]="{'modal-menu--show-mobile': contextMenuOpen$ | async, 'modal-menu--hide': !(contextMenuShown$ | async) }"
                   data-cy="overlay">
                <dcl-wrapper [type]="contextMenu$ | async" class="context-menu"></dcl-wrapper>
              </div>
            </div>
          </div>
          <div class="header__btn header__btn--right">
            <button [ngClass]="{'notification-btn--new':(hasNotification$ | async) }"
                    class="qa-header-button header__button header__button--right unstyled-button notification-btn"
                    (click)="showNotifications()"></button>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
    <div class="sidebar" [ngClass]="{'sidebar--is-shown': (notificationsShown$ | async) }">
      <hc-notification-list
        [notifications]="notifications$ | async"
        [error]="notificationError$ | async"
        (notificationSelected)="notificationSelected($event)">
      </hc-notification-list>
      <p class="sidebar__message">
        <a [routerLink]="['/klassenbuch/notifications']"
           (click)="showNotifications()">{{'Alle Meldungen anzeigen' | translate}}</a>
      </p>
    </div>
    <div class="content-overlay" [ngClass]="{'content-overlay--is-shown': (notificationsShown$ | async) }"></div>
  `
})
export class HomePage {
  user$: Observable<any>;
  username$: Observable<string>;
  schoolName$: Observable<string>;
  groupNames$: Observable<string[]>;
  public navigationShown$: Observable<boolean>;
  forceDropdown$: Observable<boolean>;
  contextMenuShown$: Observable<boolean>;
  contextMenuOpen$: Observable<boolean>;
  backShown$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  pageTitle$: Observable<string>;
  contextMenu$: Observable<string>;
  hasNotification$: Observable<boolean>;
  notificationsShown$: Observable<boolean>;
  notifications$: Observable<Notification[]>;
  public notificationError$: Observable<string>;
  backUrl: string;
  appPartName: string;
  private alive = true;

  constructor(private store: Store<reducer.AppState>,
              private router: Router,
              private renderer: Renderer2,
              private pushService: PushService,
              private offlineStorageService: OfflineStorageService,
              private translateService: TranslateService) {

    this.store.dispatch(new LoadGroupsAction()); //todo: needs to detect route changes?
    this.forceDropdown$ = store.select(reducer.getForceContextDropdown);
    this.user$ = store.select(reducer.getAuthUser);
    this.contextMenuShown$ = store.select(reducer.getContextMenuShown);
    this.contextMenuOpen$ = store.select(reducer.getContextMenuOpen);
    this.backShown$ = store.select(reducer.getBackShown);
    this.pageTitle$ = store.select(reducer.getUiTitle).pipe(
      filter(title => title !== ''),
      switchMap((title) =>
        this.translateService.get(title).pipe(
          map((translation: string) => translation)
        )
      )
    )
    this.navigationShown$ = store.select(reducer.getNavigationShown);
    this.contextMenu$ = store.select(reducer.getContextMenu);
    this.username$ = this.user$.pipe(map(user => user.username));
    this.schoolName$ = this.user$.pipe(map(user => user.schoolName));
    store.select(reducer.getBackUrl).pipe(
      takeWhile(() => this.alive))
      .subscribe(url => this.backUrl = url);
    this.groupNames$ = store.select(reducer.getGroups).pipe(
      map(groups => groups.map(group => group.display_name)));
    this.hasNotification$ = store.select(reducer.getnewNotifications);
    this.notificationsShown$ = store.select(reducer.isNotificationsShown);
    this.notifications$ = store.select(reducer.getNotifications);
    this.notificationError$ = store.select(reducer.getNotificationError);
    this.isAdmin$ = store.select(reducer.isAdmin).pipe(map((isAdmin) => IS_APP ? false : isAdmin))

    router.events.pipe(
      takeWhile(() => this.alive))
      .subscribe((event: Event) => {
        if (event instanceof (NavigationEnd)) {
          let appPart = 'klassenbuch';
          for (let i = 0; i < APP_PARTS.length; i++) {
            if (event.url.startsWith(APP_PARTS[i].url)) {
              appPart = APP_PARTS[i].part;
              break;
            }
          }

          this.store.dispatch(new UiHideNavigationAction());
          this.store.dispatch(new UiSetAppPart(appPart));
          this.appPartName = appPart;
        }

        this.renderer.removeClass(document.body, 'no-scroll');
      });

    this.notificationsShown$.pipe(
      takeWhile(() => this.alive),
      filter(shown => shown === true),)
      .subscribe(() => setTimeout(() => this.store.dispatch(new UnsetNewNotifications()), 1500));

    this.pushService.initPush();
    this.offlineStorageService.initDehydration();
  }

  public goBack() { // todo: use service
    if (this.backUrl !== '') {
      this.store.dispatch(new UiHideNotifications());
      this.router.navigateByUrl(this.backUrl);
    }
  }

  public toggleNav() {
    this.navigationShown$.pipe(take(1)).subscribe((shown) => {
      let action;

      if (shown) {
        action = new UiHideNavigationAction();
        this.renderer.removeClass(document.body, 'no-scroll');
      } else {
        action = new UiShowNavigationAction();
        this.renderer.addClass(document.body, 'no-scroll');
        this.store.dispatch(new UiHideMenuAction());
      }

      this.store.dispatch(action);
    });
  }

  public toggleMenu(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.contextMenuOpen$.pipe(take(1)).subscribe((shown) => {
      let action = shown ? new UiHideMenuAction() : new UiShowMenuAction();
      this.store.dispatch(action);
    });
  }

  public showNotifications() {
    this.store.dispatch(new UiToggleNotifications());
  }

  public notificationSelected(notification: Notification) {
    this.store.dispatch(new UiNotificationSelected(notification));
  }

  public hideOverlay() {
    this.handleEscAndClick()
  }

  private handleEscAndClick() {
    this.contextMenuOpen$.pipe(
      take(1),
      filter(isOpen => isOpen),)
      .subscribe(() => this.store.dispatch(new UiHideMenuAction()));
  }

  ngOnDestroy() {
    this.alive = false
  }

}
