import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {RelationshipManageComponent} from "./relationship.manage.component";


@Component({
    selector: 'hc-relationships',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div class="relationships">
        <h2 class="heading-2" data-cy="profile-relationship-title" translate>Verknüpfte Personen</h2>
        <div *ngIf="canManage">
            <hc-relationship-manage #relationshipmanageComponent
                [userId]="userId"
                [schoolId]="schoolId">
            </hc-relationship-manage>
        </div>
        <div *ngIf="!canManage">
            <hc-relationship-readonly
                [userId]="userId"
                [schoolId]="schoolId">
            </hc-relationship-readonly>
        </div>
    </div>
    `,
})
export class RelationshipComponent {

    @ViewChild('relationshipmanageComponent', {static: false}) public relationshipManageComponent: RelationshipManageComponent;

    @Input() userId: number;
    @Input() schoolId: number;
    @Input() canManage: boolean;

    constructor() {
    }

    public save() {
        this.relationshipManageComponent.saveRelationships();
    }

}
