import {of as observableOf} from 'rxjs';

import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable, Injector} from '@angular/core';
import {EffectsWithRouter} from '../effects/router-effect';
import {HelloClassApiService} from '../services';
import * as signupActions from '../actions/signup';
import {signup, SignupActionTypes, SignupAddFormErrorAction, SignupFailAction, signupPremium} from '../actions/signup';
import {LoginAction} from '../actions/authentication';
import {Credentials} from '../models';
import {Store} from '@ngrx/store';
import {AppState, getLoginRedirectUrl} from '../reducers';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class SignupEffects extends EffectsWithRouter {

  constructor(
    private actions$: Actions,
    private helloclassApi: HelloClassApiService,
    injector: Injector,
    private store: Store<AppState>,
  ) {
    super(injector);
  }

  signup$ = createEffect(() => this.actions$.pipe(
    ofType(signup, signupPremium),
    withLatestFrom(this.store.select(getLoginRedirectUrl)),
    switchMap(values => {
        const {["type"]: _remove, ...user} = values[0]
        return this.helloclassApi.signupUser(user)
          .pipe(
            map(() => {
              let data = new Credentials(user.email, user.password);
              return new LoginAction(data);
            }),
            catchError((error: HttpErrorResponse) => {

              let key = '';
              let type = 'email';

              // todo: get rid of nested subscription, generalize so not only email is handled
              if (error.status === 400 && error.error.email) {
                // todo: handle multiple responses
                switch (error.error.email[0]) {
                  case 'Gib eine gültige E-Mail Adresse an.':
                    key = 'Diese E-Mail Adresse ist ungültig.';
                    break;

                  case 'Diese Email ist bereits registriert':
                  case 'Dieser Benutzername ist bereits registriert':
                    key = 'Diese E-Mail Adresse wurde bereits registriert. Melde dich mit dieser E-Mail an oder setzt das Passwort zurück.';
                    break;
                }

              } else {
                key = 'Es ist ein Systemfehler aufgetreten. Bitte wende Dich sich an den Administrator.';
                type = 'general';
              }

              return observableOf(new SignupFailAction({key: [key], type: type}));
            })
          )
      }
    ),));

  signupFail$ = createEffect(() => this.actions$.pipe(
    ofType<signupActions.SignupFailAction>(SignupActionTypes.SIGNUP_FAILURE),
    map(action => action.payload),
    filter(error => error['key'] !== ''),
    map((error) => {
      let formError = {};
      formError[error['type']] = error['key'];

      return new SignupAddFormErrorAction(formError);
    })
  ));
}
