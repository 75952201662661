import {ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges} from '@angular/core';

import {ComponentStore} from "@ngrx/component-store";
import {Relationship, RelationshipUser} from "./relationship.models";
import {RelationshipStore} from "./relationship.store";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'hc-relationship-onboarding-selection',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div class="relationship-manage">
        <div>
            <hc-relationship-selection
                [users]="selectableUsers$ | async"
                [userId]="relationship.cared_for.id"
                [placeholder]="translateService.get('Person auswählen...') | async"
                (userSelected)="addRelationship($event)"
                class="relationshipmanage-item__content"></hc-relationship-selection>
        </div>
    </div>
    `,
    providers: [ComponentStore, RelationshipStore]
})
export class RelationshipOnboardingManageComponent implements OnInit {

    @Input() userId: number;
    @Input() schoolId: number;
    @Input() groups: number[] = [];

    readonly selectableUsers$ = this.relationshipStore.users$;
    relationship: Relationship = {
        id: -1,
        guardian: {
            id: -1,
            first_name: '',
            last_name: ''
        },
        cared_for: {
            id: -1,
            first_name: '',
            last_name: ''
        },
    }

    constructor(private readonly relationshipStore: RelationshipStore,
                public translateService: TranslateService) {
    }

    public addRelationship (selectedUser: RelationshipUser) {
        this.relationship.cared_for = selectedUser;
        this.relationshipStore.addNonEmptyRelationship({
            userId: this.userId,
            selectableUserId: selectedUser.id
        })
    }

    public removeRelationship (id, index: number) {
        this.relationshipStore.removeRelationship({
            id,
            index,
            userId: this.userId
        });
    }

    public saveRelationships () {
        this.relationshipStore.saveRelationships({
            schoolId: this.schoolId,
            userId: this.userId
        });
    }

    ngOnInit () {
        this.relationship.guardian.id = this.userId;
        this.relationshipStore.fetchData(this.schoolId, this.userId);
    }

    public selectedUserName (): string {
        if (this.relationship.cared_for.id > -1) {
            return `${this.relationship.cared_for.first_name} ${this.relationship.cared_for.last_name}`;
        }

        return '';
    }

}
