
import {of as observableOf} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import * as relationship from "./relationship.actions";


import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {RelationshipHttpService} from "./relationship.http.service";
import {AddToastAction} from "../alerts/alerts.actions";

@Injectable()
export class RelationshipEffects {

  updateRelationship$ = createEffect(() =>
      this.actions$.pipe(
         ofType(relationship.relationshipUpdate),
          switchMap(action => this.httpService
             .updateRelationships(action.schoolId, action.userId, action.newRelationships,
                 action.updatedRelationships, action.deletedRelationships)
              .pipe(
                 catchError(error => observableOf(new AddToastAction({ type: 'fail', message: 'Die Verknüpfungen konnten nicht gespeichert werden!'})))
             ,)
          )
      ), { useEffectsErrorHandler: false, dispatch: false }
  )

  constructor(private actions$: Actions, private httpService: RelationshipHttpService) {}
}
