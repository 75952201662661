import {Component, ChangeDetectionStrategy, OnInit} from "@angular/core";
import {EDULOG_SSO} from "../app.constants";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'hc-edulog-terms-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="container-fluid container-fluid--limit signup-page">
            <div class="lead-in">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="heading-2 qa-title" translate>Damit du Helloclass verwenden kannst, musst du die folgenden Bedingungen akzeptieren.</h1>
                    </div>
                </div>
            </div>
            <form [formGroup]="form" (submit)="formSubmit()">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                        <hc-terms
                            *ngIf="!underAged"
                            [formErrors]="formErrors"
                            >
                            <input formControlName="terms" id="terms" type="checkbox">
                        </hc-terms>
                        <hc-terms
                            *ngIf="underAged"
                            [legal]="legalUnderAged"
                            [formErrors]="formErrors"
                            >
                            <input formControlName="terms" id="terms" type="checkbox">
                        </hc-terms>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 col-md-offset-1">
                        <button class="btn btn--innershadow vertical-margin-bottom btn--blue qa-login-button btn--full-width login-form__button"
                            data-cy="signup-submit"
                            type="submit" translate>
                            Registrierung abschliessen
                        </button>
                    </div>
                </div>
            </form>
        </div>
    `
})
export class EdulogTermsPage implements OnInit {
    public edulogUrl = `${EDULOG_SSO}terms=true&redirect=true&session=`
    public form: FormGroup
    public formErrors = []
    public underAged = false
    public legalUnderAged = 'Du brauchst die Zustimmung deiner Eltern, um Helloclass zu nutzen. Wenn Du unten auf «Registrierung abschliessen» klickst, gehen wir davon aus, dass deine Eltern mit den <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Nutzungsbedingungen.pdf\">Nutzungsbedingungen</a>, den <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Privacy_Policy.pdf\">Datenschutzbestimmungen</a> und den <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Cookies.pdf\">Cookies-Bestimmungen</a> einverstanden sind.';


    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute) {
        this.form = formBuilder.group({
           terms: ['', Validators.requiredTrue],
           override: [false]
        });
    }

    public formSubmit () {
        if (!this.form.valid) {
            this.formErrors = ['Die Nutzungsbedingungen müssen akzeptiert werden']
            return
        }
        this.formErrors = []
        window.location.href = this.edulogUrl
    }

    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            this.underAged = params?.underaged && params?.underaged === 'true'
            this.edulogUrl += params?.session ? params?.session : ''
          }
        );
      }

}
