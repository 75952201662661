
import {map, take, tap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Router, ActivatedRouteSnapshot } from "@angular/router";

import * as fromAuth from "../reducers";
import {getAuthUser} from "../reducers";
import {HelloclassPermissionsService} from "../permissions/permissions.service";


@Injectable()
export class RelationshipOnboardingGuard  {
  constructor(
    private store: Store<fromAuth.AppState>,
    private permissionsService: HelloclassPermissionsService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
        return this.store.select(getAuthUser).pipe(
            map(userData => {
                if (userData.premium &&
                    this.permissionsService.canCreateRelationshipForSelf(userData.permissions) &&
                    !this.permissionsService.canAdminSchool(userData.permissions) &&
                    userData.showRelationshipOnboarding) {
                    return this.router.parseUrl(`/relationships/onboarding`);
                }
                return true;
            }),
            take(1)
        );
  }
}
