
import {combineLatest} from 'rxjs';
import * as dayjs from "dayjs";
import {TranslateService } from '@ngx-translate/core';

import {Component, Output, Input, EventEmitter, ChangeDetectionStrategy, SimpleChanges} from '@angular/core';
import {CalendarService} from '../services';

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
    selector: 'hc-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="calendar">
            <div class="calendar__toprow">
                <div class="toprow__left">
                    <p>
                    <button aria-label="Show year & month selection" class="selection-toggle unstyled-button" (click)="toggleSelection()"
                    [ngClass]="{'is-active': !selectionHidden}">{{temporarySelectionDate |dateFormatFilter:'MMMM'}} {{temporarySelectionDate |dateFormatFilter:'YYYY'}}<span class="icon-arrow-down"></span></button></p>
                </div>
                <div class="toprow__right">
                    <button class="unstyled-button" aria-label="Today" (click)="today()">{{ 'Heute' | translate }}</button>
                </div>
                <div class="overlay-dropdown sm-full-selector calendar__selection" [ngClass]="{'is-hidden': selectionHidden}" hcOverlayHide (hideOverlay)="hideOverlay()">
                    <div class="sm-full-selector-list calendar-selection">
                        <div class="calendar-selection__year">
                            <button class="unstyled-button icon-arrow-left" aria-label="previous year" (click)="prevYear($event)"></button>
                            <p class="calendar-selection__text">{{selectionYear}}</p>
                            <button class="unstyled-button icon-arrow-right" aria-label="next year" (click)="nextYear($event)"></button>
                        </div>
                        <div class="calendar-selection__month">
                            <div *ngFor="let row of months; let i = index" class="month__row">
                                <div class="month__col month__col--pos-{{j}}" *ngFor="let month of row; let j = index">
                                    <button class="calendar-button"
                                    [ngClass]="{'is-selected': (month.month === temporarySelectionDate.format('MM')) && selectionYear == temporarySelectionDate.format('YYYY')}"
                                    (click)="selectMonth(month.month, selectionYear)">{{month['text']}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="calendar__weekdays">
                <div class="day-selection">
                    <button class="unstyled-button icon-arrow-left" aria-label="previous week" (click)="previousWeek()"></button>
                    <div class="day-selection__weekday" *ngFor="let day of this.weekdays;let i = index" [ngClass]="{'is-selected': day.isSame(selectedDate)}">
                        <button class="unstyled-button weekday-button qa-weekday-button-{{i}}"
                                aria-label="select date"
                                (click)="selectDate(day, i)"
                                [disabled]="calendarDayButtonsEnabled">{{day.format('dd DD')}}</button>
                    </div>
                    <button class="unstyled-button icon-arrow-right" aria-label="next week" (click)="nextWeek()"></button>
                </div>
            </div>
        </div>
    `
})
export class CalendarComponent {

    @Output() newWeekDaysSelected = new EventEmitter<string>()
    @Output() newDaySelected = new EventEmitter()
    @Input() weekdays: dayjs.Dayjs[]
    @Input() selectedDate: dayjs.Dayjs
    @Input() calendarDayButtonsEnabled = false

    public selectionYear: number
    public temporarySelectionDate: dayjs.Dayjs
    public selectionHidden = true
    public months: Object[][]

    constructor(private calendarService: CalendarService,
                private translateService: TranslateService) {

        this.temporarySelectionDate = this.calendarService.getToday();

        combineLatest([
            this.translateService.get("Januar"),
            this.translateService.get("Februar"),
            this.translateService.get("März"),
            this.translateService.get("April"),
            this.translateService.get("Mai"),
            this.translateService.get("Juni"),
            this.translateService.get("Juli"),
            this.translateService.get("August"),
            this.translateService.get("September"),
            this.translateService.get("Oktober"),
            this.translateService.get("November"),
            this.translateService.get("Dezember")
        ]).subscribe(translations => {
         this.months = [
            [
                {text: translations[0], label: "January", month: "01"},
                {text: translations[1], label: "February", month: "02"},
                {text: translations[2], label: "March", month: "03"}
            ],
            [
                {text: translations[3], label: "April", month: "04"},
                {text: translations[4], label: "May", month: "05"},
                {text: translations[5], label: "June", month: "06"}
            ],
            [
                {text: translations[6], label: "July", month: "07"},
                {text: translations[7], label: "August", month: "08"},
                {text: translations[8], label: "September", month: "09"}
            ],
            [
                {text: translations[9], label: "October", month: "10"},
                {text: translations[10], label: "November", month: "11"},
                {text: translations[11], label: "December", month: "12"}
            ]
        ];
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this.weekdays.length > 0) {
            this.selectionYear = parseInt(this.weekdays[0].format('YYYY'), 10);
            this.temporarySelectionDate = this.weekdays[0];
        }
    }

    public hideOverlay() {
        this.selectionHidden = true;
    }

    public toggleSelection() {
        this.selectionHidden = !this.selectionHidden;
    }

    public previousWeek() {
        this.newWeekDaysSelected.emit(this.calendarService.getPreviousWeek(this.weekdays[0])[0].format(DATE_FORMAT));
    }

    public nextWeek() {
        this.newWeekDaysSelected.emit(this.calendarService.getNextWeek(this.weekdays[0])[0].format(DATE_FORMAT));
    }

    public today() {
        this.selectionHidden = true;
        this.newWeekDaysSelected.emit(this.calendarService.getToday().format(DATE_FORMAT));
    }

    public prevYear(event:MouseEvent) {
        this.preventDefault(event);
        this.selectionYear--;
    }

    public nextYear(event:MouseEvent) {
        this.preventDefault(event);
        this.selectionYear++;
    }

    private preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    private selectMonth(month) {
        this.selectionHidden = true;
        this.newWeekDaysSelected.emit(this.calendarService.getFirstMondayOfMonth(month, this.selectionYear).format(DATE_FORMAT));
    }

    private selectDate(date: dayjs.Dayjs, index: number) {
        this.newDaySelected.emit({ date: date.format(DATE_FORMAT), index: index });
    }
}
