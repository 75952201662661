import {map, take, filter} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import * as fromAuth from "../reducers";

@Injectable()
export class HasPremiumGuard  {

    constructor(private store: Store<fromAuth.AppState>,
                private router: Router) {
    }

    hasPremium() {
        return this.store.select(fromAuth.getAuthUser).pipe(
            filter(x => x.id > -1),
            take(1),
            map(user => {
                if (user.premium) {
                    return true;
                }
                return this.router.parseUrl('/premium');
            }),)
    }

    canActivate(route: ActivatedRouteSnapshot) {
        return this.hasPremium();
    }
}
