/**
 * Created by alex on 05/10/16.
 */
import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'hc-verify-email',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
            <a class="qa-verify-button" (click)="generateVerificationCode($event)" translate>Email erneut versenden</a>
  `,
})
export class VerifyEmailComponent {
    @Output() submitForm = new EventEmitter<string>();
    @Output() submitGenerateVerificationCode = new EventEmitter();

    private form: FormGroup;

    constructor(private formBuilder: FormBuilder) {
        this.form = formBuilder.group({
           code: ['', Validators.required]
        });
    }

    public formSubmit(): void {
        this.submitForm.emit(this.form.controls['code'].value);
    }

    public generateVerificationCode(event): void {
        this.submitGenerateVerificationCode.emit();
    }
}
