import * as dayjs from "dayjs";

const DATE_FORMAT = 'YYYY-MM-DD';

export class Weekday {

    constructor(private date:dayjs.Dayjs) { }

    public weekdayAsString():string {
        return this.date.format(DATE_FORMAT)
    }

    public weekdayAsDayjs():dayjs.Dayjs {
        return this.date;
    }

    static dayjsAsString(date: dayjs.Dayjs) {
        return date.format(DATE_FORMAT);
    }

    static dayjsFromWeekdayString(date:string) {
        return dayjs(date, DATE_FORMAT);
    }
}
