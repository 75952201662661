
import {take, filter} from 'rxjs/operators';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as fromGroups from '../../reducers';
import { DeleteGroupUserAction } from '../../actions/groups';

@Component({
    selector: 'hc-groupmembers-context',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="dropdown open">
            <ul class="dropdown-menu context-dropdown sm-full-selector-list" aria-labelledby="dropdownMenu1">
                <li class="context-dropdown__item sm-full-selector-list__item">
                    <button class="unstyled-button" (click)="edit()" data-cy="btn-group-edit">{{"Gruppe bearbeiten" | translate}}</button>
                </li>
                <li class="context-dropdown__item sm-full-selector-list__item">
                    <button class="unstyled-button" (click)="delete()">{{"Gruppe löschen" | translate}}</button>
                </li>
            </ul>
        </div>
    `,
})
export class GroupMembersContextComponent {

    constructor(
        private store: Store<fromGroups.AppState>,
        private router: Router
    ) { }

    public delete(): void {
        this.store.select(fromGroups.getSelectedGroupId).pipe(
            filter(groupId => groupId > 0),
            take(1),)
            .subscribe(id => this.store.dispatch(new DeleteGroupUserAction(id)));
    }

    public edit(): void {
        this.store.select(fromGroups.getSelectedGroupId).pipe(
            filter(groupId => groupId > 0),
            take(1),)
            .subscribe(id => this.router.navigate(['/admin/groups', id]));
    }
}


