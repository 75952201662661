import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subject, Subscription} from 'rxjs';

import {ProfileUser} from '../models';
import {Store} from '@ngrx/store';
import * as reducers from '../reducers';
import bothFieldsPresent from '../validators/bothFieldsPresent';
import {formChangesStream} from "../shared/util";
import {MIN_PASSWORD_LENGTH} from '../app.constants';
import {StrongPasswordValidator} from '../validators/strongPassword';


@Component({
    selector: 'hc-profile-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <form class="form--action" [formGroup]="form" (submit)="formSubmit()">
            <hc-form-actions
                [submitText]="'Speichern' | translate"
                [disableForm]="disableForm$ | async"
                (cancel)="cancel.emit()"></hc-form-actions>
            <ng-content></ng-content>
            <div class="form-section form-section--no-border form-section--no-padding">
                <hc-form-field [errors]="errors" [fieldName]="'firstName'" [labelText]="'Vorname'">
                    <input class="qa-first-name" type="text" formControlName="firstName" data-cy="firstName"
                           [readonly]="user?.edulog"/>
                </hc-form-field>
                <hc-form-field [errors]="errors" [fieldName]="'lastName'" [labelText]="'Nachname'">
                    <input class="qa-last-name" type="text" formControlName="lastName" [readonly]="user?.edulog"/>
                </hc-form-field>
                <hc-form-field [errors]="errors" [fieldName]="'email'" [labelText]="'Email'">
                    <input class="qa-email" type="text" formControlName="email" readonly/>
                </hc-form-field>
                <hc-form-field [errors]="errors" [fieldName]="'username'" [labelText]="'Benutzername'">
                    <input class="qa-username" type="text" formControlName="username" readonly/>
                </hc-form-field>
                <div class="form-group">
                    <label for="language" translate>Sprache</label>
                    <div *ngIf="submitted && formErrors['language']">
                        <i class="sdx-icon icon-026-exclamation-mark-circle form__input_error" aria-hidden="true"></i>
                        <span
                            class="form__input_error text-size-small qa-language-error">{{ formErrors.language }}</span>
                    </div>
                    <div class="radio">
                        <input type="radio"
                               id="language-de"
                               class="qa-language-de"
                               ngControl="language"
                               value="de"
                               formControlName="language">
                        <label
                            class="qa-de-text language-label"
                            for="language-de"
                            data-cy="lang-de"
                            translate>Deutsch</label>
                    </div>
                    <div class="radio">
                        <input type="radio"
                               id="language-fr"
                               class="qa-language-fr language-label"
                               ngControl="language"
                               value="fr"
                               formControlName="language">
                        <label
                            class="qa-fr-text language-label"
                            for="language-fr"
                            data-cy="lang-fr" translate>Französisch</label>
                    </div>
                    <div class="radio">
                        <input type="radio"
                               id="language-it"
                               class="qa-language-it"
                               ngControl="language"
                               value="it"
                               formControlName="language">
                        <label
                            class="qa-it-text language-label"
                            for="language-it"
                            data-cy="lang-it"
                            translate>Italienisch</label>
                    </div>
                </div>
                <div class="form-section">
                    <h2 class="heading-2" translate>Passwort ändern</h2>
                    <div *ngFor="let error of errors['passwords']">
                        <i class="sdx-icon icon-026-exclamation-mark-circle form__input_error" aria-hidden="true"></i>
                        <span class="form__input_error text-size-small qa-passwords-error"
                              data-cy="passwordErrors">{{ error }}</span>
                    </div>
                    <hc-form-field [errors]="errors" [fieldName]="'oldPassword'"
                                   [labelText]="'Altes Passwort' | translate">
                        <input class="qa-old-password" type="text" formControlName="oldPassword" autocomplete="off"
                               data-cy="oldPassword"/>
                    </hc-form-field>
                    <hc-form-field [errors]="errors" [fieldName]="'newPassword'"
                                   [labelText]="'Neues Passwort' | translate">
                        <input class="qa-new-password" type="text" formControlName="newPassword" autocomplete="off"
                               data-cy="newPassword"/>
                    </hc-form-field>
                    <p class="text-gray" translate>Das Passwort muss mindestens 10 Zeichen lang sein und
                        Grossbuchstaben, Zahlen und Sonderzeichen beinhalten</p>
                </div>
            </div>
        </form>
    `,

})
export class UserProfileFormComponent {

    @Output() submitForm = new EventEmitter()
    @Output() formErrors = new EventEmitter()
    @Output() cancel = new EventEmitter()
    @Output() formChanges = new EventEmitter<boolean>()
    @Input() user: ProfileUser = null
    @Input() errors: Object[] = []

    private allowUserUpdate = true
    public form: FormGroup
    private formSubscription: Subscription
    private formChangesOverwrite$ = new Subject<boolean>()
    public disableForm$: Observable<boolean>
    public submitted = true

    constructor(store: Store<reducers.AppState>,
                formBuilder: FormBuilder) {
        this.form = formBuilder.group({
            firstName: new FormControl({value: '', disabled: true}, Validators.required),
            lastName: new FormControl({value: '', disabled: true}, Validators.required),
            username: new FormControl({value: '', disabled: false}, Validators.required),
            email: [''],
            language: ['de', Validators.required],
            newPassword: ['', [Validators.minLength(MIN_PASSWORD_LENGTH), StrongPasswordValidator.strong]],
            oldPassword: ['']
        }, {validator: bothFieldsPresent('oldPassword', 'newPassword')});

        this.formSubscription = formChangesStream(this.form, this.formChangesOverwrite$)
            .subscribe(formHasChanged => this.formChanges.emit(formHasChanged));

        this.disableForm$ = store.select(reducers.isUsersLoading);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.user && this.allowUserUpdate && changes['user']) {
            let patchObj = {
                firstName: this.user.first_name,
                lastName: this.user.last_name,
                username: this.user.username,
                email: this.user.email,
                language: this.user.language,
                oldPassword: '',
                newPassword: ''
            };
            this.form.patchValue(patchObj);
            this.allowUserUpdate = false;

            if (ProfileUser.isAdmin(this.user)) {
                this.form.controls['firstName'].enable();
                this.form.controls['lastName'].enable();
                this.form.controls['email'].setValidators(Validators.required);
            }
        }

        // reset form changes on form init
        this.formChangesOverwrite$.next(false);
    }

    private userDataToPy(user: Object): Object {
        return {
            first_name: user['firstName'],
            last_name: user['lastName'],
            email: user['email'],
            username: user['username'],
            language: user['language'],
            old_password: user['oldPassword'],
            new_password: user['newPassword'],
        };
    }

    private prepareUserSubmit(form: FormControl): Object {
        let user = Object.assign({}, this.form.value, {
            id: this.user.id
        });

        // for students and parents name values are empty (disabled)
        if (!ProfileUser.isAdmin(this.user)) {
            user['firstName'] = this.user.first_name;
            user['lastName'] = this.user.last_name;
        }
        return user;
    }

    public formSubmit(): void {

        this.submitted = true;
        let user = this.prepareUserSubmit(this.form.value);

        if (this.form.valid) {

            // todo: move elsewhere
            // json to py
            let userData = this.userDataToPy(user)

            // form was submitted, allow to navigate away
            this.formChangesOverwrite$.next(false);
            this.submitForm.emit(userData);
            this.allowUserUpdate = true;
        } else {
            let formErrors = {};

            // get form field errors

            for (let control in this.form.controls) {
                if (this.form.controls.hasOwnProperty(control)) {
                    formErrors[control] = this.errorsForControl(this.form.controls[control]);
                }
            }

            // get form errors
            if (this.form.errors && this.form.errors['bothFieldsPresent']) {
                formErrors['passwords'] = ['Beide Passwortfelder müssen ausgefüllt werden'];
            }
            this.formErrors.emit(formErrors);
        }
    }

    private errorsForControl(control): Object[] {

        let errors = [];

        for (let error in control.errors) {
            if (control.errors.hasOwnProperty(error)) {
                let errorKey = '';

                switch (error) {
                    case 'required':
                        errorKey = 'Dieses Feld muss ausgefüllt werden';
                        break;
                    case 'minlength':
                        errorKey = 'Das neue Passwort muss mindestens 10 Zeichen lang sein';
                        break;
                    case 'strong':
                        errorKey = 'Das Passwort muss Grossbuchstaben, Zahlen und Sonderzeichen beinhalten';
                        break;
                }
                errors.push(errorKey);
            }
        }
        return errors;
    }

    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
