import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import * as reducers from "../reducers";
import {FileAttachment} from "../attachment/attachment.model";
import {
  DeleteDocumentsAction,
  GetMyDocumentsAction,
  UpdateDocumentOrderFilterAction,
  UpdateDocumentPageAction,
  UpdateDocumentQueryAction
} from "../actions/klassenbuch";
import {UiSetTitleAction} from "../actions/ui";
import {combineLatest, Observable} from "rxjs";
import {take} from "rxjs/operators";
import {DocumentOrderValues} from "./document-order-filter";


@Component({
  selector: 'hc-my-documents',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="container main__container my-documents__container">
      <div class="flex flex-col md:flex-row justify-between md:items-center mt-[20px]">
        <input (keyup)="queryUpdate($event.target.value)"
               data-cy="document-search"
               type="text" placeholder="{{'Datei suchen' | translate }}"
               class="mb-0 border-gray-600 border rounded-[4px] py-[8px] px-[16px] w-full bg-[url('../icons/search.svg')] bg-no-repeat bg-[top_50%_right_10px] md:max-w-[511px]">
        <hc-document-filter (filterUpdate)="filterUpdate($event)"></hc-document-filter>
      </div>
      <ol>
        <li *ngFor="let document of documents$ | async"
            class="border-t last:border-b border-t-gray-300 border-b-gray-300">
          <hc-document [document]="document"
                       (delete)="deleteDocument($event)"
          ></hc-document>
        </li>
      </ol>
      <hc-my-documents-footer
        *ngIf="(documentCount$ | async) > 10"
        [count]="documentCount$ | async"
        [nextUrl]="nextDocumentUrl$ | async"
        [previousUrl]="previousDocumentUrl$ | async"
        [page]="page$ | async"
        (pageUpdate)="pageUpdate($event)"
      ></hc-my-documents-footer>
    </div>
  `
})
export class MyDocumentsComponent implements OnInit, OnDestroy {
  public documents$: Observable<FileAttachment[]>
  protected documentCount$: Observable<number>
  protected nextDocumentUrl$: Observable<string>
  protected previousDocumentUrl$: Observable<string>
  protected page$: Observable<number>
  protected documentFilter$: Observable<DocumentOrderValues>
  private alive = true;

  constructor(private store: Store<reducers.AppState>) {
    this.documents$ = this.store.select(reducers.getDocuments)
    this.documentCount$ = this.store.select(reducers.getDocumentCount)
    this.nextDocumentUrl$ = this.store.select(reducers.getNextDocumentUrl)
    this.previousDocumentUrl$ = this.store.select(reducers.getPreviousDocumentUrl)
    this.page$ = this.store.select(reducers.getDocumentPage)
    this.documentFilter$ = this.store.select(reducers.getDocumentOrderFilter)
  }


  ngOnInit() {
    combineLatest([
      this.page$,
      this.documentFilter$
    ])
      .pipe(
        take(1)
      )
      .subscribe(([page, filter]) => this.store.dispatch(new GetMyDocumentsAction({page, orderBy: filter})));
    this.store.dispatch(new UiSetTitleAction('Dokumente'));

  }

  ngOnDestroy() {
    this.alive = false;
  }

  protected pageUpdate(page) {
    this.store.dispatch(new UpdateDocumentPageAction(page));
  }

  protected filterUpdate(sortBy) {
    this.store.dispatch(new UpdateDocumentOrderFilterAction(sortBy))
  }

  protected queryUpdate(query: string) {
    this.store.dispatch(new UpdateDocumentQueryAction(query))
  }

  protected deleteDocument(documentId: number) {
    this.store.dispatch(new DeleteDocumentsAction([documentId]))
  }

}
