import {AlertMessage} from './alerts.models';
import {AlertActions, AlertActionTypes} from './alerts.actions';

export interface AlertsState {
  alert: AlertMessage;
}

const initialAlertsState: AlertsState = {
  alert: null,
};

export function reducer(state = initialAlertsState, action: AlertActions): AlertsState {
  switch (action.type) {
    case AlertActionTypes.ADD_ALERT:
      return Object.assign({}, state, {
        alert: Object.assign({}, action.payload)
      });

    case AlertActionTypes.REMOVE_ALERT:
      return Object.assign({}, state, {
        alert: initialAlertsState.alert
      });

    default: {
      return state;
    }
  }
}

// getters for state members

export function getAlert(state: AlertsState) {
  return state.alert;
}
