import {Action, createAction, props} from '@ngrx/store';

import {SignupUser} from '../models';
import {type} from '../shared/util';

export const signup = createAction(
  '[Signup] Signup',
  props<SignupUser>()
);

export const signupPremium = createAction(
  '[Signup] Signup Premium',
  props<SignupUser>()
);

export const signupSuccess = createAction(
  '[Signup] Signup Success',
  props<Object>()
);

export const signupFailure = createAction(
  '[Signup] Signup Failure',
  props<Object>()
);

export const signupUpdateFormError = createAction(
  '[Signup] Signup Update Formerror',
  props<Object>()
);

export const SignupActionTypes = {
  SIGNUP_OPENID_SUCCESS: type("[Signup] Signup OpenId Success"),
  SIGNUP_OPENID_FAILURE: type("[Signup] Signup OpenId Failure"),
  SIGNUP_FAILURE: type("[Signup] Signup Failure"),
  SIGNUP_UPDATE_FORM_ERROR: type("[Signup] Signup Update Formerror"),
  SIGNUP_CLEAR_FORM_ERROR: type("[Signup] Signup Remove Formerror")
};


export class SignupFailAction implements Action {
  type = SignupActionTypes.SIGNUP_FAILURE;

  constructor(public payload: Object) {
  }
}

export class SignupOpenidSuccessAction implements Action {
  type = SignupActionTypes.SIGNUP_OPENID_SUCCESS;

  constructor(public payload: Object) {
  }
}

export class SignupOpenidFailAction implements Action {
  type = SignupActionTypes.SIGNUP_OPENID_FAILURE;

  constructor(public payload: Object) {
  }
}

export class SignupAddFormErrorAction implements Action {
  type = SignupActionTypes.SIGNUP_UPDATE_FORM_ERROR;

  constructor(public payload: Object) {
  }
}


export type AuthActions =
  SignupFailAction
  | SignupOpenidSuccessAction
  | SignupOpenidFailAction
  | SignupAddFormErrorAction

