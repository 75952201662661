import { Component, Input, ChangeDetectionStrategy, ElementRef, SimpleChanges, OnChanges } from '@angular/core';


@Component({
    selector: 'hc-form-field',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="input-field" [ngClass]="{'invalid': invalid}">
            <label
                *ngIf="translateLabel"
                [ngClass]="['hc-label', labelClass]"
                htmlFor="{{fieldName}}"
                translate>{{labelText}}</label>
            <label
                *ngIf="!translateLabel"
                [ngClass]="['hc-label', labelClass]"
                htmlFor="{{fieldName}}">{{labelText}}</label>
            <ng-content></ng-content>
            <div class="messages" *ngIf="errors && errors[fieldName]">
                <div class="messages__message message" *ngFor="let error of errors[fieldName]">
                    <i class="sdx-icon icon-026-exclamation-mark-circle" aria-hidden="true"></i>
                    <span class="qa-{{fieldName}}-error" [attr.data-cy]="fieldName + '-error'">{{ error | translate }}</span>
                </div>
            </div>
        </div>
  `,

})
export class FormFieldsComponent implements OnChanges {

    @Input() errors: Object[];
    @Input() fieldName: string;
    @Input() labelText: string;
    @Input() labelClass = '';
    @Input() translateLabel = true;
    public invalid = false;

    constructor(private elRef: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        const inputElement = this.elRef.nativeElement.querySelector('input');
        if(changes['errors'] && changes['errors']['currentValue'] && changes['errors']['currentValue'][this.fieldName]
            && changes['errors']['currentValue'][this.fieldName].length !== 0) {
            inputElement.classList.add('invalid');
            this.invalid = true;
        } else {
            inputElement.classList.remove('invalid');
            this.invalid = false;
        }
    }
}
