import {Injectable} from '@angular/core';

const FROM_PREMIUM_KEY = 'fromPremium'

@Injectable()
export class StorageService {

  constructor() {
  }

  setFromPremium() {
    localStorage.setItem(FROM_PREMIUM_KEY, 'true');
  }

  getFromPremium() {
    return localStorage.getItem(FROM_PREMIUM_KEY) || false;
  }

  deleteFromPremium() {
    localStorage.removeItem(FROM_PREMIUM_KEY);
  }

}
