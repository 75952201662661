import { Component, Input } from '@angular/core';

@Component({
    selector: 'hc-terms',
    template: `
            <div class="checkbox">
                <ng-content></ng-content>
                <label for="terms" [innerHtml]="legal | translate" data-cy="signup-terms">
                </label>
            </div>
            <div class="messages" *ngIf="formErrors">
                <div class="messages__message message" *ngFor="let error of formErrors">
                    <i class="sdx-icon icon-026-exclamation-mark-circle" aria-hidden="true"></i>
                    <span data-cy="terms-error">{{ error | translate }}</span>
                </div>
            </div>
    `
})
export class SignupTermsComponent {
  @Input() formErrors: string[] = []
  @Input() legal = 'Ich akzeptiere die <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Nutzungsbedingungen.pdf\">Nutzungsbedingungen</a> und nehme Kenntnis von den <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Privacy_Policy.pdf\">Datenschutzbestimmungen</a> und den <a href=\"https://www.helloclass.ch/assets/documents/DE_HelloClass_Cookies.pdf\">Cookies-Bestimmungen</a>.';

  constructor() {
  }
}
