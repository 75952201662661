import {filter, map, take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {ActivatedRouteSnapshot, Router} from "@angular/router";

import * as fromAuth from "../reducers";


@Injectable()
export class ForcePasswordResetGuard {
  constructor(
    private store: Store<fromAuth.AppState>,
    private router: Router
  ) {
  }

  forcePasswordReset() {
    return this.store.select(fromAuth.getAuthUser).pipe(
      filter(x => x.id > -1),
      take(1),
      map(user => {
        if (user.forcePasswordReset) {
          return this.router.parseUrl('/passwordreset');
        } else {
          return true;
        }
      }),);
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.forcePasswordReset();
  }
}
