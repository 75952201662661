import {combineLatest} from 'rxjs';

import {filter, map, takeWhile} from 'rxjs/operators';

import {Component, ViewChild} from "@angular/core";
import {Store} from "@ngrx/store";

import {UiSetBackUrl, UiSetContextMenu, UiSetTitleAction} from "../actions/ui";
import {
    DeleteAccountAction,
    HideDeleteProfileModalAction,
    InviteFriendAction,
    LoadUserAction,
    ShowDeleteProfileModalAction,
    UpdateProfileAction,
    UpdateUserFormErrorsAction
} from "../actions/users";
import {RoutingHistoryService} from "../routing-history/routing-history.service";
import {ConfirmationModalComponent} from "../confirmation-modal/confirmation-modal";
import {
    AppState,
    canCreateRelationshipsForSelfInOwnGroups,
    getAuthUser,
    getShowDeleteProfileModal,
    getSubscriptionUntil,
    getUser,
    getUserFormErrors,
    hasPremium,
    isAdmin
} from "../reducers";

import {ProfileUser} from "../models";
import {ActivatedRoute, Router} from "@angular/router";
import {IS_APP} from "../app.constants";
import {RelationshipComponent} from "../relationship/relationship.component";
import {AddAlertAction, RemoveAlertAction} from "../alerts/alerts.actions";


@Component({
    selector: 'hc-profile',
    template: `
        <div class="container-fluid container-fluid--limit main__container" [ngClass]="{'isHidden': !(user$ | async)}">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="heading-1 profile-title" translate>Profil</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <hc-profile-form
                        class="profile"
                        [ngClass]="{'isHidden': !(user$ | async)}"
                        (submitForm)="updateUser($event)"
                        [user]="user$ | async"
                        [errors]="errors$ | async"
                        (formChanges)="handleFormChange($event)"
                        (formErrors)="formErrors($event)"
                        (cancel)="cancelUpdate()">
                        <hc-alert>
                        </hc-alert>
                    </hc-profile-form>
                </div>
            </div>
            <div class="form-section">
                <div class="row profile-row" *ngIf="!isApp" [ngClass]="{'profile-row--last': !isApp}">
                    <div class="col-md-12">
                        <hc-subscription-info [premium]="premium$ | async" [until$]="until$"
                                              [admin]="isAdmin$ | async"></hc-subscription-info>
                    </div>
                </div>
            </div>
            <div class="form-section" *ngIf="premium$ | async">
                <div class="row profile-row">
                    <div class="col-md-12">
                        <hc-relationships
                            #relationshipComponent
                            *ngIf="(loggedInUser$ | async) && (canCreateRelationships$ | async)"
                            [userId]="(loggedInUser$ | async).id"
                            [schoolId]="(loggedInUser$ | async).schoolId"
                            [canManage]="canCreateRelationships$ | async"
                        ></hc-relationships>
                    </div>
                </div>
            </div>
            <div class="form-section" id="edulog">
                <div class="row profile-row">
                    <div class="col-md-12">
                        <div *ngIf="(loggedInUser$ | async).edulog; else edulogContainer">
                            <hc-edulog-widget [buttonLink]="" [buttonText]="''">
                                <p translate>Dein Helloclass-Konto ist bereits mit Edulog verknüpft.</p>
                            </hc-edulog-widget>
                        </div>

                        <ng-template #edulogContainer>
                            <hc-edulog-widget [buttonLink]="edulogQuery" [buttonText]="'Mit Edulog verknüpfen'">
                                <p translate>Hast du bereits ein Edulog-Konto, welches du auch für Helloclass verwenden
                                    willst?</p>
                            </hc-edulog-widget>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isApp">
                <div class="col-md-12">
                    <div class="subscription-info">
                        <h3 class="h4 subscription-info__title" translate>Rechtliches</h3>
                        <div class="subscription-info__current ">
                            <a routerLink="/klassenbuch/legal" class="text-emph-blue " translate>Rechtliches</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <div *ngIf="!isApp && isAdmin$ | async" class="row vertical-margin vertical-margin-bottom">
                    <div class="col-md-12">
                        <button class="btn btn--red" (click)="confirmDelete()">{{'Konto löschen' | translate}}</button>
                    </div>
                </div>
                <div *ngIf="!(isAdmin$ | async)" class="row vertical-margin vertical-margin-bottom">
                    <div class="col-md-12">
                        <h3 class="h4" translate>Löschung des Kontos</h3>
                        <p translate>Wende dich an deinen Schuladministrator um dein Konto zu löschen.</p>
                    </div>
                </div>
            </div>
            <hc-spinner class="profile-spinner center" [show]="!(user$ | async)"></hc-spinner>
            <hc-confirmation-modal #deleteConfirmationModal
                                   [title]="'Konto löschen' | translate"
                                   [displayText]="'Willst du dein Konto wirklich löschen?' | translate"
                                   [confirmationText]="'Konto löschen' | translate"
                                   (confirm)="delete()"
                                   (cancel)="abortDelete()">
            </hc-confirmation-modal>
        </div>
    `
})
export class ProfilePage {

    @ViewChild('deleteConfirmationModal', {static: false}) public deleteConfirmationModal: ConfirmationModalComponent;
    @ViewChild('relationshipComponent', {static: false}) public relationshipComponent: RelationshipComponent;

    readonly user$ = this.store.select(getUser).pipe(filter(user => user && user.id !== -1));
    public loggedInUser$ = this.store.select(getAuthUser);
    private formHasChanged = false;
    private backUrl: string;
    public errors$ = this.store.select(getUserFormErrors);
    public isApp = IS_APP;
    public premium$ = this.store.select(hasPremium);
    public isAdmin$ = this.store.select(isAdmin);
    public canCreateRelationships$ = combineLatest([this.isAdmin$, this.store.select(canCreateRelationshipsForSelfInOwnGroups)])
        .pipe(
            map(data => data[0] || data[1])
        )
    public until$ = this.store.select(getSubscriptionUntil);
    private alive = true
    public edulogQuery = 'action=link'

    constructor(private store: Store<AppState>,
                private routingHistory: RoutingHistoryService,
                private router: Router,
                private route: ActivatedRoute
    ) {

        this.store.dispatch(new UiSetContextMenu(''))
        this.setPreviousRoute()
        this.edulogQuery += this.isApp ? '&app=true' : ''
    }

    private setPreviousRoute() {
        this.backUrl = this.routingHistory.getPreviousRoute();
        if (this.backUrl.indexOf('/premium') > -1) {
            this.backUrl = '/klassenbuch';
        }
        this.store.dispatch(new UiSetBackUrl(this.backUrl));
    }

    public updateUser(user: ProfileUser) {
        if (this.relationshipComponent) {
            this.relationshipComponent.save();
        }
        this.store.dispatch(new UpdateUserFormErrorsAction([]));
        this.store.dispatch(new UpdateProfileAction(user));
    }

    hasChanges() {
        return this.formHasChanged;
    }

    confirmDelete() {
        // Todo: make action for state (like other modal in this component)
        this.store.dispatch(new ShowDeleteProfileModalAction);
    }

    delete() {
        this.store.dispatch(new HideDeleteProfileModalAction());
        this.store.dispatch(new DeleteAccountAction());
    }

    abortDelete() {
        this.store.dispatch(new HideDeleteProfileModalAction());
    }

    public handleFormChange(change) {
        this.formHasChanged = change;
    }

    public formErrors(errors: Object[]) {
        this.store.dispatch(new UpdateUserFormErrorsAction(errors));
    }

    public cancelUpdate() {
        this.router.navigateByUrl(this.backUrl);
    }

    public inviteFriend(email: string) {
        this.store.dispatch(new InviteFriendAction(email));
    }

    ngOnInit() {

        this.loggedInUser$.pipe(
            takeWhile(() => this.alive))
            .subscribe((loggedInUser: Object) => {
                this.store.dispatch(new UiSetTitleAction('Profil'));
                this.store.dispatch(new LoadUserAction(loggedInUser['id']));
            });

        this.route.queryParams.pipe(
            takeWhile(() => this.alive)
        ).subscribe(params => {
            if (params['edulog-fail'] && params['edulog-fail'] === '0') {
                const message = 'Für die verwendete Email-Adresse existiert bereits ein Helloclass Benutzer.'
                this.store.dispatch(new AddAlertAction({message, type: 'fail'}));
            }
        })
    }

    ngAfterViewInit() {
        this.store.select(getShowDeleteProfileModal).pipe(
            takeWhile(() => this.alive))
            .subscribe(showModal => {
                showModal ? this.deleteConfirmationModal.show() : this.deleteConfirmationModal.hide();
            });
    }

    ngOnDestroy() {
        this.store.dispatch(new UpdateUserFormErrorsAction([]))
        this.store.dispatch(new RemoveAlertAction())
        this.alive = false
    }

}
