import {Component, ChangeDetectionStrategy} from "@angular/core";

@Component({
    selector: 'hc-edulog-no-user-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="container-fluid container-fluid--limit signup-page">
            <div class="lead-in">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="heading-2 qa-title" translate>Leider konnte kein Benutzer mit der von dir angegebenen Email-Adresse gefunden werden.</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-md-offset-1">
                    <p class="text-size-form gutter-mt text-center"><a href="#" translate>Zurück zur Startseite</a> </p>
                </div>
            </div>
        </div>
    `
})
export class EdulogNoUserPage {

    constructor() {
    }

}
