const DOMAIN = 'stage-app2.helloclass.ch'
const PROTOCOL = 'https'
const BACKEND_URL = `${PROTOCOL}://${DOMAIN}`
const EDULOG_BASE = `${BACKEND_URL}/edulog`
const EDULOG_SSO = `${EDULOG_BASE}/?sso&`

export const environment = {
  production: true,
  app: false,
  ENV: 'stage',
  API_URL: `${BACKEND_URL}/api`,
  BASE_URL: `https://stage-app2.helloclass.ch`,
  JWT_REFRESH_DELTA: 4 * 60,
  JWT_CHECK_INTERVAL: 2 * 60,
  BACKEND_URL,
  DOMAIN,
  ENABLE_SENTRY: true,
  NOTIFICATION_INTERVAL: 10,
  API_VERSION: 1,
  IS_APP: false,
  APP_VERSION: 2,
  ALLOW_TESTUSER: false,
  I18N_PATH: `/assets/i18n/`,
  USE_MATOMO: false,
  EDULOG_SSO
}
