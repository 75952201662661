
import {timer as observableTimer,  Subscription } from 'rxjs';

import {timeInterval} from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from "../reducers";
import { GetNotifications } from './notifications.actions';
import { NUM_NOTIFICATIONS, POLLING_INTERVAL } from './notifications.const';


@Injectable()
export class NotificationsIntervalService {

    private subscription: Subscription;

    constructor(
        private store: Store<AppState>,
        private ngZone: NgZone) {
    }

    private unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    startInterval() {
        this.unsubscribe();
        // without runOutside it breaks the tests https://github.com/angular/protractor/blob/master/docs/timeouts.md#waiting-for-angular
        this.ngZone.runOutsideAngular(() => {
        this.subscription = observableTimer(0, POLLING_INTERVAL * 1000).pipe(timeInterval())
                                .subscribe(() => {
                                    this.ngZone.run(() => {
                                        this.store.dispatch(new GetNotifications(NUM_NOTIFICATIONS));
                                    });
                                });
        });
    }

    stopInterval() {
        this.unsubscribe();
    }

    getNotifications() {
        this.store.dispatch(new GetNotifications(NUM_NOTIFICATIONS));
    }
}
