import {from, of as observableOf} from 'rxjs';

import {filter, map, switchMap, take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {ActivatedRouteSnapshot, Router} from "@angular/router";

import * as fromAuth from "../reducers";
import {getIsTokenLoaded} from "../reducers";
import {Cookies} from "../token/cookies";
import {AuthTokenLoadLocal} from "../actions/authentication";
import {TokenService} from "../token/token";
import {IS_APP} from "../app.constants";


@Injectable()
export class UserUnauthorized {
  constructor(
    private store: Store<fromAuth.AppState>,
    private cookieService: Cookies,
    private router: Router,
    private tokenService: TokenService
  ) {
  }

  verifyCookie() {
    return from(this.cookieService.isLoggedIn()).pipe(
      switchMap(isloggedIn => {
        if (isloggedIn) {
          return from(this.router.navigate(['/klassenbuch'])).pipe(
            map(() => false) // Ensure the observable resolves to false after navigation
          );
        } else {
          return observableOf(true);
        }
      }),);
  }

  verifyToken() {
    this.store.dispatch(new AuthTokenLoadLocal());
    return this.store.select(getIsTokenLoaded).pipe(
      filter(isLoaded => isLoaded),
      take(1),
      switchMap(() => {
        if (this.tokenService.accessTokenIsValid()) {
          this.router.navigate(['/klassenbuch']);
          return observableOf(false);
        } else {
          return observableOf(true);
        }
      }),);
  }


  canActivate(_route: ActivatedRouteSnapshot) {
    console.log("UserUnauthorized guard activated.")
    if (IS_APP) {
      return this.verifyToken();
    }
    return this.verifyCookie();
  }
}
