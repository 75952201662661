import { CustomAction } from '../actions/CustomAction';
import { School } from '../models/school';
import { type } from '../shared/util';

export const SchoolActionTypes = {
  UPDATE_SCHOOL: type("[School] Update"),
  UPDATE_SCHOOL_FAIL: type("[School] Add failed"),
  UPDATE_SCHOOL_SUCCESS: type("[School] Add success")
};


export class UpdateSchool implements CustomAction {
    type = SchoolActionTypes.UPDATE_SCHOOL;

    constructor(public payload: School) {}
}

export class UpdateSchoolFail implements CustomAction {
    type = SchoolActionTypes.UPDATE_SCHOOL_FAIL;

    constructor(public payload: string) {}
}

export class UpdateSchoolSuccess implements CustomAction {
    type = SchoolActionTypes.UPDATE_SCHOOL_SUCCESS;

    constructor(public payload: School) {}
}

export type AuthActions =
    UpdateSchool
    | UpdateSchoolFail
    | UpdateSchoolSuccess

