
import {of, combineLatest } from 'rxjs';

import {switchMap, filter} from 'rxjs/operators';

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import * as fromAuth from "../reducers";

@Injectable()
export class LoggeinAndAdminOnlyGuard  {

    constructor(private store: Store<fromAuth.AppState>,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
        return combineLatest([
            this.store.select(fromAuth.isAdmin),
            this.store.select(fromAuth.getAuthAnonymous),
            this.store.select(fromAuth.getAuthUser)
        ]).pipe(
        filter(values => values[2].id !== -1), // make sure the user is logged in
        switchMap(values => {
            if (values[1] || !values[1] && values[0]) { // if not logged redirect takes care
                return of(true);
            } else {
                this.router.navigate([ '/' ]);
                return of(false);
            }
        }),)
    }
}
