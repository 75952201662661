import {combineLatest, EMPTY, Observable, Subject} from "rxjs";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {catchError, distinctUntilChanged, map, startWith, takeWhile, withLatestFrom} from 'rxjs/operators';
import {DOCUMENT} from "@angular/common";
import {Store} from "@ngrx/store";
import {PageScrollService} from 'ngx-page-scroll-core';
import * as fromKlassenbuch from "../reducers";
import {getAuthUser, isAdmin} from "../reducers";
import {
  DateSelectedAction,
  PrerenderEventAction,
  PublishEventAction,
  ShowNextEventAction,
  ShowPreviousEventAction
} from "../actions/klassenbuch";
import {ViewEvent} from '../models';
import {UiHideMenuAction, UiSetBackUrl, UiSetContextMenu, UiSetTitleAction} from '../actions/ui';
import {CalendarService} from '../services/calendar';
import {TranslateService} from "@ngx-translate/core";
import {MediaQueryService} from '../services/media-query';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {IS_APP} from "../app.constants";

import * as dayjs from 'dayjs';
import {OfflineService} from '../app/offline.service';
import {DisableShowEventOnboardingSettings} from '../actions/authentication';
import {InfopointHttpService} from "../infopoint/infopoint.http.service";

const SLOTS_LABELS = ["08:00", "09:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00", "17:00"];
const DAYS_OF_WEEK = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const DATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'hc-klassenbuch',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="container-fluid main__container main__container--klassenbuch">
      <hc-calendar #calendar
                   class="calendar-wrapper"
                   [ngClass]="{'calendar-wrapper--sticky': isCalendarSticky, 'calendar-wrapper--margin': (navigationShown$ | async)}"
                   [weekdays]="calendarDays$ | async"
                   [selectedDate]="selectedDate$ | async"
                   [calendarDayButtonsEnabled]="isMediumScreen$ | async"
                   (newWeekDaysSelected)="updateDate($event)"
                   (newDaySelected)="updateDay($event)"></hc-calendar>
      <div *ngIf="(displayInfopointMessage$ |async)">
        <hc-infopoint
          [infopointMessage]="(infopointMessage$ |async)"
        ></hc-infopoint>
      </div>
      <hc-spinner class="center" [show]="weeksLoading$ | async"></hc-spinner>
      <div class="row classbook" #classbook [ngStyle]="{'margin-top': calendarTopMargin$ | async }"
           [ngClass]="{'isHidden': weeksLoading$ | async}" data-cy="classbook">
        <div class="col-md-2 col-md-2--wide col-xs-12 weekday" id="{{ weekdays[i] }}"
             *ngFor="let calendarDay of calendarDays$ | async; let i = index">
          <div class="weekday__heading row">
            <div class="col-xs-10 vcenter weekday__title-wrapper">
              <h2 class="text-size-small weekday__title">{{calendarDay | dateFormatFilter:'dd, DD.MM.YYYY'}}</h2>
            </div><!--
                    -->
            <div *ngIf="canEditEvents" class="col-xs-2 vcenter weekday__add-wrapper">
              <button class="weekday__add-event icon-plus unstyled-button"
                      data-cy="create-event-day"
                      (click)="addEvent(calendarDay.format('YYYY-MM-DD'), 0)">
              </button>
            </div>
          </div>
          <div class="row">
            <div class="dayslots col-xs-12">
              <div *ngFor="let index of (showIndexes$ | async)[i]; let position = index"
                   class="dayslots__grid dayslot__grid--{{position}}"
                   (click)="addEvent(calendarDay.format('YYYY-MM-DD'), position)"></div>
              <div class="row">
                <hc-event *ngFor="let event of (days$ | async)[i]; let j = index"
                          [event]="event"
                          [isHidden]="(showIndexes$ | async)[i][event.start]['displayId'] !== event.id"
                          [numEvents]="(showIndexes$ | async)[i][event.start]['numEvents']"
                          [dayIndex]="i"
                          [displayIndex]="(showIndexes$ | async)[i][event.start]['displayIndex']+ 1"
                          [canEditEvents]="canEditEvents"
                          (showPrevEvent)="showPrevEvent($event)"
                          (showNextEvent)="showNextEvent($event)"
                          (publish)="publish($event)"
                          (eventClicked)="showDetailEvent(event)">
                </hc-event>
              </div><!--row-->
            </div><!--dayslots-->
          </div>
        </div>
      </div>
      <div class="bottom-actions" [ngClass]="{'isHidden': weeksLoading$ | async}">
        <button (click)="goToTop()" class="bottom-actions__btn btn">{{ 'Nach oben' | translate }}</button>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row footer-wrapper">
        <div class="col-md-10 col-md-offset-1">
          <hc-footer></hc-footer>
        </div>
      </div>
    </div>
    <div class="onboarding-hint" *ngIf="showEventOnboarding$ | async">
      <hc-hint [title]="'Erfass deine erste Aufgabe'"
               [body]="hintText$ | async"
               [bubbleStyle]="isMediumScreen$ | async"
               (closeHint)="showEventOnboardingHintClosed()"></hc-hint>
    </div>
  `,
})
export class KlassenbuchComponent implements OnInit, OnDestroy {

  @Output() createTaskBlock = new EventEmitter()
  @ViewChild('calendar', {read: ElementRef, static: false}) calendarRef: ElementRef
  @ViewChild('classbook', {read: ElementRef, static: false}) classbookRef: ElementRef

  public calendarDays$: Observable<Object[]>
  private days$: Observable<Object[]>
  private showIndexes$: Observable<Object[][]>
  public selectedDate$: Observable<Object>
  public isMediumScreen$: Observable<boolean>
  public calendarTopMargin$: Observable<string>
  public navigationShown$: Observable<boolean>
  public weeksLoading$: Observable<boolean>
  public showEventOnboarding$: Observable<boolean>
  public hintText$: Observable<string>
  public scrollData$ = new Subject<Object>()
  private topMostVisibleWeekdayIndex$ = new Subject<number>()
  public isCalendarSticky = false
  private canEditEvents: boolean
  public slotLabels = SLOTS_LABELS
  public weekdays = DAYS_OF_WEEK
  private isScrolling = false
  private alive = true
  public infopointMessage$ = this.infopointHttpService.fetchMessage().pipe(
    startWith({}),
    catchError((err) => {
      console.warn(err)
      return EMPTY
    })
  )

  public displayInfopointMessage$ = combineLatest([
    this.store.select(isAdmin),
    this.store.select(getAuthUser),
    this.infopointMessage$])
    .pipe(
      map(data => {
        if (!data[2].hasOwnProperty('title')) {
          return false
        }

        return data[0] || data[1].role === 'Lehrperson'
      })
    )

  constructor(private router: Router,
              private store: Store<fromKlassenbuch.AppState>,
              private calendar: CalendarService,
              private pageScrollService: PageScrollService,
              private translateService: TranslateService,
              private mediaQueryService: MediaQueryService,
              private activatedRoute: ActivatedRoute,
              private element: ElementRef,
              @Inject(DOCUMENT) private document: any,
              private offlineService: OfflineService,
              private infopointHttpService: InfopointHttpService) {

    this.calendarDays$ = store.select(fromKlassenbuch.calendarDaysAsMoment);
    this.days$ = store.select(fromKlassenbuch.getDays);
    this.showIndexes$ = store.select(fromKlassenbuch.getshowIndexes);
    this.selectedDate$ = store.select(fromKlassenbuch.getSelectedDate);
    this.navigationShown$ = store.select(fromKlassenbuch.getNavigationShown);
    store.select(fromKlassenbuch.canEditEvents).pipe(
      takeWhile(() => this.alive))
      .subscribe(admin => this.canEditEvents = admin);
    store.select(fromKlassenbuch.getGroups).pipe(
      takeWhile(() => this.alive))
      .subscribe((groups) => {
        const context = groups.length > 0 ? 'GroupSelectionContext' : '';
        this.store.dispatch(new UiSetContextMenu(context));
      });

    this.weeksLoading$ = store.select(fromKlassenbuch.getLoadingWeeks);

    this.store.dispatch(new UiSetBackUrl(''));
    this.store.dispatch(new UiSetTitleAction('Klassenbuch'))

    // handle mediaqueries for calendar
    this.isMediumScreen$ = this.mediaQueryService.minWidth(this.mediaQueryService.navMinWidth);

    // hint
    this.hintText$ = this.isMediumScreen$.pipe(
      map(isMediumScreen => {
        return isMediumScreen ? 'Klick auf den Kalender' : 'Klick auf das «Plus» neben dem Kalender'
      })
    )

    this.calendarTopMargin$ = this.calendarTopMargin();

    this.automaticDaySelectionObs().pipe(
      takeWhile(() => this.alive))
      .subscribe(() => null);

    this.showEventOnboarding$ = store.select(fromKlassenbuch.showEventOnboarding);
  }

  private automaticDaySelectionObs(): Observable<null> {
    return this.topMostVisibleWeekdayIndex$.pipe(
      startWith(0),
      distinctUntilChanged(),
      withLatestFrom(this.calendarDays$),
      map(([topMostIndex, calendarDays]: [number, dayjs.Dayjs[]]) => {
        this.store.dispatch(new DateSelectedAction({date: `${calendarDays[topMostIndex]}`, sameWeek: true}));
        return null;
      }),);
  }

  private calendarTopMargin(): Observable<string> {
    // handle sticky behavior
    // todo: how can this.isSticky be replaced with a observable?
    return this.scrollData$.pipe(
      withLatestFrom(this.isMediumScreen$),
      map(([scrollData, isMediumScreen]: [Object, boolean]) => {
        const classbookStyle = window.getComputedStyle(scrollData['classbookElement']);

        let calendarMargin = classbookStyle.getPropertyValue('margin-top');

        if (isMediumScreen) {
          return calendarMargin;
        }

        if (scrollData['calendarY'] < 1 && !this.isCalendarSticky) {
          this.isCalendarSticky = true;
          calendarMargin = `${scrollData['calendarHeight']}px`;
          this.store.dispatch(new UiHideMenuAction());
        } else if (!scrollData['isNavigationOpen'] && this.isCalendarSticky && scrollData['headerBottomY'] > 0) {
          this.isCalendarSticky = false;
          calendarMargin = '0';
        } else if (this.isCalendarSticky && scrollData['isNavigationOpen'] && scrollData['classbookElement'].getBoundingClientRect().top === scrollData['calendarHeight']) {
          this.isCalendarSticky = false;
          calendarMargin = '0';
        }
        return calendarMargin;
      }),);
  }

  public publish(event: Object) {
    this.store.dispatch(new PublishEventAction(event));
  }

  public updateDay(event: { date: string, index: number }) {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: `#${this.weekdays[event.index]}`
    });
    this.store.dispatch(new DateSelectedAction({date: event.date, sameWeek: true}));
    this.isScrolling = true;
    setTimeout(() => this.isScrolling = false, 1000);
  }

  public updateDate(date: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {'date': date}
    };
    this.router.navigate(['/klassenbuch'], navigationExtras);
  }

  public showPrevEvent(coords: { dayIndex: number, startIndex: number }) {
    this.store.dispatch(new ShowPreviousEventAction({x: coords.dayIndex, y: coords.startIndex}));
  }

  public showNextEvent(coords: { dayIndex: number, startIndex: number }) {
    this.store.dispatch(new ShowNextEventAction({x: coords.dayIndex, y: coords.startIndex}));
  }

  private addEvent(calendarDay: string, startIndex: number) {
    if (this.canEditEvents) {
      this.store.dispatch(new DisableShowEventOnboardingSettings());
      this.offlineService.executeActionOnlyWhenOnlineRequired(true,
        () => this.router.navigate(['/klassenbuch/events/add'],
          {queryParams: {'date': calendarDay, 'startIndex': startIndex}}));
    }
  }

  public showDetailEvent(event: ViewEvent): void {
    this.router.navigate(['/klassenbuch/events', event.id]);
    this.store.dispatch(new PrerenderEventAction(event));
  }

  public goToTop() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '#main'
    });
  }

  public showEventOnboardingHintClosed() {
    this.store.dispatch(new DisableShowEventOnboardingSettings());
  }

  ngOnDestroy() {
    // this.calendarDaysSub.unsubscribe();
    this.store.dispatch(new UiSetContextMenu(''));
    this.store.dispatch(new UiHideMenuAction());
    this.store.dispatch(new UiSetTitleAction(''));
    this.alive = false;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
      takeWhile(() => this.alive))
      .subscribe((param: any) => {
        const date = param['date'];
        if (dayjs(date, DATE_FORMAT, true).isValid()) {
          const day = dayjs(date, DATE_FORMAT);
          const monday = this.calendar.getPrecedingMondayForDate(day);
          this.store.dispatch(new DateSelectedAction({date: dayjs(monday).format(DATE_FORMAT), sameWeek: false}));
        } else {
          const monday = this.calendar.getPrecedingMondayForDate(this.calendar.getToday());
          this.store.dispatch(new DateSelectedAction({date: dayjs(monday).format(DATE_FORMAT), sameWeek: false}));
        }
      });
  }

  @HostListener("window:scroll", [])
  onWindowScroll(): void {

    if (IS_APP) {
      return
    }

    const classbookElement = this.classbookRef.nativeElement;
    const calendarElement = this.calendarRef.nativeElement;
    const calendarY = calendarElement.offsetTop - window.scrollY;
    const calendarHeight = calendarElement.getBoundingClientRect().height;
    const calendarBottmY = calendarElement.getBoundingClientRect().top + calendarHeight;
    const headerElement = this.element.nativeElement.parentElement.querySelector('.header');
    const headerRect = headerElement.getBoundingClientRect();
    const headerBottomY = headerRect.top + headerRect.height;
    const isNavigationOpen = headerElement.className.indexOf('is-open') > -1;
    const weekDayElements = classbookElement.querySelectorAll('.weekday');

    this.scrollData$.next({
      calendarY,
      headerBottomY,
      isNavigationOpen,
      calendarHeight,
      classbookElement
    })

    // don't update dates when we're scrolling down automatically
    if (this.isScrolling) {
      return;
    }

    let topMostVisibleWeekdayIndex = 0;

    for (let i = 0; i < weekDayElements.length; i++) {
      const weekdayHeight = weekDayElements[i].getBoundingClientRect().top + weekDayElements[i].getBoundingClientRect().height;
      // first visible weekday
      if (weekdayHeight - calendarBottmY > -1 && i < 5) {
        topMostVisibleWeekdayIndex = i;
        break;
      }

      if (i === 4) {
        topMostVisibleWeekdayIndex = 4;
      }
    }
    this.topMostVisibleWeekdayIndex$.next(topMostVisibleWeekdayIndex);
  }

}
