import {ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges} from '@angular/core';

import {ComponentStore} from "@ngrx/component-store";
import {FORTYPE, Relationship, RelationshipUser} from "./relationship.models";
import {map, take} from "rxjs/operators";
import {RelationshipStore} from "./relationship.store";
import {AbsenceUser} from "../absences/absences.models";
import {combineLatest} from "rxjs";

@Component({
    selector: 'hc-relationship-manage',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div class="relationship-manage">
        <div>
            <ul class="unstyled-list">
                <li *ngFor="let relationship of (relationships$ | async)"
                    class="relationshipmanage-item">
                    <hc-relationship-selection
                        [users]="selectableUsers$ | async"
                        [userId]="relationship.cared_for.id"
                        (userSelected)="updateRelationship($event, relationship.index)"
                        class="relationshipmanage-item__content"></hc-relationship-selection>
                    <button class="relationshipmanage-item__delete unstyled-button"
                            data-cy="remove-relationship"
                            (click)="removeRelationship(relationship.id, relationship.index)">
                        <span class="icon-bin icon-size edit-actions__icon"></span>
                    </button>
                </li>
            </ul>
            <button class="unstyled-button relationship-manage__add"
                    data-cy="add-relationship-field"
                    (click)="addRelationship()" translate>Weitere Person verknüpfen</button>
        </div>
    </div>
    `,
    providers: [ComponentStore, RelationshipStore]
})
export class RelationshipManageComponent implements OnInit {

    @Input() userId: number;
    @Input() schoolId: number;
    @Input() selectedType: FORTYPE;  // type of the user that is selected from the list
    @Input() adminMode = false;
    @Input() groups: number[] = [];

    readonly selectableUsers$ = this.relationshipStore.users$;

    readonly relationships$ = combineLatest([
        this.relationshipStore.displayRelationships,
        this.selectableUsers$,
        this.relationshipStore.selectedType$,
        this.relationshipStore.userType$
        ]).pipe(
        map((data: [Relationship[], AbsenceUser[], FORTYPE, FORTYPE]) => {
            return data[0]
                .map((relationship, i) => Object.assign(relationship, {index: i}))
                .filter(relationship => {

                if (relationship.id === -1) { // is empty relationship
                    return true;
                }

                return data[1].filter(user => user.id === relationship[data[2]].id).length > 0 && // user is among selectable
                    (relationship[data[3]].id === this.userId || relationship[data[3]].id === -1); //
                })
        })
    );

    constructor(private readonly relationshipStore: RelationshipStore) {
    }

    public addRelationship () {
        this.relationshipStore.addRelationship(this.userId)
    }

    public removeRelationship (id, index: number) {
        this.relationshipStore.removeRelationship({
            id,
            index,
            userId: this.userId
        });
    }

    protected updateRelationship(selectedUser: RelationshipUser, index: number) {

        combineLatest([
            this.relationshipStore.selectedType$,
            this.relationshipStore.userType$
        ]).pipe(
            take(1),
        ).subscribe(data => {
            const currentUserSelector = data[1];
            const selctedUserSelector = data[0];

            // we need just the ids as user info is kept in selection component and api only cares about ids
            const relationshipUserData = {
                [currentUserSelector]: {
                    id: this.userId,
                    first_name: '',
                    last_name: ''
                },
                [selctedUserSelector]: selectedUser
            };
            this.relationshipStore.updateRelationship({
                relationship: relationshipUserData,
                index
            })
        })
    }

    public saveRelationships () {
        this.relationshipStore.saveRelationships({
            schoolId: this.schoolId,
            userId: this.userId
        });
    }

    ngOnInit () {
        if (!this.adminMode) {
            this.relationshipStore.fetchData(this.schoolId, this.userId);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.adminMode && changes['groups'] && changes['groups'].currentValue.length > 0) {
            this.relationshipStore.fetchAdminData(this.schoolId, this.userId, this.groups)
        }

        if (changes['selectedType']) {
            this.relationshipStore.updateSelectedType(changes['selectedType'].currentValue);
        }
    }

}
