import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root',
})
export class HelloclassPermissionsService {

    public permissions = [
        {
            code: 'can_edit_events',
            text: 'Aufgaben erfassen, bearbeiten & löschen'
        },
        {
            code: 'can_edit_own_comments',
            text: 'Eigene Kommentare erfassen & löschen'
        },
        {
            code: 'can_delete_comments',
            text: 'Alle Kommentare löschen'
        },
        {
            code: 'can_see_private_comments',
            text: 'Kann private Kommentare sehen'
        },
        {
            code: 'can_admin_school',
            text: 'Schuladministration'
        },
        {
            code: 'can_create_relationships_for_self',
            text: 'Kann Verknüpfungen für sich in eigenen Gruppen erstellen'
        },
        {
            code: 'can_manage_absences_for_related_persons',
            text: 'Kann Absenzen für verknüpfte Personen erstellen'
        },
        {
            code: 'can_manage_absences_for_persons_in_own_groups',
            text: 'Kann Absenzen für Personen in eigenen Gruppen erstellen'
        }
    ];

    constructor() {
    }

    public isPermissionReadonly (permissionCode: string, readOnlyPermissions: string[]): boolean {
        return readOnlyPermissions.findIndex(readonly => readonly === permissionCode) > -1;
    }

    public canAlwaysEditAbsence (permissionsCodes: string[]): boolean {
        const canEditPermissions = ['can_manage_absences_for_persons_in_own_groups', 'can_admin_school']
        return this.hasPermission(permissionsCodes, canEditPermissions);
    }

    public canAdminAbsences (permissionsCodes: string[]): boolean {
        const canAdminAbsencesPermissions = ['can_manage_absences_for_related_persons',
            'can_manage_absences_for_persons_in_own_groups', 'can_admin_school']
        return this.hasPermission(permissionsCodes, canAdminAbsencesPermissions);
    }

    public canManageAbsenceForRelated (permissionsCodes: string[]): boolean {
        const canManageAbsenceForRelatedPermissions = ['can_manage_absences_for_related_persons'];
        return this.hasPermission(permissionsCodes, canManageAbsenceForRelatedPermissions);
    }

    public canCreateRelationshipForSelf (permissionsCodes: string[]): boolean {
        const canManageAbsenceForRelatedPermissions = ['can_create_relationships_for_self'];
        return this.hasPermission(permissionsCodes, canManageAbsenceForRelatedPermissions);
    }

    public canAdminSchool (permissionsCodes: string[]): boolean {
        const canManageSchoolPermission = ['can_admin_school'];
        return this.hasPermission(permissionsCodes, canManageSchoolPermission);
    }

    private hasPermission (userPermissionsCodes, requiredPermissionsCodes: string[]): boolean {
        return userPermissionsCodes.filter(permissionCode => requiredPermissionsCodes.indexOf(permissionCode) > -1).length > 0;
    }


}
