import {TranslateLoader} from "@ngx-translate/core";
import {from, Observable} from "rxjs";

export class LocalLoader implements TranslateLoader {
    private deviceReady = false;

    constructor(public prefix: string = "www/assets/i18n/",
                public suffix: string = ".json"
    ) {
    }

    public getTranslation(lang: string): Observable<object> {
        const deviceReadyPromise = new Promise((resolve, reject) => {
            if (!this.deviceReady) {
                document.addEventListener("deviceready",
                    event => {
                        this.deviceReady = true;
                        resolve(event);
                    },
                    false
                );
            } else {
                resolve(null);
            }
        });

        const fileContentPromise = deviceReadyPromise.then(() => {
            const filePath = `${cordova.file.applicationDirectory}${this.prefix}${lang}${this.suffix}`;
            return this.getFile(filePath);
        });

        return from(fileContentPromise);
    }

    private getFile(path: string): Promise<Object> {
        return new Promise((resolve, reject) => {
            window["resolveLocalFileSystemURL"](
                path,
                this.readFile(resolve),
                this.fail(reject)
            );
        });
    }

    private readFile(resolve) {
        return fileEntry => {
            const that = this;
            fileEntry.file(function (file) {
                var reader = new FileReader();
                reader.onloadend = () => resolve(that.buffer2Json(reader.result));
                reader.readAsArrayBuffer(file);
            });
        };
    }

    private fail(reject) {
        return e => {
            console.error(e);
        };
    }

    // https://stackoverflow.com/questions/6965107/converting-between-strings-and-arraybuffers/11058858
    private buffer2Json(buf) {
        var enc = new TextDecoder("utf-8");
        return JSON.parse(enc.decode(buf));
    }
}

