
import {Component, ChangeDetectionStrategy} from "@angular/core";

@Component({
    selector: 'hc-simple-header-layout',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="page">
            <div class="page__header">
                <hc-simple-header [showBack]="false"></hc-simple-header>
            </div>
            <div class="main">
                <router-outlet></router-outlet>
            </div>

        </div>
    `
})
export class SimpleHeaderLayout {

    constructor() {

    }

}
