import {FormGroup} from '@angular/forms';

export default function bothFieldsPresent(oldPassword: string, newPassword: string) {
    return (group: FormGroup): { [key: string]: any } | null => {
        let oldPasswordField = group.controls[oldPassword];
        let newPasswordField = group.controls[newPassword];

        if ((oldPasswordField.value !== '' && newPasswordField.value === '') ||
            (oldPasswordField.value === '' && newPasswordField.value !== '')) {
            return {
                bothFieldsPresent: true
            };
        }

        return null
    }
}
