export type DocumentOrderValues = "created" | "-created" | "file_name" | "-file_name";

export interface DocumentOrderFilterType {
  title: string;
  key: DocumentOrderValues;
}

export class DocumentOrderFilter {
  static OLDEST_FIRST: DocumentOrderFilterType = {
    title: "Älteste zuerst",
    key: "created"
  };
  static NEWEST_FIRST: DocumentOrderFilterType = {
    title: "Neueste zuerst",
    key: "-created"
  };
  static A_Z: DocumentOrderFilterType = {
    title: "A - Z",
    key: "file_name"
  };
  static Z_A: DocumentOrderFilterType = {
    title: "Z - A",
    key: "-file_name"
  };

  static FILTERS = [
    DocumentOrderFilter.OLDEST_FIRST,
    DocumentOrderFilter.NEWEST_FIRST,
    DocumentOrderFilter.A_Z,
    DocumentOrderFilter.Z_A,
  ]
}
