
import {filter} from 'rxjs/operators';
import {Component, ViewContainerRef, ChangeDetectorRef, ApplicationRef, OnDestroy} from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "./reducers";
import {AnalyticsService, LocalizationService} from "./services";
import { TranslateService } from "@ngx-translate/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";

import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime'
import * as isoWeek from 'dayjs/plugin/isoWeek'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'

import {StatusBarService} from './app/statusbar.service';
import {OfflineService} from './app/offline.service';

import { RemoveAlertAction } from "./alerts/alerts.actions";
import { UiHideNotifications} from "./actions/ui";
import { SplashScreenService } from "./app/splashscreen.service";
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale, frLocale, itLocale } from 'ngx-bootstrap/locale';
import {SchemeService} from "./app/scheme.service";


@Component({
    selector: 'helloclass',
    template: `
        <router-outlet></router-outlet>
    `,
    styleUrls: []
})
export class App implements OnDestroy {

    private viewContainerRef: ViewContainerRef;
    private routerSubscription: Subscription;

    constructor(private store: Store<AppState>,
                translate: TranslateService,
                viewContainerRef: ViewContainerRef,
                cd: ChangeDetectorRef,
                ar: ApplicationRef,
                router: Router,
                offlineService: OfflineService,
                analyticsService: AnalyticsService,
                localizationService: LocalizationService,
                statusbarService: StatusBarService,
                splashScreenService: SplashScreenService,
                schemeService: SchemeService) {

        // dismiss notifications on route changes
        this.routerSubscription = router.events.pipe(
            filter(event => event instanceof NavigationEnd))
            .subscribe((event: Event) => {
                this.store.dispatch(new RemoveAlertAction());
                this.store.dispatch(new UiHideNotifications())
            });

        // https://github.com/angular/angular/issues/6446#issuecomment-173459525
        this.viewContainerRef = viewContainerRef

        // init i18n
        dayjs.extend(relativeTime)
        dayjs.extend(isoWeek)

        defineLocale('de', deLocale)
        defineLocale('it', itLocale)
        defineLocale('fr', frLocale)

        translate.setDefaultLang('de') // this language will be used as a fallback when a translation isn't found in the current language
        // configure google or Adobe analytics
        analyticsService.setupAnalytics()

        statusbarService.initStatusBar()
        localizationService.setLanguageForApp()
        offlineService.initOffline()
        splashScreenService.hideSplashScreen()
        schemeService.handleSchemeEntrypoint()
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
}
