/* Keyboard input keycode definitions */

export const KEY_TAB = 9
export const KEY_ENTER = 13
export const KEY_ESCAPE = 27

// Arrow keys
export const KEY_ARROW_UP = 38
export const KEY_ARROW_DOWN = 40
export const KEY_ARROW_LEFT = 37
export const KEY_ARROW_RIGHT = 39

// Page keys
export const KEY_PAGE_UP = 33
export const KEY_PAGE_DOWN = 34

// Numbers
export const KEY_NR_0 = 48
export const KEY_NR_1 = 49
export const KEY_NR_9 = 57

// helper functions
export let containsKey = (keycode: number, inputsKeys: number[]) => {
  let hasKey = false
  if (inputsKeys && inputsKeys.length > 0) {
    [].forEach.call(inputsKeys, (inputsKey: number) => {
      if (keycode === inputsKey) {
        hasKey = true
      }
    })
  }
  return hasKey
}

export let getKeyValue = (keycode: number): string => {
  if (keycode < 48 || keycode > 105) {
    return ""
  }
  return String.fromCharCode(96 <= keycode && keycode <= 105 ? keycode - 48 : keycode).toLowerCase()
}
