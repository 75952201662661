

import {map, take, filter} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Router, ActivatedRouteSnapshot } from "@angular/router";

import * as fromAuth from "../reducers";
import {HelloclassPermissionsService} from "../permissions/permissions.service";


@Injectable()
export class UserEmailNotVerifiedGuard  {
  constructor(
    private store: Store<fromAuth.AppState>,
    private router: Router,
    private permissionsService: HelloclassPermissionsService
  ) { }

  checkEmailVerification() {
    return this.store.select(fromAuth.getAuthUser).pipe(
        filter(x => x.id > -1),
        take(1),
        map(user => {
            if (!this.permissionsService.canAdminSchool(user.permissions) || user.isEmailVerified) {
                return this.router.parseUrl('/');
            } else {
                return true;
            }
        }),)
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.checkEmailVerification();
  }
}
