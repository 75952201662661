import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserIsLoggedInGuard} from '../guards';
import {RelationshipOnboardingComponent} from "./relationship.onboarding.component";
import {RelationshipOnboardingDoneComponent} from "./relationship.onboarding-done.component";


const routes: Routes = [
  {
    path: 'onboarding',
    canActivate: [UserIsLoggedInGuard],
    component: RelationshipOnboardingComponent
  },
  {
    path: 'onboarding-success',
    canActivate: [UserIsLoggedInGuard],
    component: RelationshipOnboardingDoneComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelationshipRoutingModule {
}
