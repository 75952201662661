import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[hcOverlayHide]'
})
export class OverlayHideDirective {

    @Output() hideOverlay = new EventEmitter<boolean>();

    constructor() { }

    @HostListener('click', [])
    onClick(): void {
        this.hideOverlay.emit(true);
    }

    @HostListener('window:keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (event.key === "Escape" || event.key === "Esc") {
            this.hideOverlay.emit(true);
        }
    }
}
