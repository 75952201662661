import { Injectable } from '@angular/core';


@Injectable()
export class FileService {

    constructor() {
    }

    // https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
    public openAsFile(data: string, type: string, fileName: string): void {
        const blob = new Blob([data], { type: type });
        const url = window.URL.createObjectURL(blob);
        const a: HTMLElement = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = 'none';
        a.setAttribute('href', url);
        a.setAttribute('download', `${fileName}.csv`);
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
