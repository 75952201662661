import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import Select from '../sdx/form/Select';

import {RelationshipUser} from "./relationship.models";
import {FormBuilder, FormGroup} from "@angular/forms";


@Component({
    selector: 'hc-relationship-selection',
    template: `
    <div class="relationship-selection">
        <div class="select relationship-selection__select">
             <div form="relationship" [formGroup]="form">
                <select data-cy="user-selection" formControlName="relationshipUser" data-init="auto" (change)="selectUser()" #userSelection>
                    <option *ngFor="let user of users" [value]="user.id"
                            [attr.selected]="form.controls['relationshipUser'].value === user.id">
                        {{ displayName(user) }}
                    </option>
                </select>
             </div>
        </div>
    </div>
    `
})
export class RelationshipSelectionComponent implements OnChanges {

    @ViewChild('userSelection', {static: false}) selectRef: ElementRef;

    @Input() users: RelationshipUser[];
    @Input() userId: number;
    @Input() placeholder = '-';
    @Output() userSelected = new EventEmitter<RelationshipUser>();

    public selectElement: Select = null;
    public form: FormGroup;

    constructor(private formBuilder: FormBuilder) {
        this.form = formBuilder.group({
           relationshipUser: [''],
        });
    }

    public displayName (user: RelationshipUser) {
        return `${user.first_name} ${user.last_name}`
    }

    public selectUser () {

        const selectedId = +this.selectRef.nativeElement.value;

        const selectedUser: RelationshipUser[] = this.users.filter(user => user.id === selectedId);

        if (selectedUser.length === 1) {
            const user = selectedUser[0];
            this.userSelected.emit(user);
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        // the sdx dropdown is a touchy beast. Hackish solution to make it happy
        setTimeout(() => {
            if (this.userId) {
                this.form.patchValue({
                    relationshipUser: this.userId
                })
            }

            if (this.selectElement) {
                this.selectElement.reload();
            } else {
                this.selectElement = new Select(this.selectRef.nativeElement);
            }

            if ((changes['userId'] && changes['userId'].currentValue === -1) || this.userId === -1) {
                    this.selectElement.setPlaceholder(this.placeholder);
            }

        }, 0);
    }

}
