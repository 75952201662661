import {Injectable, NgZone} from '@angular/core';
import { IS_APP } from "../app.constants";
import {Router} from "@angular/router";

@Injectable()
export class SchemeService {

    constructor(private router: Router, private ngZone: NgZone) {
    }

    handleSchemeEntrypoint() {
        if (IS_APP) {
            window['handleOpenURL'] = (url) => {
                const scheme = 'helloclass://'
                if (url.indexOf(scheme) === 0) {
                    const path = url.substring(scheme.length)
                    this.ngZone.run(() => {
                        setTimeout(() => this.router.navigateByUrl(path), 0)
                    })
                }
            }
        }
    }
}
