import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {HelloClassRequestsService} from './hc-requests.services';

import {TokenService} from '../token/token';
import {environment} from '../../environments/environment';
import {CsrfService} from "./csrf.service";
import {CsrfInterceptor} from "./CsrfInterceptor.service";
import {JWT_OPTIONS, JwtModule} from "@auth0/angular-jwt";

export function jwtOptionsFactory(tokenService) {
  return {
    headerName: "Authorization",
    authScheme: "Bearer ",
    tokenGetter: () => tokenService.getTokens().access,
    globalHeaders: [{'Content-Type': 'application/json'}],
    throwNoTokenError: false,
    allowedDomains: [environment.DOMAIN],
    disallowedRoutes: [
      /.+\/api\/api-token-auth\//,
      /.+api\/password\/forgot\/reset\/key.+/
    ]
  };
}


@NgModule({
  imports: [
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        useFactory: jwtOptionsFactory,
        provide: JWT_OPTIONS,
        deps: [TokenService]
      }
    })
  ],
  providers: [
    HelloClassRequestsService,
    TokenService,
    CsrfService,
    CsrfInterceptor
  ]
})
export class HcRequestsModule {
  constructor(@Optional() @SkipSelf() parentModule: HcRequestsModule) {
    if (parentModule) {
      throw new Error(
        'HcRequestsModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<HcRequestsModule> {
    return {
      ngModule: HcRequestsModule,
      providers: [
        //   { provide: AuthHttp, useFactory: getAuthHttp, deps: [HttpClient, TokenService] },
        HelloClassRequestsService, CsrfService, CsrfInterceptor
      ]
    };
  }
}
