
import { Injectable } from '@angular/core';

import {ABSENCE_TYPE, AbsenceUser, ApiAbsence} from "../absences/absences.models";
import {HelloclassPermissionsService} from "../permissions/permissions.service";
import {RelationshipUser} from "../relationship/relationship.models";

@Injectable({
  providedIn: 'root'
})
export class AbsenceService {

    constructor(private permissionsService: HelloclassPermissionsService) {
    }

    public isAbsenceReadonly (absence: ApiAbsence, permissions: string[]): boolean {
        if (this.permissionsService.canAlwaysEditAbsence(permissions)) {
            return false;
        }

        return !this.permissionsService.canManageAbsenceForRelated(permissions) || this.isDateInPast(absence) ||
            !this.isStateOpen(absence);
    }

    public isDateInPast (absence: ApiAbsence): boolean {
        const absenceDatetime = new Date(absence.date);
        const now = new Date();
        const absenceDate = this.datetimeToDate(absenceDatetime);
        const nowDate = this.datetimeToDate(now);

        return absenceDate < nowDate;
    }

    public datetimeToDate (date: Date): Date {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }

    public isStateOpen (absence: ApiAbsence): boolean {
        return absence.state === ABSENCE_TYPE.Open;
    }

    public userDisplayname (user: AbsenceUser|RelationshipUser): string {
        return `${user.first_name} ${user.last_name}`;
    }

}
