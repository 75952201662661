import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import {FileAttachment} from "./attachment.model";

@Component({
    selector: 'hc-attachment-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="row">
            <div class="col-md-12">
                <ul class="attachment-list unstyled-list" [ngClass]="{'attachment-list--text': textOnly}">
                    <li *ngFor="let attachment of uploads" class="attachment">
                        <div *ngIf="!textOnly" class="attachment__preview">
                            <a class="preview" href="{{ attachment.url }}" download>
                                <div class="preview-content--image event-image" *ngIf="attachment.isImage(); else documentImage">
                                    <img class="event-image__thumbnail" src="{{ attachment.thumbnail_url }}" alt>
                                </div>

                                <ng-template #documentImage>
                                    <div class="preview-content--document event-document event-document--{{backgroundColor}}">
                                        <span class="icon-document document-thumbnail"></span>
                                        <div class="event-document__meta">
                                            <p>{{ attachment.filename }}</p>
                                        </div>
                                    </div>
                                </ng-template>
                            </a>
                        </div>
                        <div *ngIf="textOnly" class="attachment__text attachment-text">
                            <p class="attachment-text__filename">{{ attachment.filename }}</p>
                        </div>
                        <div *ngIf="editable" class="attachment__action">
                            <button type="button" (click)="deleteUpload(attachment)" class="unstyled-button attachment-action">
                            <span class="icon-bin attachment-action__icon"></span></button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    `
})
export class AttachmentListComponent {

    @Input() editable = false;
    @Input() textOnly = false;
    @Input() uploads: FileAttachment[] = [];
    @Input() backgroundColor:string;
    @Output() removeUpload = new EventEmitter();

    constructor() {}

    deleteUpload(attachment: FileAttachment): void {
        this.removeUpload.emit(attachment.id);
    }
}
