import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {RelationshipHttpService} from './relationship.http.service';
import {combineLatest, Observable} from "rxjs";
import {map, switchMap, withLatestFrom} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {AppState} from "../reducers";
import {AddToastAction} from "../alerts/alerts.actions";
import {
    FORTYPE,
    GetRelationshipAdminParams,
    GetRelationshipParams,
    Relationship,
    RelationshipState,
    RelationshipUpdate,
    RelationshipUser
} from "./relationship.models";
import {AbsenceUser} from "../absences/absences.models";
import {relationshipUpdate} from "./relationship.actions";


@Injectable()
export class RelationshipStore extends ComponentStore<RelationshipState> {

    readonly relationships$ = this.select(state => state.relationships);
    readonly displayRelationships = this.select(state => state.displayRelationships);
    readonly users$ = this.select(state => state.users);
    readonly relationshipsToDelete$ = this.select(state => state.relationshipsToDelete);
    readonly selectedType$ = this.select(state => state.selectedType);
    readonly userType$ = this.select(state => this.getOtherType(state.selectedType));

    private readonly updateUsers = this.updater((state, users: RelationshipUser[]) => ({
        ...state,
        users,
    }));

    private readonly updateRelationships = this.updater((state, relationships: Relationship[]) => ({
        ...state,
        relationships: this.addInitialRelationship(relationships, state.selectedType),
        displayRelationships: this.addInitialRelationship(relationships, state.selectedType),
    }));

    readonly addRelationship = this.updater((state, userId: number) => {
        return{
        ...state,
            relationships: [...state.relationships, this.emtpyRelationship(userId, state.selectedType)],
            displayRelationships: [...state.displayRelationships, this.emtpyRelationship(userId, state.selectedType)],
        }
    });

    readonly addNonEmptyRelationship = this.updater((state, users: {userId, selectableUserId: number}) => {
        return{
        ...state,
            relationships: [...state.relationships, this.nonEmptyRelationship(users.userId, users.selectableUserId, state.selectedType)],
            displayRelationships: [...state.displayRelationships, this.nonEmptyRelationship(users.userId, users.selectableUserId, state.selectedType)],
        }
    });

    readonly removeRelationship = this.updater((state, updatedRelationship: any) => {
        return {
            ...state,
            relationships: this.removeAndAdd(state.relationships, updatedRelationship.index, updatedRelationship.userId, state.selectedType),
            displayRelationships: this.removeAndAdd(state.displayRelationships, updatedRelationship.index, updatedRelationship.userId, state.selectedType),
            relationshipsToDelete: updatedRelationship.id !== -1 ?
                [...state.relationshipsToDelete, updatedRelationship.id] : state.relationshipsToDelete
        }
    });

    readonly updateRelationship = this.updater((state, updatedRelationship: any) => {
        const relationship = Object.assign(
            {},
            state.relationships[updatedRelationship.index],
            {
                guardian: updatedRelationship.relationship.guardian,
                cared_for: updatedRelationship.relationship.cared_for,
                dirty: true
            });

        return {
            ...state,
            relationships: [
                ...state.relationships.slice(0, updatedRelationship.index),
                relationship,
                ...state.relationships.slice(updatedRelationship.index + 1)
            ]
        }
    });

    readonly updateSelectedType = this.updater((state, selectedType: FORTYPE) => ({
        ...state,
        selectedType
    }));

    readonly selectableUsers$ = this.select(this.relationships$, this.users$,
    (relationships, users) => {
        const userIdsWithRelationship = relationships.map(relationship => relationship.cared_for.id);
        return users.filter(user => userIdsWithRelationship.indexOf(user.id) === -1)
        },
    );

    readonly fetchUsers = this.effect((params$: Observable<GetRelationshipParams>) => {
        return params$.pipe(
            switchMap((paramsData: GetRelationshipParams) => this.httpService.getUsers(paramsData.schoolId, paramsData.userId)
                .pipe(
                    tapResponse(
                        (users) => this.updateUsers(users),
                        (error: HttpErrorResponse) => {
                            console.warn(error);
                            this.store.dispatch(new AddToastAction({ type: 'fail', message: 'Die Verknüpfungen konnten nicht geladen werden!'}));
                        },
                    )
                ))
        )
    });

    readonly fetchAdminUsers = this.effect((params$: Observable<GetRelationshipAdminParams>) => {
        return params$.pipe(
            switchMap((paramsData: GetRelationshipAdminParams) => this.httpService.getAdminUsers(paramsData.schoolId, paramsData.groups)
                .pipe( // todo: refactor this part
                    tapResponse(
                        (users) => this.updateUsers(users),
                        (error: HttpErrorResponse) => {
                            console.warn(error);
                            this.store.dispatch(new AddToastAction({ type: 'fail', message: 'Die Verknüpfungen konnten nicht geladen werden!'}));
                        },
                    )
                ))
        )
    });

    readonly fetchRelationships = this.effect((params$: Observable<GetRelationshipParams>) => {
        return params$.pipe(
            switchMap((paramsData: GetRelationshipParams) => this.httpService.getRelationships(paramsData.schoolId, paramsData.userId)
                .pipe(
                    tapResponse(
                        (relationships) => this.updateRelationships(relationships),
                        (error: HttpErrorResponse) => {
                            console.warn(error);
                            this.store.dispatch(new AddToastAction({ type: 'fail', message: 'Die Verknüpfungen konnten nicht geladen werden!'}));
                        },
                    )
                ))
        )
    });

    readonly saveRelationships = this.effect((params$: Observable<GetRelationshipParams>) => {
        return params$
            .pipe(
                withLatestFrom(
                    combineLatest([
                        this.relationships$,
                        this.relationshipsToDelete$,
                        this.relationships$,
                        this.users$,
                        this.selectedType$
                    ])
                ),
                map(data => [data[0], ...data[1]]),
                map((data: [GetRelationshipParams, Relationship[], number[], Relationship[], AbsenceUser[], FORTYPE] ) => ({
                    updatedRelationships: this.getUpdatedRelationships(data[1], data[4], data[5]),
                    newRelationships: this.getAddedRelationships(data[1], data[4], data[5]),
                    deletedRelationships: this.getRelationshipsToDelete(data[2], data[4], data[5], data[3]),
                    schoolId: data[0].schoolId,
                    userId: data[0].userId,

                })),
                map((data: RelationshipUpdate) => {
                    if (this.hasApiData(data)) {
                        this.store.dispatch(relationshipUpdate(data));
                    }
                    return null;
                })
                ,
            )
    });


    constructor(private readonly componentStore: ComponentStore<RelationshipState>,
                private httpService: RelationshipHttpService,
                private store: Store<AppState>) {
        super(
            {
                relationships: [],
                displayRelationships: [],
                users: [],
                selectedUsers: [],
                relationshipsToDelete: [],
                selectedType: FORTYPE.CaredFor
            }
        );
    }

    private getUpdatedRelationships (relationships: Relationship[], users: AbsenceUser[], selectedType: FORTYPE): Relationship[] {
        return this.onlyAllowSelectableUsers(relationships, users, selectedType)
            .filter(relationship => relationship.id !== -1 && relationship.dirty) // filter if no change
    }

    private getAddedRelationships (relationships: Relationship[], users: AbsenceUser[], selectedType: FORTYPE): Relationship[] {
        return this.onlyAllowSelectableUsers(relationships, users, selectedType)
            .filter(relationship => relationship.id === -1 && relationship[selectedType].id !== -1) // filter empty relationships
    }

    public onlyAllowSelectableUsers (relationships: Relationship[], users: AbsenceUser[], selectedType: FORTYPE): Relationship[] {
        return relationships
            .filter(relationship => users.filter(user => user.id === relationship[selectedType].id).length > 0); // filter if selected user is not in the available users
    }

    private getRelationshipsToDelete (relationshipIds: number[], users: AbsenceUser[], selectedType: FORTYPE, relationships: Relationship[]): number[] {
        // remove relationship if user it exists but user is not selectable
        const existingRelationshipToDeleteIds = relationships
            .filter(relationship => relationship.id > 0) // filter empty
            .filter(relationship => users.filter(user => user.id === relationship[selectedType].id).length === 0)
            .map(relationship => relationship.id);

        return [
            ...relationshipIds,
            ...existingRelationshipToDeleteIds
        ]
    }

    // if last relationship is removed, then add a empty one
    private removeAndAdd (relationships: Relationship[], index: number, userId: number, selectedType: FORTYPE): Relationship[] {
        return this.isLastRelationship(relationships, index) ?
            [this.emtpyRelationship(userId, selectedType)] : this.removeAtPosition(relationships, index);
    }

    private isLastRelationship (relationships: Relationship[], index): boolean {
        return relationships.length === 1 && index === 0;
    }

    private removeAtPosition(relationships: Relationship[], index: number): Relationship[] {
        return [
            ...relationships.slice(0, index),
            ...relationships.slice(index + 1)
        ];
    }

    private addInitialRelationship (relationships: Relationship[], selectedType: FORTYPE): Relationship[] {
        const viewRelationships = relationships.map(relationship => Object.assign({}, relationship, {dirty: false}));
        return viewRelationships.length === 0 ? [this.emtpyRelationship(-1, selectedType)] : viewRelationships;
    }

    private nonEmptyRelationship (userId, selectableUserId: number, selectableType: FORTYPE): Relationship {
        const otherType = this.getOtherType(selectableType);
        // only id is important
        return ({
            id: -1,
            dirty: false,
            [otherType]: {
                id: userId,
                first_name: '',
                last_name: ''
            },
            [selectableType]: {
                id: selectableUserId,
                first_name: '',
                last_name: ''
            }
        } as any)
    };

    private emtpyRelationship (userId= -1, selectableType: FORTYPE): Relationship {
        return this.nonEmptyRelationship(userId, -1, selectableType);
    };

    private hasApiData (data: RelationshipUpdate) {
        return data.deletedRelationships.length > 0 || data.updatedRelationships.length || data.newRelationships.length > 0;
    }

    public fetchData (schoolId, userId: number) {
        // todo make calls in store. But how?
        const paramData = {
            schoolId,
            userId
        }

        this.fetchUsers(paramData);
        this.fetchRelationships(paramData);
    }

    public fetchAdminData (schoolId, userId: number, groups: number[]) {
        // todo make calls in store. But how?
        const paramAdminData = {
            schoolId,
            groups
        }

        const paramData = {
            schoolId,
            userId
        }

        this.fetchAdminUsers(paramAdminData);
        this.fetchRelationships(paramData);
    }

    public getOtherType (type: FORTYPE): FORTYPE {
        return type === FORTYPE.CaredFor ? FORTYPE.Guardian : FORTYPE.CaredFor
    }

}
