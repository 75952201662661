// http://stackoverflow.com/questions/36325212/angular-2-dynamic-tabs-with-user-click-chosen-components/36325468#
import {Component, Compiler, ViewContainerRef, ViewChild, SimpleChanges,
        Input, ComponentRef, ComponentFactoryResolver} from '@angular/core';
import {GroupSelectionContextComponent} from './groupselection-context';
import {GroupMembersContextComponent} from "../admin/groups/group-members-context.component";


@Component({
  selector: 'dcl-wrapper',
  template: `<div #target></div>`
})
export class DclWrapper {
  @ViewChild('target', {read: ViewContainerRef, static: false}) target;
  @Input() type;

  cmpRef: ComponentRef<any>;
  private isViewInitialized: boolean = false;
  private contextMap = {
    'GroupSelectionContext': GroupSelectionContextComponent,
    'GroupMembersContext': GroupMembersContextComponent,
    '': null
  };

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private compiler: Compiler) {}

  updateComponent() {
    if (!this.isViewInitialized) {
      return;
    }

    if (this.cmpRef) {
      this.cmpRef.destroy();
    }

    const contextClass = this.contextMap[this.type]

    if (contextClass == null) {
        return;
    }

    let factory = this.componentFactoryResolver.resolveComponentFactory(contextClass);
    this.cmpRef = this.target.createComponent(factory);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateComponent();
  }

  ngAfterViewInit() {
    this.isViewInitialized = true;
    this.updateComponent();
  }

  ngOnDestroy() {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }
}
