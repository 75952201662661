import {
  AnalyticsService,
  CalendarService,
  HelloClassApiService,
  LocalizationService,
  MediaQueryService
} from './services';
import {
  AutoLoginGuard,
  EventChangesGuard,
  ForcePasswordResetGuard,
  FromPremiumGuard,
  HasNoPremiumGuard,
  HasPremiumGuard,
  LanguageSwitchGuard,
  LoggeinAndAdminOnlyGuard,
  LogOutUserGuard,
  PersonChangesGuard,
  RelationshipOnboardingGuard,
  SyncGuardHelper,
  UserEmailNotVerifiedGuard,
  UserEmailVerifiedGuard,
  UserHasTokenGuard,
  UserIsLoggedInGuard,
  UserUnauthorized
} from './guards';
import {EventResetResolve} from './event';
import {NotificationsIntervalService} from './notifications/notifications-interval.service';
import {OfflineStorageService} from './app/offlinestorage.service';
import {StatusBarService} from './app/statusbar.service';
import {PushService} from './app/push.service';
import {OfflineService} from './app/offline.service';
import {SplashScreenService} from './app/splashscreen.service';
import {FileService} from './services/file';
import {StorageService} from './services/localstorage';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {IeInterceptor} from './ieInterceptor';
import {LabelsLoadResolve} from './labels/labels.resolve';
import {SchemeService} from "./app/scheme.service";


// Application wide providers
export const APP_PROVIDERS = [

  // services
  HelloClassApiService,
  MediaQueryService,
  CalendarService,
  NotificationsIntervalService,
  OfflineService,
  PushService,
  AnalyticsService,
  LocalizationService,
  StatusBarService,
  OfflineStorageService,
  SplashScreenService,
  FileService,
  StorageService,
  SchemeService,

  // guards
  EventChangesGuard, AutoLoginGuard,
  ForcePasswordResetGuard, PersonChangesGuard, UserEmailNotVerifiedGuard,
  UserEmailVerifiedGuard, UserHasTokenGuard, LogOutUserGuard, UserUnauthorized, LanguageSwitchGuard,
  HasPremiumGuard, HasNoPremiumGuard, FromPremiumGuard, LoggeinAndAdminOnlyGuard,
  SyncGuardHelper, RelationshipOnboardingGuard, UserIsLoggedInGuard,

  // resolves
  EventResetResolve,
  LabelsLoadResolve,


  // IE Interceptor
  {
    provide: HTTP_INTERCEPTORS,
    useClass: IeInterceptor,
    multi: true,
  }
];
