import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from '@ngx-translate/core';
import { AlertsModule } from '../alerts/alerts.module';
import {RelationshipComponent} from "./relationship.component";
import {RelationshipHttpService} from "./relationship.http.service";
import {RelationshipSelectionComponent} from "./relationship.selection.component";
import {RelationshipReadonlyComponent} from "./relationship.readonly.component";
import {RelationshipManageComponent} from "./relationship.manage.component";
import {EffectsModule} from "@ngrx/effects";
import {RelationshipEffects} from "./relationship.effects";
import {RelationshipAdminComponent} from "./relationship.admin.component";
import {RelationshipOnboardingComponent} from "./relationship.onboarding.component";
import {RelationshipRoutingModule} from "./relationship.routing";
import {RelationshipOnboardingDoneComponent} from "./relationship.onboarding-done.component";
import {RelationshipOnboardingManageComponent} from "./relationship.onboarding-selection.component";

@NgModule(
  {
    imports: [
      TranslateModule,
      AlertsModule,
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RelationshipRoutingModule,
      EffectsModule.forFeature([
        RelationshipEffects
      ]),
    ],
    declarations: [
        RelationshipComponent,
        RelationshipSelectionComponent,
        RelationshipReadonlyComponent,
        RelationshipManageComponent,
        RelationshipAdminComponent,
        RelationshipOnboardingComponent,
        RelationshipOnboardingDoneComponent,
        RelationshipOnboardingManageComponent
    ],
    exports: [
        RelationshipComponent,
        RelationshipAdminComponent,
    ],
    providers: [RelationshipHttpService]
  })
export class RelationshipModule {}
