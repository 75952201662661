import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";

const MAX_PAGE = 10;

@Component({
  selector: 'hc-my-documents-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- todo: change tailwind values -->
    <div class="flex flex-col md:flex-row justify-between my-10 items-center">
      <p class="text-gray-700 leading-[40px] m-0">
        <span data-cy="pagination-progress">{{ 'documentProgress'|translate:{
          from: getMin(),
          to: getMax(),
          total: count
        } }}</span>
      </p>
      <div>
        <div class="has-text-centered">
          <!-- we only need the pagination-controls to get the pageChange event -->
          <!-- we use a dummy array to make sure the pagination-controls are rendered correctly -->
          <p *ngFor="let item of [] | paginate: {
                                                      itemsPerPage: maxPage,
                                                      currentPage: page,
                                                      totalItems: count }"
             class="invisible"
          ></p>
          <pagination-template #p="paginationApi"
                               (pageChange)="updatePage($event)">

            <div
              class="border border-gray-600 rounded-md flex justify-between items-center h-16 text-center">
              <div class="w-16 border-gray-600 border-r h-16 leading-[40px]"
                   [class.disabled]="p.isFirstPage()">
                <button
                  [disabled]="p.isFirstPage()"
                  [ngClass]="{'text-gray-600': p.isFirstPage()}"
                  class="unstyled-button icon-arrow-left text-black top-1"
                  (click)="p.previous()"
                  data-cy="pagination-previous"></button>
              </div>

              <div *ngFor="let page of p.pages"
                   class="w-16 h-16 border-r border-gray-600 leading-[40px]"
                   [ngClass]="{'bg-blue-400': p.getCurrent() === page.value}">
                <button
                  class="unstyled-button"
                  (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value"
                  attr.data-cy="pagination-button-{{page.value}}">
                  <span>{{ page.label }}</span>
                </button>
                <div *ngIf="p.getCurrent() === page.value">
                  <span class="text-white" data-cy="pagination-current">{{ page.label }}</span>
                </div>
              </div>

              <div class="w-16 h-16 leading-[40px]" [class.disabled]="p.isLastPage()">
                <button
                  [disabled]="p.isLastPage()"
                  class="unstyled-button icon-arrow-right text-black top-1"
                  [ngClass]="{'text-gray-600': p.isLastPage()}"
                  (click)="p.next()"
                  data-cy="pagination-next"></button>
              </div>
            </div>

          </pagination-template>
        </div>
      </div>
    </div>

  `
})
export class MyDocumentsFooter {
  @Input() count: number;
  @Input() page: number;
  @Input() nextUrl: string;
  @Input() previousUrl: string;
  @Output() pageUpdate = new EventEmitter<number>();

  protected min: number;
  protected max: number;
  protected readonly maxPage = MAX_PAGE;

  constructor() {
  }

  protected getMin() {
    return this.page * MAX_PAGE - MAX_PAGE + 1;
  }

  protected getMax() {
    const maxPage = Math.floor(this.count / MAX_PAGE) + 1;
    return this.page === maxPage ? this.count : this.page * MAX_PAGE;
  }

  public updatePage(page) {
    this.pageUpdate.emit(page);
  }
}
