import {take} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {Observable, Subscription} from "rxjs";

import * as reducer from "../reducers";
import {getLoginFormErrors} from "../reducers";
import {AddAlertAction} from "../alerts/alerts.actions";
import {LoginAction, LoginAddFormErrorAction} from "../actions/authentication";
import {Credentials} from "../models/user";
import {Meta, Title} from "@angular/platform-browser";
import {StorageService} from '../services';


@Component({
  selector: 'hc-auth-login-page',
  template: `
    <div class="login-page">
      <hc-simple-header></hc-simple-header>
      <div class="container-fluid container-fluid--limit login-page__container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div class="lead-in">
              <div class="row">
                <div class="col-md-12">
                  <hc-alert></hc-alert>
                  <div *ngIf="displayPremium; then premiumTemplate; else normalTemplate">
                  </div>
                  <ng-template #premiumTemplate>
                    <h1 class="heading-2"><span translate>Melde dich jetzt an</span>&nbsp;<span
                      class="qa-login-subheader-premium" translate>um Helloclass Premium zu verwenden</span></h1>
                  </ng-template>
                  <ng-template #normalTemplate>
                    <h1 class="heading-2" translate>Hier kannst du dich für Helloclass anmelden.</h1>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <hc-auth-login></hc-auth-login>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="login-footer">
        <div class="container-fluid">
          <div class="row footer-wrapper">
            <div class="col-md-10 col-md-offset-1">
              <hc-footer></hc-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class LoginPage implements OnInit, OnDestroy {
  anonymous$: Observable<any>;
  logginIn$: Observable<any>;
  public formErrors$: Observable<Object>;
  private queryParamsSubscription: Subscription;
  public displayDemoInfo = false;
  public displayPremium = false;

  constructor(private store: Store<reducer.AppState>,
              private serviceStorage: StorageService,
              private activatedRoute: ActivatedRoute,
              private title: Title,
              private meta: Meta) {
    /**
     * Selectors can be applied with the `let` operator, which passes the source
     * observable to the provided function. This allows us an expressive,
     * composable technique for creating view projections.
     *
     * More on `let`: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35#let
     * More on selectors: https://gist.github.com/btroncone/a6e4347326749f938510#extracting-selectors-for-reuse
     */
    this.anonymous$ = store.select(reducer.getAuthUser).pipe(take(1));
    this.logginIn$ = store.select(reducer.getAuthLogginIn);
    this.formErrors$ = store.select(getLoginFormErrors);

    this.activatedRoute.params
      .subscribe(params => {
        let message = params['message'];
        if (message && message !== '') {
          this.store.dispatch(new AddAlertAction({message: message, type: 'fail'}));
        }
      });
  }

  login(loginObj: Credentials) {
    /**
     * All state updates are handled through dispatched actions in 'smart'
     * components. This provides a clear, reproducible history of state
     * updates and user interaction through the life of our application.
     */
    this.store.dispatch(new LoginAction({username: loginObj.username, password: loginObj.password}));
  }

  formErrors(errors: Object) {
    this.store.dispatch(new LoginAddFormErrorAction(errors));
  }

  ngOnInit() {
    this.title.setTitle('Helloclass - Login');
    this.queryParamsSubscription = this.activatedRoute.queryParams
      .subscribe((param: any) => {
        this.displayDemoInfo = !!param['demo'];
        this.displayPremium = param['from'] && param['from'] === 'premium';
        if (this.displayPremium) {
          this.serviceStorage.setFromPremium();
        }
      });
    this.meta.updateTag({
      property: 'og:description',
      content: 'Helloclass Login'
    });
    this.meta.updateTag({
      property: 'og:title',
      content: `Helloclass - Login`
    });
    this.meta.addTag({
      name: 'description',
      content: 'Helloclass Login'
    })
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }
}
