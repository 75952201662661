import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { FormFieldsComponent } from './form-field';
import {FormActions} from "./form-actions";

@NgModule(
  {
    imports: [CommonModule, TranslateModule],
    declarations: [ FormFieldsComponent, FormActions],
    exports: [ FormFieldsComponent, FormActions]
  })
export class HelloclassFormModule {}
