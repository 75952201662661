import { NgModule } from '@angular/core';
import {DateFormatPipe} from "./dateformater.pipe";


@NgModule(
    {
      imports: [],
      declarations: [DateFormatPipe],
      providers: [DateFormatPipe],
      exports: [ DateFormatPipe ]
    })
  export class HcDateFormaterModule {}

