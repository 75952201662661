import {Component, OnDestroy} from '@angular/core';
import { AuthLoginComponent } from "../components/auth-login";
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as reducer from "../reducers";
import { Observable, combineLatest } from 'rxjs';
import { takeWhile, } from 'rxjs/operators';
import { OfflineService } from './offline.service';
import { TranslateService } from '@ngx-translate/core';
import {EDULOG_SSO} from "../app.constants";

@Component({
    selector: 'hc-auth-app-login',
    template: `
    <div class="login">
        <div *ngIf="formErrors?.general">
            <p class="form__input_error text-size-small qa-login-general-error">
            <i aria-hidden="true" class="sdx-icon icon-026-exclamation-mark-circle"></i> <span [innerHtml]="formErrors['general'] | translate"></span></p>
        </div>
        <form [formGroup]="form" (submit)="formSubmit()" class="login__form">
            <div class="form-group">
                <hc-form-field
                    [errors]="formErrors"
                    [fieldName]="'username'"
                    [labelText]="'Benutzername oder Email'">
                    <input class="qa-login-username"
                        type="text" name="username"
                        formControlName="username"
                        arial-label="username"
                        autofocus />
                </hc-form-field>
            </div>
            <div class="form-group">
                <hc-form-field [errors]="formErrors" [fieldName]="'password'"
                            [labelText]="'Passwort'">
                <input class="qa-login-password"
                    type="password"
                    name="password"
                    formControlName="password"
                    arial-label="password"
                    autocomplete="off" />
                </hc-form-field>
            </div>
            <hc-spinner class="login-spinner" [show]="isLogginIn$ | async"></hc-spinner>
            <button
                class="btn btn--blue btn--bigger qa-login-button btn--full-width login-form__button btn--innershadow"
                [ngClass]="{'isHidden': isLogginIn$ | async}"
                type="submit" translate>
                Anmelden
            </button>
        </form>
        <div class="login__reset-link">
            <p><a routerLink="/forgotpassword" class="a--no-highlight">{{"Passwort vergessen?" | translate}}</a></p>
        </div>
        <div>
            <hc-edulog-widget
                [buttonLink]="ssoLink"
                [buttonText]="'Mit Edulog anmelden'"
                [isApp]="true">
                <p translate>Willst du dich mit deinen Edulog Zugangsdaten anmelden?</p>
            </hc-edulog-widget>
        </div>
    </div>
    `
})
export class AuthLoginAppComponent extends AuthLoginComponent implements OnDestroy{

    public isOnline$: Observable<boolean>;
    public isLogginIn$: Observable<boolean>;
    private alive = false;
    public ssoLink = `${EDULOG_SSO}action=login&redirect=true&app=true`

    constructor(formBuilder: FormBuilder,
                store: Store<reducer.AppState>,
                offlineService: OfflineService,
                translateService: TranslateService) {

        super(formBuilder, store);
        this.isOnline$ = store.select(reducer.getAppOnline);

        combineLatest([
            this.isOnline$,
            translateService.get('Du kannst dich nur einloggen wenn du online bist'),
            translateService.get('Du bist offline!'),
            translateService.get('Weiter')
        ]).pipe(
            takeWhile(() => this.alive))
            .subscribe(data => {
                if (!data[0]) {
                    offlineService.displayOfflineWarning(data[1], data[2], data[3]);
                }
            }
        );

        this.isLogginIn$ = store.select(reducer.getAuthLogginIn);
    }

    ngOnDestroy() {
        this.alive = false;
    }

}
