
import {of as observableOf } from 'rxjs';

import {catchError, switchMap, filter, tap, take, map, withLatestFrom} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { EffectsWithRouter } from '../../effects/router-effect';
import { Store } from '@ngrx/store';
import { RoutingHistoryService } from '../../routing-history/routing-history.service';
import { AppState, getAuthUser } from "../../reducers";
import { UserData } from "../../models";
import { LabelActionTypes, GetLabelsSuccess, GetLabelsFail, UpdateLabelsSuccess, UpdateLabelsFail } from './labels.actions';
import { AddAlertAction, AddToastAction } from '../../alerts/alerts.actions';
import { TranslateService } from '@ngx-translate/core';
import { HelloClassApiService } from '../../services/helloclass';
import * as labels from './labels.actions';

@Injectable()
export class LabelEffects extends EffectsWithRouter {

    constructor(private actions$: Actions,
                private store: Store<AppState>,
                injector: Injector,
                private translateService: TranslateService,
                private helloclassApi: HelloClassApiService,
                private routingHistoryService: RoutingHistoryService) {

                super(injector);
    }

     getLabels$ = createEffect(() => this.actions$.pipe(
        ofType(LabelActionTypes.LABELS_GET_LABELS),
        switchMap(() => this.store.select(getAuthUser).pipe(filter((userData: UserData) => userData.id > -1),take(1),)),
            switchMap((userData: UserData) => this.helloclassApi.getLabels(userData.schoolId).pipe(
                map(data => new GetLabelsSuccess(data)),
                catchError(error => observableOf(new GetLabelsFail(error))),)
        ),));

     getLabelsFail$ = createEffect(() => this.actions$.pipe(
        ofType(LabelActionTypes.LABELS_GET_LABELS_FAIL),
        map(() => new AddAlertAction({message: 'Es ist ein Fehler aufgetreten!', type: 'fail' }))));

     updateLabels$ = createEffect(() => this.actions$.pipe(
        ofType<labels.UpdateLabels>(LabelActionTypes.LABELS_UPDATE_LABELS),
        withLatestFrom(this.store.select(getAuthUser).pipe(filter((userData: UserData) => userData.id > -1))),
        switchMap(values => this.helloclassApi.updateLabels(values[1].schoolId, values[0].payload).pipe(
                map(data => new UpdateLabelsSuccess(data)),
                catchError(error => observableOf(new UpdateLabelsFail(error))),)
        ),));

     updateLabelsSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(LabelActionTypes.LABELS_UPDATE_LABELS_SUCCESS),
        tap(() => {
            const backUrl = this.routingHistoryService.getPreviousRoute();
            this.getRouter().navigate([ backUrl]);
        }),
        map(() => new AddToastAction({message: 'Die Daten wurden gespeichert!', type: 'success' })),));

     updateLabelsFail$ = createEffect(() => this.actions$.pipe(
        ofType(LabelActionTypes.LABELS_UPDATE_LABELS_FAIL),
        map(() => new AddAlertAction({message: 'Es ist ein Fehler aufgetreten!', type: 'fail' }))));
}
