import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'hc-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div bsModal #modal="bs-modal"
         class="modal"
         tabindex="-1"
         role="dialog"
         (onHide)="closeModal()"
         aria-labelledby="modal"
         aria-hidden="true">
      <div [ngClass]="{'modal-dialog': true, 'modal-lg': modalSizeLarge, 'modal-sm': !modalSizeLarge}">
        <div class="modal-content">
          <div class="modal-header">
            <ng-content select="[header]"></ng-content>
          </div>
          <div class="modal-body">
            <ng-content select="[body]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ModalComponent {
  @Input() modalSizeLarge = true
  @Output() close = new EventEmitter()
  @ViewChild(ModalDirective, {static: false}) public modal: ModalDirective

  constructor() {
  }

  show(): void {
    console.log(this.modal, this.modal.show)
    this.modal.show()
  }

  hide(): void {
    this.modal.hide()
  }

  public closeModal(): void {
    this.close.emit()
  }
}
