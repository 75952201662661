
import { Injectable, Inject } from '@angular/core';

import { HelloClassRequestsService } from '../hc-requests/hc-requests.services';
import {BACKEND_BASE_URL, EDULOG_SSO_URL} from '../app.tokens';
import {forkJoin, Observable} from "rxjs";

@Injectable()
export class EdulogHttpService {

    constructor(private hcRequests: HelloClassRequestsService, @Inject(BACKEND_BASE_URL) private api,
                @Inject(EDULOG_SSO_URL) private eduloSsoUrl) {
    }

    getTokens(token, userId: string): Observable<any> {
        return this.hcRequests.request({
            method: 'get',
            url: `${this.api}/edulog/token/?token=${token}&id=${userId}`
        });
    }

    linkAccount(query: string): Observable<string> {
        return this.hcRequests.request({
            method: 'get',
            url: this.makeQuery(query),
            mediaType: 'text/*'
        });
    }

    private makeQuery (query: string): string {
        if (this.eduloSsoUrl.indexOf('?') > -1 && this.eduloSsoUrl.indexOf('&') !== 0) {
            return `${this.eduloSsoUrl}&${query}`
        }
        return `${this.eduloSsoUrl}/?${query}`
    }
}
