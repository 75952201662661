import {filter, map, take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {ActivatedRouteSnapshot, Router} from "@angular/router";

import * as fromAuth from "../reducers";
import {HelloclassPermissionsService} from "../permissions/permissions.service";


@Injectable()
export class UserEmailVerifiedGuard {
  constructor(
    private store: Store<fromAuth.AppState>,
    private router: Router,
    private permissionsService: HelloclassPermissionsService
  ) {
  }

  checkEmailVerification() {
    return this.store.select(fromAuth.getAuthUser).pipe(
      filter(x => x.id > -1),
      take(1),
      map(user => {
        // check only for admins! the email for parents and students is not mandatory
        if (this.permissionsService.canAdminSchool(user.permissions) && !user.isEmailVerified) {
          return this.router.parseUrl('/verify');
        } else {
          return true;
        }
      }),);
  }

  canActivate(route: ActivatedRouteSnapshot) {
    console.log("UserEmailVerified guard activated.")
    return this.checkEmailVerification();
  }
}
