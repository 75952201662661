
export type SummaryData = [number, number]

export interface AbsenceUser {
    first_name: string;
    last_name: string;
    id: number;
    open: SummaryData;
    excused: SummaryData;
    not_excused: SummaryData;
}

export interface Absence {
    user: AbsenceUser;
}

export interface AbsenceUserData {
    user: AbsenceUser;
    absences: ApiAbsence[];
    open: SummaryData;
    excused: SummaryData;
    not_excused: SummaryData;
}

export interface FormAbsence {
    type: ABSENCE_TYPE;
    date: Date;
    from: Date;
    to: Date;
    duration: number;
    remark: string;
    noCount: boolean;
}

export class ApiAbsence {

    public id = '';

    constructor(public date: string,
                public user: number | AbsenceUser,
                public owner: AbsenceUser,
                public start_time: string,
                public end_time: string,
                public number_of_lessons: number,
                public description: string,
                public state: ABSENCE_TYPE,
                public no_count: boolean) {
    }

    static fromFormAbsence (absence: FormAbsence): ApiAbsence {
        const date = absence.date.toISOString();
        const start_time = ApiAbsence.dateToTime(absence.from)
        const end_time = ApiAbsence.dateToTime(absence.to)
        return new ApiAbsence(date, -1, null, start_time, end_time, absence.duration,
            absence.remark, absence.type, absence.noCount);
    }

    static empty (date: Date) {
        const stringDate = date.toISOString();
        return new ApiAbsence(stringDate, -1, null, '07:00', '08:00', 0, '',
            ABSENCE_TYPE.Open, false);
    }

    static dateToTime (date: Date): string {
        const hours = ApiAbsence.intToPadded(date.getHours())
        const minutes = ApiAbsence.intToPadded(date.getMinutes())
        return `${hours}:${minutes}`
    }

    static intToPadded (digits: number): string {
        return digits < 10 ? `0${digits}` : `${digits}`
    }

    static timeAsArray (time: string): [number, number, number] {
        return [ApiAbsence.hoursFromTime(time), ApiAbsence.minutesFromTime(time), 0]
    }

    static hoursFromTime (time: string): number {
        return parseInt(time.split(':')[0], 10)
    }

    static minutesFromTime (time: string): number {
        return parseInt(time.split(':')[1], 10)
    }
}

export interface FetchUserAbsencesParameters {
    userId: number;
    from: Date;
    to: Date;
}


export enum TIMEUNIT {
  Semester = 'semester',
  Day = 'day',
}

export enum ABSENCE_TYPE {
    Open = 'open',
    Excused = 'excused',
    NonExcused = 'not_excused'
}

export const ABSENCE_TYPES = {
    [ABSENCE_TYPE.Open]: 'Erfasst',
    [ABSENCE_TYPE.Excused]: 'Entschuldigt',
    [ABSENCE_TYPE.NonExcused]: 'Nicht entschuldigt'
};

export interface AbsenceReadState {
  absence: ApiAbsence;
}

export interface Semester {
    name?: string;
    from: Date;
    to: Date;
}
