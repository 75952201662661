import {Component, Input} from '@angular/core';
import {EdulogHttpService} from "./edulog.http.service";

@Component({
  selector: 'hc-edulog-widget',
  template: `
    <div class="mt-[20px]">
      <img
        class="edulog__logo mx-auto"
        src="assets/icons/edulog.svg"/>
      <div
        class="edulog"
        data-cy="edulog-banner">
        <div class="edulog__text">
          <ng-content></ng-content>
        </div>
        <div *ngIf="showButton(buttonText, buttonLink)">
          <button
            type="button"
            class="btn btn--blue-dark edulog__button"
            (click)="followLink()" translate>{{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  `
})
export class EdulogWidget {
  @Input() buttonText = ''
  @Input() buttonLink = ''
  @Input() isApp = false

  constructor(private edulogHttpService: EdulogHttpService) {
  }

  public showButton(text, link: string): boolean {
    return text !== '' && link !== ''
  }

  public followLink() {
    if (this.isApp) {
      cordova.InAppBrowser.open(this.buttonLink, '_system')
    } else {
      this.edulogHttpService.linkAccount(this.buttonLink)
        .subscribe(data => document.location = data['url'])
    }
  }

}
