
import {Component, Output, Input, EventEmitter, ChangeDetectionStrategy, SimpleChanges, OnChanges} from "@angular/core";
import { Notification } from './notifications.models';


@Component({
    selector: 'hc-notification-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div class="notification-list" [ngClass]="{'vertical-margin': notifications.length === 0}">
        <p class="notification-list__no-msg" *ngIf="!error && showList && notifications.length === 0">{{ 'Du hast keine Meldungen' | translate }}</p>
        <p *ngIf="error" class="notification-list__error form__input_error">{{ error | translate }}</p>
        <ul *ngIf="showList && notifications.length > 0" class="notifications-list unstyled-list">
            <li class="notifications-list__item"
                [ngClass]="{'notifications-list__item--no-pointer': !hasTarget(notification)}"
                *ngFor="let notification of notifications"
                (click)="notificationClicked(notification)"
                role="button"
                aria-label="Show notification">
                <hc-notification [notification]="notification"></hc-notification>
            </li>
        </ul>
        <hc-spinner class="center" [show]="!showList"></hc-spinner>
    </div>
  `,

})
export class NotificationListComponent implements OnChanges {

    @Input() notifications: Notification[];
    @Input() isLoading: boolean;
    @Input() error: string;
    @Output() notificationSelected = new EventEmitter();

    public showList = false

    constructor() {
    }

    public hasTarget (notification) {
        return notification.target && (notification.target.id > -1 || typeof(notification.target.id) === 'string' ||
            notification.target.link);
    }

    public notificationClicked(notification) {
        if (this.hasTarget(notification)) {
            this.notificationSelected.emit(notification);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.showList = !(changes.isLoading && changes.isLoading.currentValue && changes.isLoading.firstChange)
    }
}
