import { Component, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'hc-form-actions',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="form-actions event-actions">
            <div class="container-fluid container-fluid--limit">
                <button class="btn btn-primary btn--innershadow btn--bigger qa-save-button form-actions__item form-actions__ok"
                        data-cy="form-action-submit"
                        [disabled]="disableForm"
                        (click)="submitForm()"
                        type="submit">{{submitText}}</button>
                <button class="qa-abort-button form-actions__item form-actions__cancel unstyled-button icon-cross pull-right btn--dark-text"
                        type="button" (click)="cancelAdd()"></button>
            </div>
        </div>
    `
})

export class FormActions {

    @Input() disableForm = false;
    @Input() submitText = '';
    @Output() cancel = new EventEmitter();
    @Output() submit = new EventEmitter();

    constructor(public translateService: TranslateService) {
    }

    public cancelAdd(): void {
        this.cancel.emit();
    }

    public submitForm(): void {
        this.submit.emit();
    }
}
