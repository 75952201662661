// https://stackoverflow.com/questions/40589878/multiple-canactivate-guards-all-run-when-first-fails/40590812#40590812

import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { from, Observable, of } from "rxjs";
import { concatMap, first, tap } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SyncGuardHelper  {
        public constructor(public injector: Injector) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return from(route.data.syncGuards).pipe(concatMap((value) => {
            const guard = this.injector.get(value);

            const result = guard.canActivate(route, state);
            if (result instanceof Observable) {
                return result;
            } else if (result instanceof Promise) {
                return from(result);
            } else {
                return of(result);
            }
        }), first((x) => x === false || x instanceof UrlTree, true));
    }
}
