import { Router } from '@angular/router';
import { Injector } from '@angular/core';

export class EffectsWithRouter {
    router: Router;

    getRouter() {
        if (!this.router) {
            this.router = this.injector.get(Router);
        }
        return this.router;
    }

    constructor(private injector: Injector) {
    }
}