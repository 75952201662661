
import { Component, Output, Input, EventEmitter, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal';

@Component({
    selector: 'hc-confirmation-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <hc-modal
        [modalSizeLarge]="false"
        (close)="cancelAction()">
        <h4 header class="modal-title">{{title}}</h4>
        <div body>
            {{displayText}}
            <div class="vertical-margin modal-body__actions">
                <button
                    class="btn btn--full-width btn-primary btn--innershadowy"
                    aria-label="Confirm"
                    (click)="confirmAction()"
                    data-cy="modalConfirmButton">{{ confirmationText }}</button>
                <ng-content></ng-content>
                <button class="btn btn--full-width btn--white" aria-label="Close" (click)="cancelAction()">{{ 'Abbrechen' | translate}}</button>
            </div>
        </div>
    </hc-modal>

    `,
})
export class ConfirmationModalComponent {
    @Input() title: string;
    @Input() displayText: string;
    @Input() confirmationText: string;
    @Output() confirm = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @ViewChild(ModalComponent, {static: false}) public modal: ModalComponent;

    constructor() {
    }

    show(): void {
        this.modal.show();
    }

    hide(): void {
        this.modal.hide();
    }


    public confirmAction(): void {
        this.confirm.emit();
    }

    public cancelAction(): void {
        this.cancel.emit();
    }

}
