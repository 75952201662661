import {Action} from '@ngrx/store';
import {type} from '../shared/util';
import {AlertMessage} from './alerts.models';

export const AlertActionTypes = {
  ADD_ALERT: type('[Alert] Add Alert'),
  REMOVE_ALERT: type('[Alert] Remove Alert'),
  ADD_TOAST: type('[Alert] Add Toast'),
};

export type AlertMessageTypes =
  'success' |
  'fail';

export class AddAlertAction implements Action {
  readonly type = AlertActionTypes.ADD_ALERT;

  constructor(public payload: AlertMessage) {
  }
}

export class RemoveAlertAction implements Action {
  readonly type = AlertActionTypes.REMOVE_ALERT;
  payload = null;

  constructor() {
  }
}

export class AddToastAction implements Action {
  readonly type = AlertActionTypes.ADD_TOAST;

  constructor(public payload: AlertMessage) {
  }
}

export type AlertActions = AddAlertAction | RemoveAlertAction | AddToastAction;
