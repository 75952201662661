import {Component} from "@angular/core";


@Component({
  selector: 'hc-activation-page',
  template: `
    <div class="page">
      <div class="page__header">
        <hc-simple-header></hc-simple-header>
      </div>
      <div class="page__content page-content page-content--full-height">

        <hc-alert></hc-alert>
        <h3 data-cy="activationPageTitle" translate>Aktiviere jetzt dein Helloclass Konto</h3>

        <div class="lead-in page-content__lead">

        </div>
        <div class="login page-content__content">
          <hc-activation-code></hc-activation-code>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row footer-wrapper">
        <div class="col-md-10 col-md-offset-1">
          <hc-footer></hc-footer>
        </div>
      </div>
    </div>
  `
})
export class ActivationCodePage {


  constructor() {
  }

}
