import {Subscription} from 'rxjs';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {SignupUser} from '../models/';
import {MIN_PASSWORD_LENGTH} from '../app.constants';
import {StrongPasswordValidator} from '../validators/strongPassword';

/**
 * Tip: Export type aliases for your component's inputs and outputs. Until we
 * get better tooling for templates, this helps enforce you are using a
 * component's API with type safety.
 */

export type signup = SignupUser;
export type user = SignupUser;

@Component({
  selector: 'hc-signup',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="signup">
      <div *ngIf="formErrors && formErrors['general']">
        <p class="form__input_error text-size-small qa-signup-general-error">
          <i aria-hidden="true"
             class="sdx-icon icon-026-exclamation-mark-circle"></i> {{ formErrors['general'][0] | translate }}</p>
      </div>
      <form [formGroup]="form" (submit)="formSubmit()">
        <div class="form-group">
          <hc-form-field [errors]="formErrors" [fieldName]="'schoolName'"
                         [labelText]="'Schulname'">
            <input data-cy="signup-school-name" type="text" formControlName="schoolName" ngControl="schoolName"
                   [readonly]="schoolNameReadOnly"/>
          </hc-form-field>
        </div>
        <div class="form-group">
          <hc-form-field [errors]="formErrors" [fieldName]="'firstName'"
                         [labelText]="'Vorname'">
            <input data-cy="signup-firstname" type="text" formControlName="firstName" ngControl="firstName"/>
          </hc-form-field>
        </div>
        <div class="form-group">
          <hc-form-field [errors]="formErrors" [fieldName]="'lastName'"
                         [labelText]="'Nachname'">
            <input data-cy="signup-lastname" type="text" formControlName="lastName" ngControl="lastName"/>
          </hc-form-field>
        </div>
        <div class="form-group">
          <hc-form-field [errors]="formErrors" [fieldName]="'email'"
                         [labelText]="'Email'">
            <input data-cy="signup-email" type="text" formControlName="email" ngControl="email"/>
          </hc-form-field>
        </div>
        <div class="form-group">
          <label for="language" translate>Sprache</label>
          <div *ngIf="submitted && formErrors['language']">
            <p class="form__input_error text-size-small qa-language-error">{{ formErrors['language'] }}</p>
          </div>
          <div class="language-selection">
            <div class="radio signup-radio">
              <input type="radio"
                     id="language-de"
                     data-cy="signup-language-de"
                     ngControl="language"
                     value="de"
                     formControlName="language">
              <label for="language-de" translate>Deutsch</label>
            </div>
            <div class="radio signup-radio">
              <input type="radio"
                     id="language-fr"
                     data-cy="signup-language-fr"
                     ngControl="language"
                     value="fr"
                     formControlName="language">
              <label for="language-fr" translate>Französisch</label>
            </div>
            <div class="radio signup-radio">
              <input type="radio"
                     id="language-it"
                     data-cy="signup-language-it"
                     ngControl="language"
                     value="it"
                     formControlName="language">
              <label for="language-it" translate>Italienisch</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <hc-form-field [errors]="formErrors" [fieldName]="'password'" [labelText]="'Passwort' | translate">
            <input data-cy="signup-password"
                   type="password"
                   formControlName="password"
                   ngControl="password"/>
          </hc-form-field>
          <p class="text-gray" translate>Das Passwort muss mindestens 10 Zeichen lang sein und Grossbuchstaben, Zahlen
            und Sonderzeichen beinhalten</p>
        </div>
        <div class="form-group">
          <hc-terms
            [formErrors]="formErrors['terms']"
          >
            <input formControlName="terms" id="terms" type="checkbox">
          </hc-terms>
        </div>
        <button
          class="btn btn--innershadow btn--blue vertical-margin-bottom/2 qa-login-button btn--full-width login-form__button"
          data-cy="signup-submit"
          type="submit" translate>
          Neues Helloclass Konto regstrieren
        </button>
      </form>
    </div>
  `,

})
export class SignupComponent {
  /**
   * Dumb components receieve data through @Input() and communicate events
   * through @Output() but generally maintain no internal state of their
   * own. All decisions are delegated to 'container', or 'smart'
   * components before data updates flow back down.
   *
   * More on 'smart' and 'dumb' components: https://gist.github.com/btroncone/a6e4347326749f938510#utilizing-container-components
   *
   * Tip: Utilize getters to keep templates clean in 'dumb' components.
   */
  @Output() signup = new EventEmitter<SignupUser>()
  @Output() errors = new EventEmitter<Object>()
  @Output() formChanges = new EventEmitter<Object>()
  @Input() user: SignupUser // todo: is this really needed?
  @Input() formErrors: Object = {}
  @Input() schoolName = ''
  @Input() schoolNameReadOnly = true

  public form: FormGroup;
  public submitted = false;
  private formSubscription: Subscription;

  constructor(formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      schoolName: [this.schoolName, Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH), StrongPasswordValidator.strong]],
      language: ['de', Validators.required],
      terms: ['', Validators.requiredTrue]
    });

    this.formSubscription = this.form.valueChanges.subscribe(changes => this.formChanges.emit(changes));
  }

  public formSubmit(): void {
    this.submitted = true;

    if (this.form.valid) {
      this.signup.emit(this.form.value);
    } else {
      let errors = {};

      for (let control in this.form.controls) {
        if (this.form.controls[control].errors) {
          // todo allow multiple errors && only handles required so far....
          let key = '';
          switch (control) {
            case 'schoolName':
              key = 'Schulname fehlt';
              break;
            case 'firstName':
              key = 'Vorname fehlt';
              break;
            case 'lastName':
              key = 'Nachname fehlt';
              break;
            case 'password':
              let errorType = this.form.controls[control].errors;
              if (errorType['required']) {
                key = 'Passwort fehlt';
              } else if (errorType['minlength']) {
                key = 'Das Passwort muss mindestens 10 Zeichen lang sein';
              } else if (errorType['strong']) {
                key = 'Das Passwort muss Grossbuchstaben, Zahlen und Sonderzeichen beinhalten'
              }
              break;
            case 'email':
              key = 'Email fehlt';
              break;
            case 'terms':
              key = 'Die Nutzungsbedingungen müssen akzeptiert werden';
              break;
          }
          errors[control] = [key];
        }
      }
      this.errors.emit(errors);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.user && !this.form.dirty) {
      let patchObj = Object.assign(
        {},
        this.user,
        {
          language: this.form.controls['language'].value
        });

      this.form.patchValue(patchObj);
    }

    if (changes['schoolName'] && changes['schoolName'].firstChange) {
      this.form.controls['schoolName'].setValue(this.schoolName);
    }
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

}
