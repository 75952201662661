import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from "@ngrx/store";
import { Observable ,  Subscription } from "rxjs";

import { ActivateCode, ActivateCodeFormErrorAction } from "../actions/authentication";
import * as reducer from "../reducers";


@Component({
    selector: 'hc-activation-code',
    template: `
    <div class="activate">
        <div *ngIf="formErrors?.general">
            <p class="form__input_error text-size-small qa-login-general-error">
            <i aria-hidden="true" class="sdx-icon icon-026-exclamation-mark-circle"></i> <span data-cy="activationError" [innerHtml]="formErrors['general']"></span></p>
        </div>
        <form [formGroup]="form" (submit)="formSubmit()" class="login__form">
            <div class="form-group">
                <hc-form-field [errors]="formErrors" [fieldName]="'activationCode'"
                            [labelText]="'Zugangscode'">
                <input
                    data-cy="activationCode"
                    type="text"
                    name="activationCode"
                    formControlName="activationCode"
                    arial-label="activationCode"
                    autocomplete="off" />
                </hc-form-field>
            </div>
            <button
                class="btn btn--blue btn--bigger btn--full-width login-form__button btn--innershadow"
                type="submit"
                data-cy="activationSubmit" translate>
                Prüfen
            </button>
        </form>
    </div>
    `
})
export class ActivationCodeComponent {
    message$: Observable<any>;
    showMessages$: Observable<boolean>;
    public formErrors = {
        activationCode: '',
        general: ''
    };

    public form: FormGroup;
    private formErrorSubscription: Subscription;

    constructor(formBuilder: FormBuilder,
                private store: Store<reducer.AppState>) {
        this.form = formBuilder.group({
           activationCode: ['', Validators.required],
        });

        this.formErrorSubscription = store.select(reducer.getActivationFormErrors).subscribe(errors => this.formErrors = errors);
    }

    public formSubmit(): void {
        if (this.form.valid) {
            this.store.dispatch(new ActivateCode(this.form.value.activationCode));
        } else {
            let errors = {};

            for (let control in this.form.controls) {
                if (this.form.controls[control].errors) {
                    // todo allow multiple errors && only handles required so far....
                    let key = '';
                    switch (control) {
                        case 'activationCode':
                            key = 'Der Zugangscode fehlt!';
                            break;
                    }
                    errors[control] = [key];
                }
            };
            this.store.dispatch(new ActivateCodeFormErrorAction(errors));
        }
    }

    ngOnDestroy() {
        this.formErrorSubscription.unsubscribe();
    }
}
