
/**
 * Created by alex on 05/10/16.
 */
import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StrongPasswordValidator } from '../validators/strongPassword';
import { MIN_PASSWORD_LENGTH } from '../app.constants';
import {take} from 'rxjs/operators';

@Component({
    selector: 'hc-password-reset',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="row vertical-margin">
            <div class="col-md-5 col-md-offset-3">
                <h3 data-cy="pw-reset-title">{{'Bitte definiere jetzt dein persönliches Passwort.' | translate}}</h3>
                <p class="text-gray" translate>Das Passwort muss mindestens 10 Zeichen lang sein und Grossbuchstaben, Zahlen und Sonderzeichen beinhalten</p>
                <form [formGroup]="form" (submit)="formSubmit()">
                    <hc-form-field [errors]="errors" [fieldName]="'passwordNew'" [labelText]="'Neues Passwort'">
                        <input class="form-control qa-password-reset" type="password" formControlName="passwordNew" data-cy="passwordInput" />
                    </hc-form-field>
                    <button class="btn btn--innershadow btn-primary qa-submit-button vertical-margin" type="submit" data-cy="passwordSubmit">
                        {{'Speichern'|translate}}
                    </button>
                </form>
            </div>
        </div>
  `,
})
export class PasswordResetComponent {
    @Output() submitForm = new EventEmitter<Object>();

    public form: FormGroup;
    public errors = {};
    private errorMsgs = { required: '', minlength: '', strong: '' };

    constructor(private formBuilder: FormBuilder, translateService: TranslateService) {
        this.form = formBuilder.group({
           passwordNew: ['', [Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH), StrongPasswordValidator.strong]]
        });

        translateService.get(['Dieses Feld muss ausgefüllt werden',
                              'Das neue Passwort muss mindestens 10 Zeichen lang sein',
                              'Das Passwort muss Grossbuchstaben, Zahlen und Sonderzeichen beinhalten']).pipe(
            take(1))
            .subscribe(msgs => {
                this.errorMsgs.required = msgs['Dieses Feld muss ausgefüllt werden'];
                this.errorMsgs.minlength = msgs['Das neue Passwort muss mindestens 10 Zeichen lang sein'];
                this.errorMsgs.strong = msgs['Das Passwort muss Grossbuchstaben, Zahlen und Sonderzeichen beinhalten']
            });
    }

    public formSubmit(): void {
        if (this.form.valid) {
            this.submitForm.emit({passwordNew: this.form.controls['passwordNew'].value});
        } else {
            this.errors = { passwordNew: []};
            let errors = this.form.controls['passwordNew'].errors;

            for (let error in errors) {
                if (errors.hasOwnProperty(error)) {

                    try {
                        this.errors['passwordNew'].push(this.errorMsgs[error]);
                    } catch (e) {
                        this.errors['passwordNew'].push(this.errorMsgs.required);
                    }
                }
            }
        }
    }
}
