
import * as dayjs from 'dayjs';
import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { Notification } from './notifications.models';

@Component({
    selector: 'hc-notification',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="notification" *ngIf="notification.actor" [ngClass]="{'notification--unread': notification.unread}">
            <h4 class="notification__title text-size-normal no-margin">
                {{notification.actor['first_name']}} {{notification.actor['last_name']}} <span translate>{{notification.verb}}</span>
            </h4>
            <p class="notification__time text-size-small text-gray no-margin">{{ calculateTimeAgo(notification.timestamp) }}</p>
        </div>
  `,

})
export class NotificationComponent {

    @Input() notification: Notification;

    constructor() {
    }

    public calculateTimeAgo(timestring: string) {
        return dayjs(timestring).fromNow();
    }
}
