
import { Action } from '@ngrx/store';
import { type } from '../shared/util';
import { Notification } from '../notifications/notifications.models';

export const UiActionTypes = {
  UI_SHOW_NAVIGATION: type("[UI] Show navigation"),
  UI_HIDE_NAVIGATION: type("[UI] Hide navigation"),
  UI_SET_TITLE: type("[UI] Set title"),
  UI_SHOW_MENU: type("[UI] Show menu"),
  UI_HIDE_MENU: type("[UI] Hide menu"),
  UI_SHOW_BACK: type("[UI] Show back"),
  UI_HIDE_BACK: type("[UI] Hide back"),
  UI_SET_CONTEXT_MENU: type("[UI] Set context menu"),
  UI_SET_BACK_URL: type("[UI] Set back URL"),
  UI_SET_APP_PART: type("[UI] Set app part"),
  UI_FORCE_CONTEXT_DROPDOWN: type('[UI] Force Context Dropdown'),
  UI_LIBERATE_CONTEXT_DROPDOWN: type('[UI] Liberate Context Dropdown'),
  UI_SET_LANGUAGE: type('[UI] Set language'),
  UI_SHOW_NOTIFICATIONS: type('[UI] Show notifications'),
  UI_HIDE_NOTIFICATIONS: type('[UI] Hide notifications'),
  UI_TOGGLE_NOTIFICATIONS: type('[UI] Toggle notifications'),
  UI_NOTIFICATION_SELECTED: type('[UI] Notification selected'),
  UI_RESET: type('[UI] Reset'),
};

export class UiShowNavigationAction implements Action {
    type = UiActionTypes.UI_SHOW_NAVIGATION;

    constructor() {}
}

export class UiHideNavigationAction implements Action {
    type = UiActionTypes.UI_HIDE_NAVIGATION;

    constructor() {}
}

export class UiSetTitleAction implements Action {
    type = UiActionTypes.UI_SET_TITLE;

    constructor(public payload: string) {}
}

export class UiShowMenuAction implements Action {
    type = UiActionTypes.UI_SHOW_MENU;

    constructor() {}
}

export class UiHideMenuAction implements Action {
    type = UiActionTypes.UI_HIDE_MENU;

    constructor() {}
}

export class UiShowBackAction implements Action {
    type = UiActionTypes.UI_SHOW_BACK;

    constructor() {}
}

export class UiHideBackAction implements Action {
    type = UiActionTypes.UI_HIDE_BACK;

    constructor() {}
}

export class UiSetContextMenu implements Action {
    type = UiActionTypes.UI_SET_CONTEXT_MENU;

    constructor(public payload:string) {}
}

export class UiSetBackUrl implements Action {
    type = UiActionTypes.UI_SET_BACK_URL;

    constructor(public payload: string) {}
}

export class UiSetAppPart implements Action {
    type = UiActionTypes.UI_SET_APP_PART;

    constructor(public payload: string) {}
}

export class UiForceContextDropdown implements Action {
    type = UiActionTypes.UI_FORCE_CONTEXT_DROPDOWN;

    constructor() {}
}

export class UiLiberateContextDropdown implements Action {
    type = UiActionTypes.UI_LIBERATE_CONTEXT_DROPDOWN;

    constructor() {}
}

export class UiSetLanguage implements Action {
    type = UiActionTypes.UI_SET_LANGUAGE;

    constructor(public payload: string) {}
}

export class UiShowNotifications implements Action {
    type = UiActionTypes.UI_SHOW_NOTIFICATIONS;

    constructor() {}
}

export class UiHideNotifications implements Action {
    type = UiActionTypes.UI_HIDE_NOTIFICATIONS;

    constructor() {}
}

export class UiToggleNotifications implements Action {
    type = UiActionTypes.UI_TOGGLE_NOTIFICATIONS;

    constructor() {}
}

export class UiNotificationSelected implements Action {
    type = UiActionTypes.UI_NOTIFICATION_SELECTED;

    constructor(public payload: Notification) {}
}

export class UiResetAction implements Action {
    type = UiActionTypes.UI_RESET;

    constructor() {}
}

export type AuthActions =
    UiShowNavigationAction
    | UiHideNavigationAction
    | UiSetTitleAction
    | UiShowMenuAction
    | UiHideMenuAction
    | UiShowBackAction
    | UiHideBackAction
    | UiSetContextMenu
    | UiSetBackUrl
    | UiSetAppPart
    | UiForceContextDropdown
    | UiLiberateContextDropdown
    | UiSetLanguage
    | UiShowNotifications
    | UiHideNotifications
    | UiToggleNotifications
    | UiNotificationSelected
    | UiResetAction;

