import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as fromGroups from "../../reducers";
import { ForgotPasswordGenerateAction } from "../../actions/authentication";
import { UiSetTitleAction } from "../../actions/ui";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IS_APP } from '../../app.constants';

@Component({
    selector: 'hc-forgotpassword',
    template: `

    <div class="page forgot-password">
        <div class="page__header">
            <hc-simple-header [showBack]="isApp"></hc-simple-header>
        </div>

        <div class="page__content page-content page-content--full-height">

            <hc-alert></hc-alert>

            <div class="lead-in page-content__lead forgot-password__lead">
                <h1>{{'Passwort zurücksetzen'|translate}}</h1>
            </div>

            <div class="login page-content__content">
                <div class="page__content page-content page-content--full-height">
                    <div>
                        <ol class="ol--no-padding">
                            <li>{{'Bitte gebe Deine Email ein mit der Du Dich auf Helloclass registriert hast.' | translate}}</li>
                            <li>{{"Klicke unten auf 'Passwort Link zuschicken'" | translate}}</li>
                            <li>{{'Öffne unsere E-Mail in Deiner Inbox' | translate}}</li>
                            <li>{{'Klicke in der E-Mail auf den Link' | translate}}</li>
                            <li>{{'Setze Dein Neues Passwort' | translate}}</li>
                        </ol>
                    </div>
                    <form [formGroup]="form" (submit)="formSubmit()">
                        <div class="form-group">
                        <label for="email" translate>Deine Helloclass E-Mail</label>
                        <input class="form-control" type="text" name="email" formControlName="email" />
                        </div>
                        <button class="btn btn-primary qa-save-button btn--innershadow" [disabled]="!form.valid" type="submit">
                                {{'Passwort Link zuschicken'|translate}}
                        </button>
                    </form>
                </div>
            </div>
        </div>

    </div>
    `
})
export class ForgotPasswordWizardPage {

    public form: FormGroup;
    public isApp = IS_APP;

    constructor(private store: Store<fromGroups.AppState>,
                private formBuilder: FormBuilder) {

        this.form = formBuilder.group({
            email: ['', Validators.required]
        });

        this.store.dispatch(new UiSetTitleAction('Passwort vergessen'));
    }

    public forgotPasswordGenerate(email: string): void {
        this.store.dispatch(new ForgotPasswordGenerateAction( {'email': email} ));
    }

    public formSubmit(): void {
        this.forgotPasswordGenerate(this.form.controls['email'].value);
    }

}
