
import {pairwise, filter} from 'rxjs/operators';


import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoutingHistoryService {

    private routes: Object;

    constructor(router: Router) {
        router.events.pipe(
            filter(event => event instanceof (NavigationStart) ),
            pairwise(),
            filter((events:[any, any]) => events[0].url !== events[1].url),)
            .subscribe((events) => {
                this.routes = {
                    previousRoute: events[0].url,
                    currentRoute: events[1].url
                };
            });
    }

    public getPreviousRoute(): string {
        if (this.routes && this.routes['previousRoute']) {
            return this.routes['previousRoute'];
        } else {
            return '';
        }
    }

    public getUrlPart(url: string): string {
        return url.split('?')[0];
    }

    public getParams(url: string): Object {
        let paramString = url.split('?')[1];
        return this.parseQueryString(paramString);
    }

    // https://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-get-parameters
    private parseQueryString(query: string): Object {
        if (query === undefined) {
            return {};
        }

        let vars = query.split("&");
        let queryParams = {};
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            let key = decodeURIComponent(pair[0]);
            let value = decodeURIComponent(pair[1]);
          // If first entry with this name
          if (typeof queryParams[key] === "undefined") {
            queryParams[key] = decodeURIComponent(value);
            // If second entry with this name
          } else if (typeof queryParams[key] === "string") {
            let arr = [queryParams[key], decodeURIComponent(value)];
            queryParams[key] = arr;
            // If third or later entry with this name
          } else {
            queryParams[key].push(decodeURIComponent(value));
          }
        }
        return queryParams;
      }

}
